var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { FileType, supportedFiles, } from "..";
import { sleep } from "../utils/sleep";
import { generateModelAndReps, renderSystem } from "./main";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import urlJoin from "url-join";
import { getRawFileContentOrFSpath } from "../apps/results/fetchDcData";
const suppFileToFiletype = {
    coordinates_static: FileType.coordinates_single,
    coordinates_trajectory: FileType.trajectory,
    topology: FileType.trajectory,
    volume: FileType.volume,
};
const extensionToFileType = {};
Object.entries(supportedFiles)
    .filter((e) => e[0] !== "loading_batch")
    .forEach((e) => {
    const [suppfile, extli] = e;
    extli.forEach((ext) => {
        if (suppfile in FileType) {
            const _suppfile = suppfile;
            const ftype = suppFileToFiletype[_suppfile];
            extensionToFileType[ext] = ftype;
        }
    });
});
function toAbsPath(base, mypath) {
    return mypath.charAt(0) === "/" ? mypath : urlJoin(base, mypath);
}
export function createNewSystemFromLocalTrajFrames(vss, molstar, pyodide, framesToLoad, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        framesToLoad.files.forEach((frameInfo) => {
            frameInfo.model = toAbsPath(localBasePath, frameInfo.model);
            frameInfo.trajectory = frameInfo.trajectory.map((tpath) => toAbsPath(localBasePath, tpath));
        });
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        const result = yield wIPCR.invoke("load-traj-frames", framesToLoad.files);
        if (!result["data"] && result["error"]) {
            dispatchNotificationEvent({ message: result["error"], type: "error" });
            return;
        }
        const files = result["data"];
        const fileObj = Object.assign({}, ...files.map((x) => ({
            [x.name]: x,
        })));
        const system = framesToLoad.system
            ? framesToLoad.system
            : {
                name: files.map((e) => e.name).join(", "),
            };
        system["files"] = files.map((e) => e.name);
        yield renderSystem(vss, molstar, pyodide, fileObj, system);
        if (!system["cellRef"])
            return;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(system);
        });
        vss.getState().set_loaded_structures(newSystems);
        return system;
    });
}
export function createNewSystemFromLocal(vss, molstar, pyodide, topFilePath, fileName, category, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        const systFile = yield wIPCR.invoke("read-local-file", topFilePath);
        systFile;
        if (!systFile)
            return;
        const system = {
            name: fileName ? fileName : topFilePath,
            files: [topFilePath],
            category: category,
            representations: representations,
        };
        yield renderSystem(vss, molstar, pyodide, { [topFilePath]: systFile }, system);
        if (!system["cellRef"])
            return;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(system);
        });
        vss.getState().set_loaded_structures(newSystems);
        return system;
    });
}
export function createNewSystemFromDC(vss, molstar, pyodide, topFilePath, trajFilePath, loadMultiple, category, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const newSystem = yield renderDataCenterSystem(vss, molstar, pyodide, topFilePath, trajFilePath, undefined, loadMultiple, undefined, representations);
        if (!newSystem)
            return;
        newSystem.category = category;
        const prevSystems = vss.getState().loaded_structures;
        const newSystems = produce(prevSystems, (draft) => {
            draft.push(newSystem);
        });
        vss.getState().set_loaded_structures(newSystems);
        return newSystem;
    });
}
export function renderDataCenterSystem(vss, molstar, pyodide, topFilePath, trajFilePath, system, loadMultiple, quiet, representations) {
    return __awaiter(this, void 0, void 0, function* () {
        const topFileName = topFilePath.split("=").pop();
        const trajFileName = trajFilePath ? trajFilePath.split("=").pop() : undefined;
        const systemName = trajFileName
            ? `${trajFileName}, ${topFileName}`
            : topFileName;
        const idx = system ? system.name : systemName ? systemName : "";
        if (!quiet)
            dispatchControlledNotification({
                idx: idx,
                type: "info",
                message: `Loading...`,
                fileName: systemName,
            });
        const files = [topFilePath];
        if (trajFilePath) {
            files.push(trajFilePath);
        }
        const extension = topFileName === null || topFileName === void 0 ? void 0 : topFileName.split(".").pop();
        const _system = system
            ? system
            : {
                name: systemName ? systemName : "",
                files: files,
                representations: representations,
                type: trajFilePath
                    ? FileType.trajectory
                    : extensionToFileType[extension ? extension : ""],
            };
        let topData;
        let trajData;
        let isInVFS = false;
        try {
            if (pyodide) {
                const topContentOrPath = yield getRawFileContentOrFSpath(topFilePath, pyodide);
                if (topContentOrPath) {
                    if (topContentOrPath.content) {
                        topData = topContentOrPath.content;
                        yield sleep(2000);
                    }
                    else {
                        topData = topContentOrPath.path;
                        isInVFS = true;
                    }
                }
                if (trajFilePath) {
                    const trajContentOrPath = yield getRawFileContentOrFSpath(trajFilePath, pyodide, true);
                    if (trajContentOrPath) {
                        if (trajContentOrPath.content) {
                            if (isInVFS)
                                throw new Error("Error reading topology-trajectory pair");
                            trajData = trajContentOrPath.content;
                            yield sleep(2000);
                        }
                        else {
                            if (!isInVFS)
                                throw new Error("Error reading topology-trajectory pair");
                            trajData = trajContentOrPath.path;
                        }
                    }
                }
            }
            if (topData) {
                yield generateModelAndReps(vss, molstar, pyodide, _system, topData, trajData, topFileName ? topFileName : "", trajFileName, undefined, undefined, quiet, isInVFS);
            }
        }
        catch (e) {
            dispatchNotificationEvent({ message: e, type: "error" });
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: idx });
        return _system.cellRef && _system.cellRef.length > 0 ? _system : undefined;
    });
}
