import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import DeleteIcon from "@mui/icons-material/Delete";
export function RepresentationRowOptions({ visible, disabled, handleToggleRepVisibility, handleFocus, handleRemoveRep, }) {
    return (_jsxs(Box, Object.assign({ sx: {
            "& .MuiSvgIcon-root": {
                fontSize: "0.9em",
            },
        }, flex: 2 }, { children: [_jsx(VisibilityButton, { visible: visible, disabled: disabled, handleToggleRepVisibility: handleToggleRepVisibility }), _jsx(FocusButton, { disabled: disabled, handleFocus: handleFocus }), _jsx(RemoveButton, { disabled: disabled, handleRemoveRep: handleRemoveRep })] })));
}
function RemoveButton({ disabled, handleRemoveRep, }) {
    return (_jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: "Remove Representation" }, { children: _jsx(IconButton, Object.assign({ edge: "end", "aria-label": "delete", size: "small", color: "primary", onClick: (e) => {
                handleRemoveRep();
                e.stopPropagation();
            } }, { children: _jsx(DeleteIcon, { fontSize: "small", sx: {
                    color: disabled ? "#BDBDBD" : "auto",
                } }) })) })));
}
function FocusButton({ disabled, handleFocus, }) {
    return (_jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: "Focus Representation" }, { children: _jsx(IconButton, Object.assign({ edge: "end", "aria-label": "focus", size: "small", color: "primary", onClick: (e) => {
                handleFocus();
                e.stopPropagation();
            } }, { children: _jsx(CenterFocusStrongIcon, { fontSize: "small", sx: {
                    color: disabled ? "#BDBDBD" : "auto",
                } }) })) })));
}
function VisibilityButton({ visible, disabled, handleToggleRepVisibility, }) {
    return (_jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: `${visible ? "Hide" : "Show"} Representation` }, { children: _jsx(IconButton, Object.assign({ color: "primary", edge: "end", "aria-label": "toggle-viz", size: "small", onClick: (e) => {
                handleToggleRepVisibility();
                e.stopPropagation();
            } }, { children: _jsx(_Fragment, { children: !visible ? (_jsx(VisibilityOffIcon, { fontSize: "small", sx: {
                        color: disabled ? "#BDBDBD" : "auto",
                    } })) : (_jsx(VisibilityIcon, { fontSize: "small", sx: {
                        color: disabled ? "#BDBDBD" : "auto",
                    } })) }) })) })));
}
