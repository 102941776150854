import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { StateObjectRef } from "molstar/lib/mol-state";
import { indexSelector, addReverseIndexing } from "..";
import { setSubtreeVisibility } from "molstar/lib/mol-plugin/behavior/static/state";
export function toggleVisibility(molstar, cellRefs) {
    cellRefs.forEach((cellRef) => {
        const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
        if (selectedStruct) {
            PluginCommands.State.ToggleVisibility(molstar, {
                state: selectedStruct.parent,
                ref: selectedStruct.transform.parent,
            });
        }
    });
}
export function setVisibility(molstar, cellRefs, visibility) {
    cellRefs.forEach((cellRef) => {
        const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
        if (selectedStruct) {
            setSubtreeVisibility(selectedStruct.parent, selectedStruct.transform.parent, !visibility);
        }
    });
}
export function setVisibilityForSDF(molstar, activeTextFieldVal, refs) {
    // Toggle the visibility of SDF frames
    if (!activeTextFieldVal)
        return;
    const repRefs = refs.map((x) => {
        return x.repRef;
    });
    let newActiveElements = [...repRefs];
    if (activeTextFieldVal) {
        try {
            newActiveElements = indexSelector(activeTextFieldVal, addReverseIndexing(repRefs));
        }
        catch (_a) { }
    }
    const _repRefs = repRefs.filter((e) => e !== undefined);
    const _newActiveElements = newActiveElements.filter((e) => e !== undefined);
    setVisibility(molstar, _repRefs, false); //set all to visibility false
    setVisibility(molstar, _newActiveElements, true); //set selected refs to visibility true
}
