// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { FileType, } from "../Interfaces";
import supportedFiles from "./supportedFiles";
function generateFileConfiguration(acceptedFiles) {
    const supportedSingleFileExt = supportedFiles.coordinates_static;
    const generatedSystems = [];
    const tableFiles = [];
    const msaFiles = [];
    const plotFiles = [];
    const notSupported = [];
    let topology = [];
    let trajectory = [];
    let coordinates = [];
    Array.from(acceptedFiles).forEach((strucFile) => {
        let filePath;
        if (strucFile["path"]) {
            filePath = strucFile["path"];
        }
        else {
            filePath = strucFile.name;
        }
        const fileName = strucFile.name.split("/").pop();
        const ext = fileName.split(".").pop();
        if (ext && supportedSingleFileExt.includes(ext)) {
            coordinates.push({ name: fileName, path: filePath });
        }
        else if (ext && supportedFiles.volume.includes(ext)) {
            const system = {
                name: fileName,
                type: FileType.volume,
                files: [filePath],
            };
            generatedSystems.push(system);
        }
        else if (ext && supportedFiles.tables.includes(ext)) {
            const isMSa = supportedFiles.MSAs.some((alig_ext) => fileName.endsWith(`.${alig_ext}`));
            if (isMSa) {
                msaFiles.push(filePath);
            }
            else {
                tableFiles.push({ path: filePath });
            }
        }
        else if (ext &&
            supportedFiles.plots.some((alig_ext) => fileName.endsWith(`.${alig_ext}`))) {
            plotFiles.push({ path: filePath });
        }
        else if (ext && supportedFiles.coordinates_trajectory.includes(ext)) {
            trajectory.push({ name: fileName, path: filePath });
        }
        else if (ext && supportedFiles.topology.includes(ext)) {
            topology.push({ name: fileName, path: filePath });
        }
        else {
            notSupported.push(fileName);
        }
    });
    let unmatchedFiles = [];
    if (trajectory.length == 1) {
        const mytraj = trajectory[0];
        if (topology.length === 1) {
            const mytop = topology[0];
            const system = {
                name: `${mytraj.name}, ${mytop.name}`,
                type: FileType.trajectory,
                files: [mytraj.path, mytop.path],
            };
            generatedSystems.push(system);
            topology = [];
            trajectory = [];
        }
        else if (coordinates.length == 1) {
            const mycoo = coordinates[0];
            const system = {
                name: `${mytraj.name}, ${mycoo.name}`,
                type: FileType.trajectory,
                files: [mytraj.path, mycoo.path],
            };
            generatedSystems.push(system);
            coordinates = [];
            trajectory = [];
        }
    }
    unmatchedFiles = [
        ...topology.map((e) => e.name),
        ...trajectory.map((e) => e.name),
    ];
    coordinates.forEach((coord) => {
        const system = {
            name: coord.name,
            type: FileType.coordinates_single,
            files: [coord.path],
        };
        generatedSystems.push(system);
    });
    return {
        loadingInstructions: {
            systems: generatedSystems,
            tables: tableFiles,
            MSAs: { files: msaFiles },
            plots: plotFiles,
        },
        unsupportedFilesDetected: notSupported,
        unmatchedFiles,
    };
}
export default generateFileConfiguration;
