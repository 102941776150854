import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { shallow } from "zustand/shallow";
import { Box, Select, MenuItem } from "@mui/material";
import { useCustomEvent } from "..";
import { NAPMSAStore } from "../StateStores/MSA.store";
import { Panels } from "../GenericViewerState/SharedState";
function SelectMSA({ handleChange, activeMSAID, msaArr }) {
    return (_jsx(Box, Object.assign({ sx: { pb: 2 } }, { children: _jsx(Select, Object.assign({ labelId: "select-msa-label", id: "select-msa", value: activeMSAID.toString(), 
            // label="MSA"
            onChange: handleChange, size: "small" }, { children: msaArr.map((a, i) => (_jsx(MenuItem, Object.assign({ value: i }, { children: a.name }), `opt${i}`))) })) })));
}
export function MSA({ NAPGenericStore, }) {
    const { msaArr, del_msa } = NAPMSAStore();
    const [activeMSAID, setActiveMSAID] = useState(0);
    const [activePanels, setActivePanels] = NAPGenericStore((state) => [state._activePanels, state.setActivePanels], shallow);
    const openMSAPanel = (e) => {
        setActivePanels(Object.assign(Object.assign({}, activePanels), { msa: true }));
    };
    useCustomEvent("PM-Loaded-MSA", openMSAPanel);
    const closePanel = () => setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.msa]: false }));
    const onDelete = () => {
        del_msa(Number(activeMSAID), closePanel);
        setActiveMSAID(0);
    };
    const selectedMSA = msaArr[activeMSAID]
        ? msaArr[activeMSAID].data
        : msaArr[activeMSAID];
    return (_jsx(Box, Object.assign({ sx: { p: 1, pb: 0 } }, { children: msaArr.length > 1 ? (_jsx(SelectMSA, { activeMSAID: activeMSAID, msaArr: msaArr, handleChange: (e) => setActiveMSAID(Number(e.target.value)) })) : null })));
}
