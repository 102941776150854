import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { ActionIconButton, NAPPlotStore, NAPTableStore } from "../..";
import { Box, Fade, Grid, Typography } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Panels, } from "../../GenericViewerState/SharedState";
import { TablePlotsTabs } from "../../DataViz/DataViz";
import { rightPanel } from "../panelDimensions";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { NavigationTableData } from "../../Tables/TableNavigation/NavigationTableData";
import { deleteAllDatavizElementIntances } from "../../DataViz/utils";
import { DatavizElements } from "../../DataViz/interfaces";
import RoundDeleteButton from "../../components/Buttons/DeleteButton/RoundDeleteButton";
function TablePlotsButtons({ isFullscreen, handleClickToggleFullscreen, handleClickHide, handleDeleteAll, }) {
    return (_jsxs(Grid, Object.assign({ container: true, sx: { my: 1, height: "2.1em" }, alignContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: "Hide", onClick: handleClickHide, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: _jsx(ExpandLessIcon, { style: { transform: "rotate(90deg)" }, fontSize: "small" }) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ActionIconButton, Object.assign({ tooltipMsg: isFullscreen ? "Exit full screen" : "Full screen", onClick: handleClickToggleFullscreen, iconHoverColor: "auto", backgroundColor: "auto", backgroundHoverColor: "auto" }, { children: isFullscreen ? (_jsx(FullscreenExitIcon, { fontSize: "small" })) : (_jsx(FullscreenIcon, { fontSize: "small" })) })) })), handleDeleteAll ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(RoundDeleteButton, { onClick: handleDeleteAll, tooltipMsg: "Close all", icon: "close" }) }))) : null] })));
}
export const PlotsPanel = ({ NAPGenericStore, vss, molstar, pyodide, skipButtons, fileSelectorAsTabs, }) => {
    const { isFullscreen, toggleFullscreen } = NAPGenericStore((state) => ({
        isFullscreen: state.isFullscreen,
        toggleFullscreen: state.toggleFullscreen,
    }), shallow);
    const [activePanels, focusedPanel, setFocusedPanel, setActivePanels, sideBarWidth, dataVizDetails,] = NAPGenericStore((state) => [
        state._activePanels,
        state._focusedPanel,
        state.setFocusedPanel,
        state.setActivePanels,
        state.sideBarWidth,
        state.dataVizDetails,
    ], shallow);
    const rightPanelDimensions = Object.assign(Object.assign({}, rightPanel), { p: (theme) => `calc(${theme.spacing(1)})` });
    const rightPanelDimensionsFULL = Object.assign(Object.assign({}, rightPanel), { width: `calc(100vw - ${sideBarWidth} )`, p: (theme) => `calc(${theme.spacing(1)})` });
    useEffect(() => {
        if (activePanels.plots) {
            setFocusedPanel(Panels.plots);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePanels.plots]);
    // const { showGraph } = FEPGraphStore();
    const handleClickToggleFullscreen = () => {
        toggleFullscreen();
    };
    const handleClickHide = () => {
        setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: false }));
    };
    const [tables] = NAPTableStore((state) => [state.tables], shallow);
    const [plots] = NAPPlotStore((state) => [state.plots], shallow);
    const numTables = tables.filter((t) => !t.archived).length;
    const numPlots = plots.filter((p) => !p.archived).length;
    const singleElement = numTables + numPlots === 1 ? true : false;
    const multipleInstanceOneElement = (numTables === 0 || numPlots === 0) &&
        numTables + numPlots > 1 &&
        !fileSelectorAsTabs;
    let handleDeleteAll;
    if (multipleInstanceOneElement) {
        const elementType = numTables > 0 ? DatavizElements.Tables : DatavizElements.Plots;
        handleDeleteAll = () => {
            deleteAllDatavizElementIntances(elementType, numTables, numPlots);
        };
    }
    return (_jsxs(_Fragment, { children: [_jsx(Fade, Object.assign({ in: activePanels.plots, style: { zIndex: focusedPanel === Panels.plots ? 2 : 1 } }, { children: _jsx(Box, Object.assign({ id: "plotpage-entrypoint", sx: isFullscreen ? rightPanelDimensionsFULL : rightPanelDimensions, onClick: () => {
                        setFocusedPanel(Panels.plots);
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: singleElement ? "auto" : 12 }, { children: _jsx(TablePlotsButtons, { isFullscreen: isFullscreen, handleClickToggleFullscreen: handleClickToggleFullscreen, handleClickHide: handleClickHide, handleDeleteAll: handleDeleteAll }) })), !!(dataVizDetails.name || dataVizDetails.description) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, direction: "column", sx: { order: 1, px: 1 } }, { children: [!!dataVizDetails.name && (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "h5" }, { children: dataVizDetails.name }))), !!dataVizDetails.description && (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", color: "text.secondary" }, { children: dataVizDetails.description })))] }))), _jsx(TablePlotsTabs, { vss: vss, molstar: molstar, pyodide: pyodide, NAPGenericStore: NAPGenericStore, skipButtons: skipButtons, fileSelectorAsTabs: fileSelectorAsTabs })] })) })) })), _jsx(NavigationTableData, { NAPGenericStore: NAPGenericStore, vss: vss })] }));
};
