// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
function optionsToLabelMap(options) {
    let result = {};
    for (const option of options) {
        result[option.value] = option.label;
    }
    return result;
}
export const representationColorOptions = [
    { label: "Atom ID", value: "atom-id" },
    { label: "Chain ID", value: "chain-id" },
    { label: "Element", value: "element-symbol" },
    { label: "Element Index", value: "element-index" },
    { label: "Hydrophobicity", value: "hydrophobicity" },
    { label: "Illustrative", value: "illustrative" },
    { label: "Model Index", value: "model-index" },
    { label: "Molecule Type", value: "molecule-type" },
    { label: "Polymer ID", value: "polymer-id" },
    { label: "Residue Name", value: "residue-name" },
    { label: "Secondary Structure", value: "secondary-structure" },
    { label: "Sequence ID", value: "sequence-id" },
    { label: "Custom color", value: "uniform" },
    { label: "Uncertainty", value: "uncertainty" },
];
export const defaultColor = "element-symbol";
export const repColorToLabel = optionsToLabelMap(representationColorOptions);
export const representationTypeOptions = [
    { label: "Ball and Stick", value: "ball-and-stick" },
    { label: "Backbone", value: "backbone", onlyProtein: true },
    { label: "Cartoon", value: "cartoon", onlyProtein: true },
    { label: "Ellipsoid", value: "ellipsoid", onlyProtein: true },
    { label: "Gaussian Surface", value: "gaussian-surface" },
    { label: "Label", value: "label" },
    { label: "Line", value: "line" },
    { label: "Molecular Surface", value: "molecular-surface" },
    { label: "Points", value: "point" },
    { label: "Putty", value: "putty", onlyProtein: true },
    { label: "Spacefill", value: "spacefill" },
];
export const defaultType = "line";
export const repTypeToLabel = optionsToLabelMap(representationTypeOptions);
export const getRepresentationTypeOptions = (isSmallMol) => {
    return isSmallMol
        ? representationTypeOptions.filter((repType) => !repType.onlyProtein)
        : representationTypeOptions;
};
export const representationOpacityOptions = [
    {
        label: "0",
        value: 0,
    },
    {
        label: "0.2",
        value: 0.2,
    },
    {
        label: "0.4",
        value: 0.4,
    },
    {
        label: "0.6",
        value: 0.6,
    },
    {
        label: "0.8",
        value: 0.8,
    },
    {
        label: "1",
        value: 1,
    },
];
