import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { IconButton, Grid, TextField, Typography, Tooltip, Button, } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { indexSelector, addReverseIndexing, FileType } from "../..";
import InputAdornment from "@mui/material/InputAdornment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
function EntryIdInput({ strArray, disabled, currentTextFieldVal, setCurrentTextFieldVal, setActiveElements, setActiveTextFieldVal, inputError = "", setInputError, visibility = true, }) {
    const applySelection = () => {
        try {
            const newActiveElements = indexSelector(currentTextFieldVal, addReverseIndexing(strArray));
            setInputError("");
            setActiveElements(newActiveElements);
            setActiveTextFieldVal(currentTextFieldVal);
        }
        catch (e) {
            setInputError(`${e}`);
            setActiveElements([]);
            setActiveTextFieldVal(undefined);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            applySelection();
        }
    };
    return (_jsx(TextField, { size: "small", type: "text", value: currentTextFieldVal, disabled: disabled, error: inputError ? true : false, onChange: (e) => setCurrentTextFieldVal(e.target.value), onKeyDown: handleKeyDown, placeholder: "(index)", InputProps: {
            endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(Tooltip, Object.assign({ title: "Select", arrow: true, placement: "top" }, { children: _jsx(Button, Object.assign({ variant: "contained", size: "small", onClick: applySelection, style: { minWidth: "2rem", width: "2.5rem" }, disabled: !visibility }, { children: _jsx(SendIcon, { sx: { fontSize: "1rem", color: "white" } }) })) })) }))),
            sx: {
                fontSize: "0.875rem",
                color: disabled ? "#808080" : "black",
                pr: "5px",
            },
        } }));
}
function NextPrevButtons({ strArray, disabled, activeElements, setActiveElements, setCurrentTextFieldVal, setActiveTextFieldVal, setInputError, }) {
    const numOfEntries = strArray.length;
    function onArrayUpdate(newID) {
        const newActiveElements = [strArray[newID]];
        setActiveElements(newActiveElements);
        const selString = (newID + 1).toString();
        setCurrentTextFieldVal(selString);
        setActiveTextFieldVal(selString);
        setInputError("");
    }
    const nextEntry = () => {
        let _next;
        if (activeElements.length === 1) {
            const currentIdx = strArray.indexOf(activeElements[0]);
            _next = (currentIdx + 1) % numOfEntries;
        }
        else {
            _next = 0;
        }
        onArrayUpdate(_next);
    };
    const prevEntry = () => {
        let _prev;
        if (activeElements.length === 1) {
            const currentIdx = strArray.indexOf(activeElements[0]);
            if (currentIdx === 0) {
                _prev = numOfEntries - 1;
            }
            else {
                _prev = currentIdx - 1;
            }
        }
        else {
            _prev = numOfEntries - 1;
        }
        onArrayUpdate(_prev);
    };
    const arrowStyle = { fontSize: "1.3rem" };
    return (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Tooltip, Object.assign({ title: "Previous", arrow: true, placement: "top" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: prevEntry, disabled: disabled, size: "small" }, { children: _jsx(ArrowBackIosNewIcon, { sx: arrowStyle }) })) })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Tooltip, Object.assign({ title: "Next", arrow: true, placement: "top" }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: nextEntry, disabled: disabled, size: "small" }, { children: _jsx(ArrowForwardIosIcon, { sx: arrowStyle }) })) })) }))] }));
}
function validateIndexSelectionString(selectedElementIDs, strArray) {
    let selectedElements;
    let correctedSelectionString;
    if (selectedElementIDs !== undefined) {
        try {
            selectedElements = indexSelector(selectedElementIDs, addReverseIndexing(strArray));
            correctedSelectionString = selectedElementIDs;
        }
        catch (e) {
            selectedElements = strArray;
            correctedSelectionString = "all";
            // throw new Error(`Incorrect element selection - ${e}`);
        }
    }
    else {
        selectedElements = strArray;
        correctedSelectionString = "all";
    }
    return [selectedElements, correctedSelectionString];
}
export function EntryDisplayControls({ structCellRefs, predefinedIndexSelection, fileType, visibility, onActiveElementsUpdate, onVisibilityChange, }) {
    const [initActiveElements, initTextFieldVal] = validateIndexSelectionString(predefinedIndexSelection, structCellRefs);
    const [activeElements, setActiveElements] = useState(initActiveElements);
    const [currentTextFieldVal, setCurrentTextFieldVal] = useState(initTextFieldVal);
    const [activeTextFieldVal, setActiveTextFieldVal] = useState(initTextFieldVal);
    useEffect(() => {
        setCurrentTextFieldVal(initTextFieldVal);
        setActiveTextFieldVal(initTextFieldVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predefinedIndexSelection]);
    const _elementName = fileType === FileType.sdf ? "Compound" : "System";
    useEffect(() => {
        onActiveElementsUpdate(activeTextFieldVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeElements]);
    useEffect(() => {
        if (onVisibilityChange) {
            onVisibilityChange(activeTextFieldVal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibility]);
    const [inputError, setInputError] = useState("");
    return (_jsx("div", Object.assign({ style: { padding: 10 } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", justifyContent: "center" }, { children: [_jsxs(Grid, Object.assign({ item: true }, { children: [_jsxs(Typography, Object.assign({ variant: "caption", sx: { color: visibility ? "black" : "#808080" } }, { children: [structCellRefs.length, " ", _elementName.toLowerCase(), "s found. Showing:"] })), _jsxs(Grid, Object.assign({ container: true, item: true, spacing: 2, alignItems: "center", direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, sx: { width: "9em" } }, { children: _jsx(EntryIdInput, { strArray: structCellRefs, disabled: !visibility, currentTextFieldVal: currentTextFieldVal, setCurrentTextFieldVal: setCurrentTextFieldVal, setActiveElements: setActiveElements, setActiveTextFieldVal: setActiveTextFieldVal, inputError: inputError, setInputError: setInputError, visibility: visibility }) })), _jsx(Grid, Object.assign({ item: true, style: { paddingLeft: "0.25em", minHeight: 0, height: "2.5rem" } }, { children: _jsx(CompoundSelectorInstructions, { color: visibility ? "secondary.main" : "#808080" }) })), _jsx(Grid, Object.assign({ item: true, container: true, xs: "auto", flexWrap: "nowrap" }, { children: _jsx(NextPrevButtons, { strArray: structCellRefs, disabled: !visibility, activeElements: activeElements, setActiveElements: setActiveElements, setCurrentTextFieldVal: setCurrentTextFieldVal, setActiveTextFieldVal: setActiveTextFieldVal, setInputError: setInputError }) }))] }))] })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem", color: "#d32f2f" } }, { children: inputError })) }))] })) })));
}
const CompoundSelectorInstructions = ({ color }) => {
    const instructions = `
  Insert comma-separated indices, ranges or the 'all' keyword. 
  E.g. '1-4, 6, -1' corresponds to: 1st to 4th, 6th, and last element.
  Note that 'all' is used without any indices.
  `;
    return (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", title: instructions }, { children: _jsx(HelpOutlineIcon, { sx: { color: color } }) })));
};
