import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Collapse, Paper, Box, Typography, Button, IconButton, Menu, MenuItem, } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../FormGenerator/utils";
import CloseIcon from "@mui/icons-material/Close";
import { arrayify_enum } from "../../../utils/NAPConfigValidator/arrayify_enum";
import { MeasurementType, SelectionStore, } from "../../../StateStores/selection.store";
import { shallow } from "zustand/shallow";
import { MeasurementsList } from "./MeasurementsList";
export function MeasurementsMenu({ isActive, molstar, backgroundColor, }) {
    const [activeMeasurement, setActiveMeasurement,
    // measurementLoci
    ] = SelectionStore((state) => [
        state.activeMeasurement,
        state.setActiveMeasurement,
        // state.measurementLoci,
    ], shallow);
    // const measuresAvail = Object.values(measurementLoci).some(
    //   (measurementLi) => measurementLi.length > 0
    // );
    useEffect(() => {
        if (!isActive)
            setActiveMeasurement(undefined);
    }, [isActive]);
    const [showList, setShowList] = useState(true);
    const handleSelectMeasure = (measurementType) => {
        setActiveMeasurement(measurementType);
    };
    useEffect(() => {
        // Clear selection
        if (activeMeasurement) {
            molstar.managers.interactivity.lociSelects.deselectAll();
            SelectionStore.getState().setSelectedResidues({});
        }
    }, [activeMeasurement]);
    let numItemsToSelect;
    switch (activeMeasurement) {
        case MeasurementType.distance:
            numItemsToSelect = 2;
            break;
        case MeasurementType.angle:
            numItemsToSelect = 3;
            break;
        case MeasurementType.dihedral:
            numItemsToSelect = 4;
            break;
        default:
            numItemsToSelect = 0;
    }
    return (_jsx(Collapse, Object.assign({ in: isActive, unmountOnExit: true }, { children: _jsxs(Paper, Object.assign({ elevation: 0, sx: {
                backgroundColor: backgroundColor ? backgroundColor : undefined,
            } }, { children: [_jsxs(Box, Object.assign({ sx: { mt: 1, py: "4px", px: 2, gap: 0 }, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }, { children: [_jsxs(Box, Object.assign({ sx: { gap: 2, width: "100%" }, display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "15px" } }, { children: "Measurements" })), _jsx(AddMeasureButton, { handleSelect: handleSelectMeasure, activeMeasurement: activeMeasurement })] })), !!activeMeasurement && (_jsx(Typography, Object.assign({ variant: "caption" }, { children: `Select ${numItemsToSelect} item${numItemsToSelect > 1 ? "s" : ""}` })))] })), _jsx(Collapse, Object.assign({ in: showList }, { children: _jsx(MeasurementsList, { molstar: molstar }) }))] })) })));
}
function AddMeasureButton({ handleSelect, activeMeasurement, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const _handleSelect = (measurementType) => {
        handleSelect(measurementType);
        handleClose();
    };
    return (_jsxs(Box, Object.assign({ display: "flex", gap: 0 }, { children: [_jsx(Button, Object.assign({ disabled: !!activeMeasurement, "aria-controls": open ? "basic-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleClick, variant: "text", size: "small", endIcon: !activeMeasurement && _jsx(AddCircleOutlineIcon, { fontSize: "small" }) }, { children: _jsxs(Typography, Object.assign({ sx: { fontSize: "15px" } }, { children: [activeMeasurement ? capitalizeFirstLetter(activeMeasurement) : "Add", " "] })) })), _jsx(Menu, Object.assign({ anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                } }, { children: arrayify_enum(MeasurementType).map((measurement) => (_jsx(MenuItem, Object.assign({ onClick: () => {
                        _handleSelect(measurement);
                    } }, { children: capitalizeFirstLetter(measurement) }), `measurement-menu-item-${measurement}`))) })), !!activeMeasurement && (_jsx(IconButton, Object.assign({ size: "small", sx: { color: "#8c0d26" }, onClick: () => {
                    handleSelect(undefined);
                } }, { children: _jsx(CloseIcon, { fontSize: "small" }) })))] })));
}
