import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { ProtaelWrapper } from "./";
import { Box } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
//Examples
const MSAexamples = [
    {
        alidisplay: false,
        alignments: [
            {
                label: "SEQ",
                start: 0,
                sequence: "LSYQDSLACRLVFLLMQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVSIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "6KK7",
                start: 0,
                sequence: "LSYQDSLACRLVFLLMQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVSIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "6KK1",
                start: 0,
                sequence: "LSYQDSLACRLVFLLMQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVAIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "6KJV",
                start: 0,
                sequence: "LSYQDSLACRLVFLLMQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVAIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "5VEX",
                start: 0,
                sequence: "LSYQDSLACRLVFLLCQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVAIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "5VEW",
                start: 0,
                sequence: "LSYQDSLACRLVFLLCQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVAIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
                CS: "Clustal",
            },
            {
                label: "5EE7",
                start: 7,
                sequence: "       ACRVAAVFMQYGIVANYCWLLVEGLYLHNLLGLNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYPERSFFSLYLGIGWGAPALFVVPWAVVKCLFENVQCWTSNDNMGFWWILRFPVFLAILINFFIFVRIVQLLVAKLRARQMHHTDYAFRLAKSTLTLIPLLGVHFVVFAFVTD",
                CS: "Clustal",
            },
            {
                label: "5YQZ",
                start: 8,
                sequence: "        CRVAAVFMQYGIVANYCWLLVEGLYLHNLLATNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY-ERSFFSLYLGIGWGAPMLFVVPWAVVKCLFENVQCWTSNDNMGFWWILRFPVFLAILINFFIFVRIVQLLVAKLRARQMHHTDYKFRLAKSTLTLIPLLGVHEVVFAFVTD",
                CS: "Clustal",
            },
            {
                label: "5XF1",
                start: 8,
                sequence: "        CRVAAVFMQYGIVANYCWLLVEGLYLHNLLGLNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY-ERSFFSLYLGIGWGAPMLFVVPWAVVKCLFENVQCWTSNDNMGFWWILRFPVFLAILINFFIFVRIVQLLVAKLRARQMHHTDYKFRLAKSTLTLIPLLGVHEVVFAFVTD",
                CS: "Clustal",
            },
            {
                label: "5XEZ",
                start: 8,
                sequence: "        CRVAAVFMQYGIVANYCWLLVEGLYLHNLLGLNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY-ERSFFSLYLGIGWGAPMLFVVPWAVVKCLFENVQCWTSNDNMGFWWILRFPVFLAILINFFIFVRIVQLLVAKLRARQMHHTDYKFRLAKSTLTLIPLLGVHEVVFAFVTD",
                CS: "Clustal",
            },
            {
                label: "4K5Y",
                start: 8,
                sequence: "        CRLVTAAYNYFHVTNFFWMFGEGCYLHTAIVLNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLSVAKSELDKAIGRNSNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRSALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVIATFRTGTWDAYDRAWMF---ICIGWGVPFPIIVAWAIGKLYYDNEKCWAKRPGVYTDYIYQGPMALVLLINFIFLFNIVRILMTKLRASTTSETIQARKAVKATLVLLPLL            ",
                CS: "Clustal",
            },
            {
                label: "4Z9G",
                start: 8,
                sequence: "        CRLVTAAYNYFHVTNFFWMFGEGCYLHTAIVLNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLSVAKSELDKAIGRNSNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRSALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVIATFRTGTWDAYTDRLRAWMFICIGWGVPFPIIVAWAIGKLYYDNEKCWAKRPGVYTDYIYQGPMALVLLINFIFLFNIVRILMTKLRASTTSETIQARKAVKATLVLLPLL            ",
                CS: "Clustal",
            },
            {
                label: "1JTN",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYKN---LETYYTIGIG                                                                                                 ",
                CS: "Clustal",
            },
            {
                label: "1JTM",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYKN---LETYYTIGIG                                                                                                 ",
                CS: "Clustal",
            },
            {
                label: "4GBR",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYA                                                                                                               ",
                CS: "Clustal",
            },
            {
                label: "6U0C",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "6U0B",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "6PH1",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "6PH0",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5VNR",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5VNQ",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "2OEA",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "2OE9",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "2OE7",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "2OE4",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "219L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1LW9",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L63",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1C6T",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1C6Q",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1C6P",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "3RUN",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "212L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5V7F",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5KI8",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5KI3",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "5KHZ",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "4PK0",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "4PJZ",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "2L78",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGIAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1P7S",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMIFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1P36",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALVNMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1P2R",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGVLRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1P2L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPIYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1G0Q",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRIITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "4YXC",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYA                                                                                                               ",
                CS: "Clustal",
            },
            {
                label: "1P56",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1PQO",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSIRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L93",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAAMINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L92",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAAIINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1D3J",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKMFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CV5",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNMAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CV4",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSMRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CV3",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMMQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CU5",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSMDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CU2",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKMKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "4YXA",
                start: 26,
                sequence: "                          ILIKRKTLERYFSINIFEMLRIDEGLRLKIYKNTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYA                                                                                                               ",
                CS: "Clustal",
            },
            {
                label: "4YX7",
                start: 26,
                sequence: "                          ILIKRKTLERYFSINIFEMLRIDEGLRLKIYKNTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYA                                                                                                               ",
                CS: "Clustal",
            },
            {
                label: "234L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQLGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "233L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRLLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "230L",
                start: 40,
                sequence: "                                        NIFELLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "224L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDSVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "221L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKSIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "206L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNASKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L94",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAAVINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L77",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINLVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L68",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKAELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1L61",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPNLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1D3F",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVMTKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1D2Y",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAMGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1D2W",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTMGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CV6",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRMITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CV1",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGMAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CUQ",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMMFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CUP",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALMNMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CU3",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPMYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1CU0",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGMLRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "165L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNALRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "125L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRASLINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "123L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNSKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "122L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDSAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "120L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNSAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "119L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLSKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "118L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEASVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "114L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKTELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "111L",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKNELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
            {
                label: "1QTB",
                start: 40,
                sequence: "                                        NIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAVKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAY                                                                                                                ",
                CS: "Clustal",
            },
        ],
        sequence: "LSYQDSLACRLVFLLMQYCVAANYYWLLVEGVYLYTLLAFNIFEMLRIDEGLRLKIYKDTEGYYTIGIGHLLTKSPSLNAAKSELDKAIGRNTNGVITKDEAEKLFNQDVDAAVRGILRNAKLKPVYDSLDAVRRAALINMVFQMGETGVAGFTNSLRMLQQKRWDEAAVNLAKSRWYNQTPNRAKRVITTFRTGTWDAYSEQWIFRLYVSIGWGVPLLFVVPWGIVKYLYEDEGCWTRNSNMNYWLIIRLPILFACIVNFLIFVRVICIVVSKLKANLMCKTDIAFRLAKSTLTLIPLLCTHEVIFAFVMD",
    },
    {
        sequence: "MSDFDEFERQLNENKQERDKENRHRKRSHSRSRSRDRKRRSRSRDRRNRDQRSASRDRRRRSKPLTRGA",
    },
    {
        alidisplay: true,
        alignments: [
            {
                CS: "Clustal",
                // by default 'ALI' is used (gray lines for aligned regions)
                id: "1bo4",
                label: "1bo4_A",
                sequence: "RTCRLGPDQVKSMRAALDLFGREFGDVATYSQHQPDSDYLGNLLRSKTFIALAAFDQEAVVGALAAYVLPKFEQPRSEIYIYDLAVSGEHRRQGIATALINLLKHEANALGAYVIYVQADYGDDPAVALYTKLGIREEVMH",
                start: 7,
                properties: {
                    // properties attribute will be converted to data-* attribute
                    // and by default used in tooltip
                    pdbid: "1bo4",
                    "PDB structure": "1bo4_A",
                },
            },
            {
                CS: "",
                description: "3tfy_A",
                id: "3tfy",
                label: "3tfy_A",
                sequence: "NDKFYKDVL-EVGELAKLAYFNDIAVGAVCCRV--DHSQNQKRLYIMTLGCLAPYRRLGIGTKMLNHVLNICEKDGTFDNIYLHVQISNESAIDFYRKFG",
                start: 42,
            },
        ],
        sequence: "MISTQTKITRLNSQDVGVMRAMLGMFGEAFEDAENYCRAQPSDSYLQDLLCGSGFIAIAALQGQEVIGGLAAYVLPKFEQQRKEIYIYDLGVQGAYRRRGIATALINELQRIAHDIGAYVIFVQADYGDDPAVALYTKLGIREDVMHFDIEPQPAA",
    },
];
function MSASelector() {
    const [exMSA, setExMSA] = useState("");
    const handleChange = (event) => {
        setExMSA(event.target.value);
    };
    const selectedMSA = exMSA !== "" ? MSAexamples[Number(exMSA)] : undefined;
    return (_jsxs("div", { children: [_jsxs(Select, Object.assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", value: exMSA, label: "Age", onChange: handleChange }, { children: [_jsx(MenuItem, Object.assign({ value: 0 }, { children: "Example 0" })), _jsx(MenuItem, Object.assign({ value: 1 }, { children: "Example 1" })), _jsx(MenuItem, Object.assign({ value: 2 }, { children: "Example 2" }))] })), _jsx(Box, Object.assign({ sx: { height: "30em", overflowY: "scroll", overflowX: "hidden" }, id: "parent-protaelwrapper" }, { children: _jsx(ProtaelWrapper, { msa: selectedMSA, onDelete: () => console.log("Delete") }) }))] }));
}
export default MSASelector;
