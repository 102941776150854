var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContentText, FormHelperText, CircularProgress, Grid, } from "@mui/material";
import { TextFieldPM } from "../../SharedFormParts/TextField";
import { executeWithTokenRefresh } from "../executeWithTokenRefresh";
import { MailVerificationSchema, mailVerificationDefaults, } from "./MailCodeVerificationSchema";
export function MailCodeVerificationForm({ onSuccess, email, }) {
    var _a;
    const formMethods = useForm({
        resolver: yupResolver(MailVerificationSchema),
        defaultValues: mailVerificationDefaults,
    });
    const { handleSubmit, formState: { errors }, } = formMethods;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        const code = data.code;
        setIsSubmitting(true);
        setErrorMessage("");
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/auth/verify/validate-verification-code`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mail: email,
                code: code,
            }),
        }), true);
        if (response.success) {
            setTimeout(() => setIsSubmitting(false), 200);
            onSuccess(code);
        }
        else {
            setIsSubmitting(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong. Please try again or contact support if the issue continues.";
            setErrorMessage(errorMsg);
        }
    });
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(FormProvider, Object.assign({}, formMethods, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ textAlign: "left" }, { children: "Please enter the confirmation code we sent to your email." })) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { size: "small", formKey: "code", label: "Confirmation code", error: (_a = errors["code"]) === null || _a === void 0 ? void 0 : _a.message }) })), !!errorMessage && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: errorMessage })) }))), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disabled: isSubmitting, endIcon: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : undefined }, { children: "Confirm" })) })) }))] })) })) })));
}
