import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Box } from "@mui/material";
import { Panels, } from "../../GenericViewerState/SharedState";
import { bottomPanel } from "../panelDimensions";
import { PyodideTerminal } from "../../PyodideTerminal/PyodideTerminal";
export const ConsolePanel = ({ NAPGenericStore, }) => {
    const [activePanels, focusedPanel, setFocusedPanel] = NAPGenericStore((state) => [
        state._activePanels,
        state._focusedPanel,
        state.setFocusedPanel,
    ], shallow);
    const dimensions = Object.assign(Object.assign({}, bottomPanel), { left: (theme) => `calc(${theme.spacing(7)})`, width: (theme) => `calc(100vw - ${theme.spacing(7)} )`, height: activePanels.console ? bottomPanel.height : "1.75em", zIndex: focusedPanel === Panels.console ? 2 : 1 });
    return (_jsx(Box, Object.assign({ id: "console-entrypoint", sx: dimensions, onFocus: () => setFocusedPanel(Panels.console) }, { children: _jsx(PyodideTerminal, { NAPGenericStore: NAPGenericStore }) })));
};
