import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useLocation } from "wouter";
import { shallow } from "zustand/shallow";
import { userAuthStore } from "..";
import { Box, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { resetAllState } from "../utils/resetState";
import { NAPTableStore } from "../StateStores/tables.store";
import { AuthenticationDialog } from "./AuthenticationDialog";
import { UserOptions } from "./UserOptions/UserOptionsMenu";
function RegisterLogin() {
    const [, setLocation] = useLocation();
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Log In / Register", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ onClick: () => setLocation("/login"), sx: { cursor: "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(LoginIcon, {}) })) }) })) }), "pm-sidebar-authentication-login-tooltip"));
}
export function AuthenticationButtons({ vss, molstar, }) {
    const [logout, userInfo] = userAuthStore((state) => [state.logout, state.userInfo], shallow);
    const [, setLocation] = useLocation();
    const handleLogoutClick = () => {
        logout();
        resetAllState(molstar, vss, NAPTableStore);
        setLocation("/");
    };
    const isAuthenticated = !!userInfo;
    return (_jsxs(_Fragment, { children: [isAuthenticated ? (_jsx(UserOptions, { userInfo: userInfo, handleLogoutClick: handleLogoutClick })) : (_jsx(RegisterLogin, {})), _jsx(AuthenticationDialog, { isAuthenticated: isAuthenticated })] }));
}
