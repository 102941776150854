var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { useState, useMemo } from "react";
import { setVisibility } from "../VisibilityToggle";
import { removeSystem } from "../Controls/utils";
import { Panels, } from "../../GenericViewerState/SharedState";
import { updateStateTreeVisibility, getSystemStateVisibility, getAllChildSystems, selectSystemByIndexArray, updateStateTreeParameter, } from "../stateTree";
import { childCellRefFromIndexArray } from "./groupUtils";
import { SystemGroupAccordion } from "./SystemGroupAccordion";
import { SystemGroupAccordionSimplified } from "./SystemGroupAccordionSimplified";
import produce from "immer";
function removeVisibleGroupFiles(positionInTree, removeVisibleSystemsFiles, vss) {
    const childSystems = getAllChildSystems(vss, positionInTree);
    childSystems.forEach((syst) => {
        if (syst.files) {
            removeVisibleSystemsFiles(syst.files);
        }
    });
}
export function CustomAccordion({ vss, molstar, pyodide, name, children, positionInTree, NAPGenericStore, simplified, disabled, loading, }) {
    const [activeSystemPosition, activePanels, setActivePanels, removeVisibleSystemsFiles, closeSystemRepresentations,] = NAPGenericStore((state) => [
        state._activeSystemPosition,
        state._activePanels,
        state.setActivePanels,
        state.removeVisibleSystemsFiles,
        state.closeSystemRepresentations,
    ], shallow);
    const groupVisibility = useMemo(() => getSystemStateVisibility(vss, positionInTree), [positionInTree, vss]);
    const childRefs = childCellRefFromIndexArray(vss, positionInTree);
    const [accordionIsExpanded, setAccordionIsExpanded] = useState(false);
    const toggleAccordion = () => {
        setAccordionIsExpanded((currentIsExpanded) => !currentIsExpanded);
    };
    const handleDeleteGroupClick = () => __awaiter(this, void 0, void 0, function* () {
        closeSystemRepresentations();
        removeVisibleGroupFiles(positionInTree, removeVisibleSystemsFiles, vss);
        removeSystem(molstar, pyodide, vss, childRefs, positionInTree);
        const loaded_structures = vss.getState().loaded_structures;
        if (loaded_structures.length === 0)
            setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false, [Panels.sequence]: false }));
    });
    const handleUngroupClick = () => {
        const loaded_structures = vss.getState().loaded_structures;
        const updatedTree = produce(loaded_structures, (draft) => {
            let siblings = draft;
            if (positionInTree.length > 1) {
                const parentPos = positionInTree.slice(0, -1);
                const parentSyst = selectSystemByIndexArray(parentPos, draft);
                if (parentSyst.children) {
                    siblings = parentSyst.children;
                }
            }
            const startIdx = positionInTree[positionInTree.length - 1];
            const oldGroupArr = siblings.splice(startIdx, 1);
            const oldGroupElements = oldGroupArr.length > 0 ? oldGroupArr[0].children : undefined;
            if (!oldGroupElements)
                return;
            siblings.splice(startIdx, 0, ...oldGroupElements);
        });
        vss.getState().set_loaded_structures(updatedTree);
    };
    function handleVisibilityClick() {
        setVisibility(molstar, childRefs, !groupVisibility);
        updateStateTreeVisibility(vss, positionInTree, !groupVisibility);
        if (groupVisibility &&
            activeSystemPosition &&
            JSON.stringify(activeSystemPosition.slice(0, positionInTree.length)) ===
                JSON.stringify(positionInTree)) {
            //This closes the system representation panel if is a child of the group made not visible
            closeSystemRepresentations();
        }
    }
    const updateGroupName = (name) => {
        updateStateTreeParameter(vss, { name }, positionInTree);
    };
    return simplified ? (_jsx(SystemGroupAccordionSimplified, Object.assign({ accordionIsExpanded: accordionIsExpanded, toggleAccordion: toggleAccordion, handleVisibilityClick: handleVisibilityClick, groupVisibility: groupVisibility, handleDeleteGroupClick: handleDeleteGroupClick, name: name, disabled: disabled, loading: loading }, { children: children }))) : (_jsx(SystemGroupAccordion, Object.assign({ accordionIsExpanded: accordionIsExpanded, toggleAccordion: toggleAccordion, handleVisibilityClick: handleVisibilityClick, groupVisibility: groupVisibility, handleDeleteGroupClick: handleDeleteGroupClick, handleUngroupClick: handleUngroupClick, updateGroupName: updateGroupName, name: name, disabled: disabled }, { children: children })));
}
