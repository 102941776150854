var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel, } from "@tanstack/react-table";
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { NAPGenericStore } from "../GenericViewerState";
import { calculateTableHeight, getComponentYDistance, prettifyLabel, } from "./utils";
import { NAPPlotStore, NAPTableStore } from "../StateStores";
import { Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import { useCallback, useEffect, useState, memo, useRef, useMemo, } from "react";
import { HideColumnsMenu } from "./TableButtons/ColumnFilter";
import { DeleteTable } from "./TableButtons/DeleteTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DOMPurify from "isomorphic-dompurify";
import { diff } from "deep-object-diff";
import { Filter } from "./TableButtons/RowFilters";
import { applyColFilter, applySorting, fetchData } from "./fetchData";
import { TableInfo } from "./TableInfo";
import { ToggleNavigationMode } from "./TableButtons/ToggleNavigationMode";
import { TableNavigation } from "./TableNavigation/TableNavigation";
function propsComparison(prevProps, nextProps) {
    const diffIdx = prevProps.activeTableIdx !== nextProps.activeTableIdx;
    const _diffCol = diff(prevProps.columns, nextProps.columns);
    const _diffData = diff(prevProps.data, nextProps.data);
    if (_diffCol || _diffData || diffIdx) {
        return false;
    }
    return true;
}
export const DataTable = memo(DataTableMemo, propsComparison);
function PMVTableHead({ table }) {
    return (_jsx(TableHead, Object.assign({ style: {
            display: "grid",
            position: "sticky",
            top: 0,
            zIndex: 1,
        } }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, Object.assign({ style: { display: "flex", width: "100%" } }, { children: headerGroup.headers.map((header) => {
                var _a;
                return (_jsx(TableCell, Object.assign({ colSpan: header.colSpan, style: {
                        display: "flex",
                        width: header.getSize(),
                    } }, { children: header.isPlaceholder ? null : (_jsxs(Grid, Object.assign({ container: true, alignItems: "center", wrap: "nowrap", direction: "column", justifyContent: "space-between" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: true, direction: "row", alignItems: "center", wrap: "nowrap", style: {
                                    cursor: header.column.getCanSort() ? "pointer" : "auto",
                                }, onClick: header.column.getToggleSortingHandler(), spacing: 1 }, { children: [_jsx(Grid, Object.assign({ xs: true, item: true, sx: { fontWeight: "bold" } }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                                __html: DOMPurify.sanitize(prettifyLabel(header.column.columnDef.header)),
                                            } }) })), _jsx(Grid, Object.assign({ xs: true, item: true }, { children: (_a = {
                                            asc: (_jsx(KeyboardArrowUpIcon, { sx: {
                                                    fontSize: 20,
                                                } })),
                                            desc: (_jsx(KeyboardArrowDownIcon, { sx: {
                                                    fontSize: 20,
                                                } })),
                                        }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : null }))] })), _jsx(Grid, Object.assign({ xs: "auto", item: true }, { children: _jsx(Filter, { column: header.column, table: table }) }))] }))) }), header.id));
            }) }), headerGroup.id))) })));
}
function PMVTableBody({ table, rows, isWaiting, onRowClick, onMouseEnter, onMouseLeave, rowIsActive, tableContainerRef, svgColumns, rowsAreClickable, visibleSystemsFiles, }) {
    var _a, _b;
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        count: rows.length,
        overscan: 10,
        estimateSize: () => (svgColumns && svgColumns.length > 0 ? 145 : 53), //estimate row height for accurate scrollbar dragging
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        // measureElement:
        //   typeof window !== "undefined" &&
        //   navigator.userAgent.indexOf("Firefox") === -1
        //     ? (element) => element?.getBoundingClientRect().height
        //     : undefined,
    });
    const virtualRows = rowVirtualizer.getVirtualItems();
    const totalSize = rowVirtualizer.getTotalSize();
    const paddingTop = virtualRows.length > 0 ? ((_a = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[0]) === null || _a === void 0 ? void 0 : _a.start) || 0 : 0;
    const paddingBottom = virtualRows.length > 0
        ? totalSize - (((_b = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[virtualRows.length - 1]) === null || _b === void 0 ? void 0 : _b.end) || 0)
        : 0;
    const rdkit = window.RDKit;
    const [inactiveRows, setInactiveRows] = useState([]);
    const _onRowClick = (row, isActive) => __awaiter(this, void 0, void 0, function* () {
        const rowIdx = row.index;
        if (!inactiveRows.includes(rowIdx)) {
            setInactiveRows((pre) => [...pre, rowIdx]);
            yield onRowClick(row, isActive);
            setInactiveRows((pre) => pre.filter((idx) => idx !== rowIdx));
        }
    });
    const sortingState = table.getState().sorting;
    const renderRows = useCallback((rows) => virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const isActive = rowIsActive(row);
        return (_jsx(TableRow, Object.assign({ id: `table-row-${row.index}`, "data-index": virtualRow.index, ref: (node) => rowVirtualizer.measureElement(node), hover: true, onClick: () => _onRowClick(row, isActive), onMouseEnter: () => {
                if (onMouseEnter)
                    onMouseEnter(row);
            }, onMouseLeave: () => {
                if (onMouseLeave)
                    onMouseLeave();
            }, style: {
                display: "flex",
                position: "absolute",
                transform: `translateY(${virtualRow.start}px)`,
                width: "100%",
            }, selected: isActive }, { children: row.getVisibleCells().map((cell) => {
                let svgCell = false;
                if (svgColumns) {
                    if (svgColumns.includes(cell.column.id)) {
                        try {
                            const mol = rdkit.get_mol(cell.getValue());
                            svgCell = mol ? mol.get_svg(125, 125) : undefined;
                            mol.delete();
                        }
                        catch (_a) {
                            svgCell = undefined;
                        }
                    }
                }
                return (_jsx(TableCell, Object.assign({ style: {
                        // display: "flex",
                        width: cell.column.getSize(),
                        verticalAlign: "center",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    } }, { children: svgCell ? (_jsx("div", { dangerouslySetInnerHTML: {
                            __html: DOMPurify.sanitize(svgCell),
                        } })) : (flexRender(cell.column.columnDef.cell, cell.getContext())) }), cell.id));
            }) }), row.id));
    }), [
        svgColumns,
        inactiveRows,
        virtualRows,
        sortingState,
        visibleSystemsFiles,
        isWaiting,
    ]);
    return (_jsxs(TableBody, Object.assign({ style: {
            cursor: rowsAreClickable
                ? isWaiting
                    ? "progress"
                    : "pointer"
                : "auto",
            display: "grid",
            height: `${rowVirtualizer.getTotalSize()}px`,
            position: "relative",
        } }, { children: [paddingTop > 0 && (_jsx(TableRow, { children: _jsx(TableCell, { style: { height: `${paddingTop}px` } }) }, "ror-paddingtop")), renderRows(rows), paddingBottom > 0 && (_jsx(TableRow, { children: _jsx(TableCell, { style: { height: `${paddingBottom}px` } }) }, "ror-paddingbottom"))] })));
}
function PMVTable({ table, rows, tableContainerRef, isWaiting, onRowClick, onMouseEnter, onMouseLeave, rowIsActive, svgColumns, rowsAreClickable, visibleSystemsFiles, }) {
    return (_jsxs(Table, Object.assign({ style: {
            display: "grid",
            borderCollapse: "collapse",
            borderSpacing: 0,
            tableLayout: "fixed",
        }, size: "small", stickyHeader: true }, { children: [_jsx(PMVTableHead, { table: table }), _jsx(PMVTableBody, { tableContainerRef: tableContainerRef, table: table, rows: rows, rowsAreClickable: rowsAreClickable, svgColumns: svgColumns, onRowClick: onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, isWaiting: isWaiting, rowIsActive: rowIsActive, visibleSystemsFiles: visibleSystemsFiles })] })));
}
function InfiniteScrollTable({ columns, data, tableID, onRowClick, onMouseEnter, onMouseLeave, rowIsActive, svgColumns, rowsAreClickable, isWaiting, options, onDelete, handleToggleNavigationMode, navigationMode, handleClickTableNavigation, visibleSystemsFiles, }) {
    var _a, _b, _c, _d;
    // Set the component max. size, necessary for the virtual table.
    const plots = NAPPlotStore((state) => state.plots);
    const tables = NAPTableStore((state) => state.tables);
    const [yPosition, setYPosition] = useState(0);
    const tableContainerRef = useRef(null);
    const tableContainerRefCurrent = tableContainerRef.current;
    useEffect(() => {
        const newYPosition = getComponentYDistance(tableContainerRefCurrent);
        if (newYPosition !== yPosition)
            setYPosition(newYPosition);
    }, [tables.length, plots.length, tableContainerRefCurrent, tableID]); //Change to table index
    useEffect(() => {
        const handleResize = () => {
            const newYPosition = getComponentYDistance(tableContainerRefCurrent);
            setYPosition(newYPosition);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tableContainerRefCurrent]);
    const height = useMemo(() => {
        return calculateTableHeight(yPosition, navigationMode);
    }, [yPosition, navigationMode]);
    // Hiden and ignored columns (needs rewriting) --------------
    let _hiddenColumns = [];
    if (options && options.hasOwnProperty("hideColumns")) {
        const _columnNames = columns.map((x) => x.header);
        _hiddenColumns = _columnNames.filter((x) => { var _a; return (_a = options.hideColumns) === null || _a === void 0 ? void 0 : _a.some((c) => c.toLowerCase() === x.toLowerCase()); });
    }
    let _ignoredColumns = [];
    if (options && options.hasOwnProperty("ignoreColumns")) {
        const _columnNames = columns.map((x) => x.header);
        _ignoredColumns = _columnNames.filter((x) => { var _a; return (_a = options.ignoreColumns) === null || _a === void 0 ? void 0 : _a.some((c) => c.toLowerCase() === x.toLowerCase()); });
    }
    const initHiddenColumns = [..._hiddenColumns, ..._ignoredColumns];
    const initHiddenColumnsDict = Object.fromEntries(columns.map((col) => [
        col.header,
        !initHiddenColumns.includes(col.header),
    ]));
    // Table state vars --------------
    const [sorting, setSorting] = useState([]);
    useEffect(() => {
        setSorting([]);
        setColumnFilters([]);
    }, [tableID]);
    const [columnVisibility, setColumnVisibility] = useState(initHiddenColumnsDict);
    const [columnFilters, setColumnFilters] = useState([]);
    // Apply modifications to table data (sort and filter)
    const filteredData = useMemo(() => applyColFilter(columnFilters, data), [columnFilters, data]);
    const filteredSortedData = useMemo(() => applySorting(sorting, filteredData), [sorting, filteredData]);
    // Define data fetching --------------
    const fetchSize = 25;
    const { data: tableData, fetchNextPage, isFetching, isLoading, } = useInfiniteQuery([tableID, sorting, columnFilters], //adding sorting state as key causes table to reset and fetch from new beginning upon sort
    ({ pageParam = 0 }) => __awaiter(this, void 0, void 0, function* () {
        const start = pageParam * fetchSize;
        const fetchedData = yield fetchData(start, fetchSize, filteredSortedData); //pretend api call
        return fetchedData;
    }), {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const flatData = useMemo(() => { var _a, _b; return (_b = (_a = tableData === null || tableData === void 0 ? void 0 : tableData.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [tableData]);
    const totalDBRowCount = (_d = (_c = (_b = (_a = tableData === null || tableData === void 0 ? void 0 : tableData.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.totalRowCount) !== null && _d !== void 0 ? _d : 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
            if (scrollHeight - scrollTop - clientHeight < 300 &&
                !isFetching &&
                totalFetched < totalDBRowCount) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, isFetching, totalFetched, totalDBRowCount]);
    // Create react data obj --------------
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);
    const table = useReactTable({
        data: flatData,
        columns,
        state: {
            sorting,
            columnVisibility,
            columnFilters,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        // getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const { rows } = table.getRowModel();
    if (isLoading) {
        return (_jsxs(Box, Object.assign({ sx: { width: "100%", ml: 1 }, display: "flex", gap: 1 }, { children: [_jsx(CircularProgress, { size: "20px" }), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Loading..." }))] })));
    }
    const _handleClickTableNavigation = (rowIdx) => __awaiter(this, void 0, void 0, function* () {
        if (!handleClickTableNavigation)
            return;
        if (rowIdx >= totalFetched - 1 &&
            !isFetching &&
            totalFetched < totalDBRowCount) {
            NAPGenericStore.getState().setLoadingDCFile(true);
            yield fetchNextPage();
        }
        const row = rows[rowIdx];
        if (!row) {
            NAPGenericStore.getState().setLoadingDCFile(false);
            return;
        }
        yield handleClickTableNavigation(row);
        NAPGenericStore.getState().setLoadingDCFile(false);
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ item: true, container: true, xs: "auto", alignItems: "center", sx: { order: 3 } }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(HideColumnsMenu, { table: table, ignoredColumns: _ignoredColumns }) })) })), !!handleToggleNavigationMode && (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(ToggleNavigationMode, { handleClick: handleToggleNavigationMode, isActive: navigationMode }) })) }))), onDelete ? (_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Box, Object.assign({ sx: { width: "32px" } }, { children: _jsx(DeleteTable, { onDelete: onDelete }) })) }))) : null] })), _jsx(Grid, Object.assign({ item: true, xs: 12, sx: { cursor: isWaiting ? "wait" : "auto", order: 5 } }, { children: _jsxs(TableContainer, Object.assign({ ref: tableContainerRef, style: {
                        maxHeight: height,
                        overflow: "auto",
                        position: "relative", //needed for sticky header
                    }, onScroll: (e) => fetchMoreOnBottomReached(e.target) }, { children: [rowsAreClickable && (_jsx(TableInfo, { text: "Click on the table rows to hide/show data." })), _jsx(PMVTable, { table: table, rows: rows, rowsAreClickable: rowsAreClickable, tableContainerRef: tableContainerRef, svgColumns: svgColumns, onRowClick: onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, isWaiting: isWaiting, rowIsActive: rowIsActive, visibleSystemsFiles: visibleSystemsFiles })] })) })), !!navigationMode && (_jsx(TableNavigation, { handleClickTableNavigation: _handleClickTableNavigation, numRows: rows.length }))] }));
}
const queryClient = new QueryClient();
function DataTableMemo(args) {
    return (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(InfiniteScrollTable, Object.assign({}, args)) })));
}
