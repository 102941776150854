// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import produce from "immer";
export const NAPMSAStore = create()((set) => ({
    msaArr: [],
    set_msaArr: (x) => set({ msaArr: x }),
    add_msa: (x) => set((state) => ({ msaArr: [...state.msaArr, x] })),
    del_msa: (idx, cb) => set((state) => {
        const updated_msa_arr = produce(state.msaArr, (draft) => {
            draft.splice(idx, 1);
        });
        if (updated_msa_arr.length === 0)
            cb();
        return { msaArr: updated_msa_arr };
    }),
}));
