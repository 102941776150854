var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { getDatasets } from "../apps/results/fetchDcData";
import { create } from "zustand";
import { createFiletree as _createFiletree } from "./createFiletree";
import { executeWithTokenRefresh } from "../Authentication";
/** The FileMap-type objects are parsed by chonky's hooks in order to
 * render the file list and the folder path. The default value passed
 * to the state is essentially an empty directory.
 */
const emptyFileMap = {
    rootFolderId: "Projects",
    fileMap: {
        Projects: {
            id: "Projects",
            name: "Projects",
            isDir: true,
            childrenIds: [],
            childrenCount: 0,
        },
    },
};
export var ActiveDataset;
(function (ActiveDataset) {
    ActiveDataset["CATH"] = "CATHDataset";
    ActiveDataset["user"] = "userDataset";
})(ActiveDataset || (ActiveDataset = {}));
export const DCBrowserStore = create((set, get) => ({
    project: "default_project",
    sessionProjects: undefined,
    currentFolderId: "",
    currentFolderContent: [],
    folderChain: [],
    // datacenterEntries: emptyFileMap as unknown as DatacenterResponse,
    selectedFiles: [],
    userDataset: emptyFileMap,
    CATHDataset: emptyFileMap,
    CATHDatasetIsReady: false,
    activeDataset: ActiveDataset.user,
    setCurrentFolderId: (folderId) => {
        set({ currentFolderId: folderId });
        let dcEntries = get().getActiveDatasetEntries();
        get().updateFolderChain(dcEntries, folderId);
        get().getCurrentFolderContent(dcEntries, folderId);
    },
    updateFolderChain: (dcEntries, currentFolderId) => {
        const { fileMap, rootFolderId } = dcEntries;
        const currentFolder = fileMap[currentFolderId]
            ? fileMap[currentFolderId]
            : fileMap[rootFolderId];
        const _folderChain = [currentFolder];
        //@ts-ignore
        let parentId = currentFolder.parentId;
        while (parentId) {
            const parentFile = fileMap[parentId];
            if (parentFile) {
                _folderChain.unshift(parentFile);
                //@ts-ignore
                parentId = parentFile.parentId;
            }
            else {
                parentId = null;
            }
        }
        set({ folderChain: _folderChain });
    },
    getCurrentFolderContent: (dcEntries, currentFolderId, filterExtension) => {
        const { fileMap, rootFolderId } = dcEntries;
        const currentFolder = fileMap[currentFolderId]
            ? fileMap[currentFolderId]
            : fileMap[rootFolderId];
        let files = currentFolder.childrenIds
            ? currentFolder.childrenIds.map((fileId) => { var _a; return (_a = fileMap[fileId]) !== null && _a !== void 0 ? _a : null; })
            : [];
        if (filterExtension && filterExtension.length > 0) {
            files = files.filter((myFile) => {
                if (!myFile)
                    throw new Error("invalid file");
                //@ts-ignore
                return !myFile.isDir && myFile.id
                    ? //@ts-ignore
                        filterExtension.indexOf(myFile.id.split(".").pop()) >= 0
                    : true;
            });
        }
        set({ currentFolderContent: files });
    },
    setDatacenterEntries: (entries, activeDataset) => {
        if (activeDataset === "userDataset") {
            set({ userDataset: entries });
        }
        else if (activeDataset === "CATHDataset") {
            set({ CATHDataset: entries, CATHDatasetIsReady: true });
        }
    },
    setSelectedFiles: (fileIDs) => set({ selectedFiles: fileIDs }),
    setProject: (project, userId) => {
        if (userId) {
            window.highPriorityMsg({
                type: "fetch-datasets",
                userId: userId,
                url: window.location.origin,
                project: project,
                setCurrentDir: true,
                priority: true,
            });
        }
        window.highPriorityMsg({ type: "set-job-project", project: project });
        set({ project: project });
    },
    setSessionProjects: (projects) => set({ sessionProjects: projects }),
    addSessionProject: (project, userId) => {
        const _project = project.replace(" ", "_");
        const currentProjects = get().sessionProjects;
        const newProjects = currentProjects
            ? [...currentProjects, _project]
            : undefined;
        set({ sessionProjects: newProjects });
        get().setProject(_project, userId);
    },
    removeSessionProject: (projectToRemove, userId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Change project to default
        if (projectToRemove === get().project) {
            get().setProject("default_project", userId);
        }
        // Remove deleted project from available projects
        set({
            sessionProjects: (_a = get().sessionProjects) === null || _a === void 0 ? void 0 : _a.filter((e) => e !== projectToRemove),
        });
        // Delete all associated job results
        if (userId) {
            const datasets = yield getDatasets({
                startswith: `${userId}/${projectToRemove}`,
                useronly: true,
            });
            if (!datasets)
                return;
            const execids = datasets.map((ds) => ds.execid);
            yield get().removeJobResults(execids, userId);
        }
    }),
    createFiletree: _createFiletree,
    removeJobResults: (execIds, userId, updateDataset) => { var _a, execIds_1, execIds_1_1; return __awaiter(void 0, void 0, void 0, function* () {
        var _b, e_1, _c, _d;
        try {
            for (_a = true, execIds_1 = __asyncValues(execIds); execIds_1_1 = yield execIds_1.next(), _b = execIds_1_1.done, !_b;) {
                _d = execIds_1_1.value;
                _a = false;
                try {
                    const execId = _d;
                    yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/jobs/${execId}/delete`, {
                        method: "DELETE",
                        headers: {
                            "Content-type": "application/json",
                            Accept: "text/plain",
                        },
                    }));
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = execIds_1.return)) yield _c.call(execIds_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (updateDataset) {
            window.highPriorityMsg({
                type: "fetch-datasets",
                userId: userId,
                url: window.location.origin,
                project: DCBrowserStore.getState().project,
                setCurrentDir: true,
            });
        }
    }); },
    setUserDataset: (x) => set({ userDataset: x }),
    setCATHDataset: (x) => set({ CATHDataset: x }),
    setActiveDataset: (x) => set({ activeDataset: x }),
    getActiveDatasetEntries: () => {
        const activeDataset = get().activeDataset;
        return activeDataset === ActiveDataset.user
            ? get().userDataset
            : get().CATHDataset;
    },
}));
