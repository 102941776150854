var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { PlotClickAction } from "../..";
import { EchartsComp } from "../EchartsComp";
import { plotonLegendEventAction, plotonSeriesEventAction, } from "./plotOnEventAction";
import { handleStstemVizChange } from "./handleStstemVizChange";
export function InteractivePlot({ content, vss, molstar, pyodide, plotActions, localBasePath, NAPGenericStore, height, }) {
    let onClickNode;
    const plotOnClickNode = plotActions ? plotActions.onSeriesClick : undefined;
    if (plotOnClickNode) {
        onClickNode = (params, chartHandler) => __awaiter(this, void 0, void 0, function* () {
            yield plotonSeriesEventAction(plotOnClickNode, params, vss, molstar, pyodide, NAPGenericStore, chartHandler, localBasePath);
            // if (!cellRefs || cellRefs.length === 0) return;
            // const systems = vss.getState().loaded_structures;
            // focusOnSystem(molstar, cellRefs, systems);
        });
    }
    let onMouseOverNode;
    const plotOnHover = plotActions ? plotActions.onSeriesHover : undefined;
    if (plotOnHover) {
        onMouseOverNode = (params, chartHandler) => __awaiter(this, void 0, void 0, function* () {
            yield plotonSeriesEventAction(plotOnHover, params, vss, molstar, pyodide, NAPGenericStore, chartHandler);
        });
    }
    let onClickLegend;
    const plotOnClickLegend = plotActions ? plotActions.onLegendClick : undefined;
    if (plotOnClickLegend) {
        onClickLegend = (params) => __awaiter(this, void 0, void 0, function* () {
            yield plotonLegendEventAction(plotOnClickLegend, params, vss, molstar, pyodide, NAPGenericStore, content, localBasePath);
        });
    }
    // capture system visibility changes
    const toggleVizAction = plotOnClickNode
        ? plotOnClickNode.find((e) => e.action === PlotClickAction.toggleVisibility)
        : undefined;
    let onSystemVizChange;
    if (!!toggleVizAction) {
        onSystemVizChange = (chartHandler, system, visibility) => {
            if (!chartHandler)
                return;
            const dataNames = toggleVizAction.dataName;
            if (!dataNames)
                return;
            handleStstemVizChange(content, system, dataNames, visibility, chartHandler, molstar);
        };
    }
    return (_jsx(EchartsComp, { graphOptions: content, onClickNode: onClickNode, onMouseOverNode: onMouseOverNode, onClickLegend: onClickLegend, onSystemVizChange: onSystemVizChange, NAPGenericStore: NAPGenericStore, height: height }));
}
