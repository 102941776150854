import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItemIcon, ListItem, Tooltip, Box } from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
export const BugReportButton = ({ setBugPanelIsOpen, open, }) => {
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Report a bug", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ onClick: () => setBugPanelIsOpen(true), sx: { cursor: "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: open ? "secondary.main" : "secondary.light" } }, { children: _jsx(BugReportIcon, {}) })) }) })) }), "pm-sidebar-bugreport-tooltip"));
};
