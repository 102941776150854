// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect } from "react";
import { NAPGenericStore } from "../GenericViewerState";
export const repsPanelHeight = 400;
export const repsPanelMinHeight = 300;
export const repsPanelVolumeHeight = 260;
export const repsPanelWidth = 400;
export function positionRepresentationsPanel(targetRef, isVolume) {
    const currentRef = targetRef.current;
    if (currentRef) {
        const rect = currentRef.getBoundingClientRect();
        let x = rect.left + window.scrollX;
        let y = rect.bottom + window.scrollY + 2;
        const distanceToBottom = window.innerHeight - rect.bottom;
        const _repsPanelMinHeight = isVolume
            ? repsPanelVolumeHeight
            : repsPanelMinHeight;
        const _repsPanelHeight = isVolume ? repsPanelVolumeHeight : repsPanelHeight;
        if (distanceToBottom < _repsPanelMinHeight) {
            y = window.scrollY + window.innerHeight - _repsPanelHeight;
            y = y > 0 ? y : 0;
        }
        const distanceToRight = window.innerWidth - rect.left;
        if (distanceToRight < repsPanelWidth) {
            x = window.scrollX + window.innerWidth - repsPanelWidth;
            x = x > 0 ? x : 0;
        }
        NAPGenericStore.getState().setRepresentationsPanelPosition({ x, y });
    }
}
export function useResizeHandler() {
    useEffect(() => {
        const handleResize = () => {
            if (!NAPGenericStore.getState()._openBottomPanel)
                return;
            const currentPosition = NAPGenericStore.getState().representationsPanelPosition;
            const componentAHeight = window.scrollY + window.innerHeight - currentPosition.y;
            let newY;
            if (componentAHeight < repsPanelVolumeHeight) {
                newY = window.scrollY + window.innerHeight - repsPanelVolumeHeight;
                newY = newY > 0 ? newY : 0;
            }
            const componentWidth = window.scrollX + window.innerWidth - currentPosition.x;
            let newX;
            if (componentWidth < repsPanelWidth) {
                newX = window.scrollX + window.innerWidth - repsPanelWidth;
                newX = newX > 0 ? newX : 0;
            }
            if (newY !== undefined || newX !== undefined) {
                const newPositions = {
                    x: newX !== undefined ? newX : currentPosition.x,
                    y: newY !== undefined ? newY : currentPosition.y,
                };
                NAPGenericStore.getState().setRepresentationsPanelPosition(newPositions);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
}
