var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { IsLoadingGroup, } from "..";
import { arraysHaveSameElements } from "../utils/compareArrays";
export function selectSystemByIndexArray(indexArray, allSystems) {
    const parentSystem = allSystems[indexArray[0]];
    const restIndexArray = indexArray.slice(1);
    const currSys = restIndexArray.reduce((prev, curr) => {
        if (prev && prev.children)
            return prev.children[curr];
        else
            throw new Error("Incorrect index array.");
    }, parentSystem);
    // const systemClone = Object.assign({}, currSys);
    // return systemClone;
    return currSys;
}
export function getSystemStateVisibility(vss, positionInTree) {
    const s = vss.getState().loaded_structures;
    const system = selectSystemByIndexArray(positionInTree, s);
    return system.visibility === undefined ? true : system.visibility;
}
export function getVisibleSystemIDsFromStateTree(vss, positionInTree) {
    const s = vss.getState().loaded_structures;
    const system = selectSystemByIndexArray(positionInTree, s);
    return system.visibleElementIDs;
}
function recursiveVisibilityUpdate(system, newVal) {
    var _a;
    system.visibility = newVal;
    if (newVal) {
        (_a = system.representations) === null || _a === void 0 ? void 0 : _a.forEach((rep) => {
            rep.visibility = newVal;
        });
    }
    if (system.children) {
        system.children.forEach((cs) => {
            if (cs.isLoading !== IsLoadingGroup.all)
                recursiveVisibilityUpdate(cs, newVal);
        });
    }
}
function setVisibilityAscendentGroups(systTree, indexArray) {
    const parentSystem = systTree[indexArray[0]];
    const restIndexArray = indexArray.slice(1);
    if (parentSystem.children) {
        parentSystem.visibility = true;
    }
    restIndexArray.reduce((prev, curr) => {
        if (prev.children) {
            prev.visibility = true;
            return prev.children[curr];
        }
        else
            throw new Error("Incorrect index array.");
    }, parentSystem);
}
function setVisibilityAscendentGroupsIfChildrenHidden(systTree, indexArray) {
    // sets visibility to parent groups to false if none of the children is visible
    let currentIndexArray = [...indexArray];
    while (currentIndexArray.length > 1) {
        currentIndexArray = currentIndexArray.slice(0, -1); // Get the IndexArray of the parent
        const system = selectSystemByIndexArray(currentIndexArray, systTree);
        if (system.children) {
            const someVisible = system.children.some((child) => child.visibility !== false);
            if (someVisible)
                break;
            system.visibility = false;
        }
    }
}
export function updateStateTreeVisibility(vss, positionInTree, newVal) {
    /*
    Updates visibility of the element and all descendents (if any).
    When visibility is set to true, visibility of all parent groups is set to true.
    */
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const system = selectSystemByIndexArray(positionInTree, draft);
        recursiveVisibilityUpdate(system, newVal);
        if (newVal) {
            setVisibilityAscendentGroups(draft, positionInTree);
        }
        else {
            setVisibilityAscendentGroupsIfChildrenHidden(draft, positionInTree);
        }
    });
    vss.getState().set_loaded_structures(newSysts);
}
function recursiveUpdateStateTreeParameters(newVals, system) {
    for (let param in newVals) {
        system[param] = newVals[param];
    }
    if (system.children) {
        system.children.forEach((cs) => {
            recursiveUpdateStateTreeParameters(newVals, cs);
        });
    }
}
export function updateStateTreeVisibilityFromCellRefs(vss, cellRefs, newVal) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const system = findSystemByKey(s, "cellRef", cellRefs);
        if (system) {
            recursiveVisibilityUpdate(system, newVal);
            const positionInTree = system.stateTreePosition;
            if (positionInTree) {
                if (newVal) {
                    setVisibilityAscendentGroups(draft, positionInTree);
                }
                else {
                    setVisibilityAscendentGroupsIfChildrenHidden(draft, positionInTree);
                }
            }
        }
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function updateStateTreeParameters(vss, newVals) {
    /*
    Updates parameters of the element and all descendents
    */
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draftSystems) => {
        draftSystems.forEach((system) => recursiveUpdateStateTreeParameters(newVals, system));
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function updateStateTreeParameterFromCellRefs(vss, cellRefs, newVals) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const system = findSystemByKey(s, "cellRef", cellRefs);
        if (system) {
            for (let param in newVals) {
                system[param] = newVals[param];
            }
        }
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function updateStateTreeParameter(vss, newVals, positionInTree) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const system = selectSystemByIndexArray(positionInTree, draft);
        for (let param in newVals) {
            system[param] = newVals[param];
        }
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function updateStateTreeRepresentation(vss, positionInTree, selectedRepID, paramKey, newVal) {
    return __awaiter(this, void 0, void 0, function* () {
        const s = vss.getState().loaded_structures;
        const newSysts = produce(s, (draft) => {
            const system = selectSystemByIndexArray(positionInTree, draft);
            if (!system.representations)
                return;
            const systemReps = system.representations;
            if (selectedRepID >= systemReps.length)
                return;
            const currentRep = systemReps[selectedRepID];
            //@ts-ignore
            currentRep[paramKey] = newVal;
        });
        vss.getState().set_loaded_structures(newSysts);
    });
}
export function updateStateTreeVolume(vss, positionInTree, paramKey, newVal) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const currSys = selectSystemByIndexArray(positionInTree, draft);
        const volParams = currSys.volumeRepresentation;
        volParams[paramKey] = newVal;
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function updateStateTreeTrajectory(vss, positionInTree, paramKey, newVal) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const currSys = selectSystemByIndexArray(positionInTree, draft);
        if (!currSys.trajectoryParameters) {
            currSys.trajectoryParameters = {};
        }
        const trajParams = currSys.trajectoryParameters;
        //@ts-ignore
        trajParams[paramKey] = newVal;
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function setStateTreeVisibleElementIDs(vss, positionInTree, visibleElementIDs) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const currSys = selectSystemByIndexArray(positionInTree, draft);
        currSys.visibleElementIDs = visibleElementIDs;
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function setStateTreeVisibleElementIDsFromCellRefs(vss, cellRefs, visibleElementIDs) {
    const s = vss.getState().loaded_structures;
    const newSysts = produce(s, (draft) => {
        const currSys = findSystemByKey(s, "cellRef", cellRefs);
        if (currSys) {
            currSys.visibleElementIDs = visibleElementIDs;
        }
    });
    vss.getState().set_loaded_structures(newSysts);
}
export function recursiveGetAllChildSystems(system) {
    if (system.children) {
        const childSystems = [];
        system.children.forEach((childSyst) => {
            const thisChildSystems = recursiveGetAllChildSystems(childSyst);
            childSystems.push(...thisChildSystems);
        });
        return childSystems;
    }
    else {
        return [system];
    }
}
export function getAllChildSystems(vss, positionInTree) {
    const allSystems = vss.getState().loaded_structures;
    const systemGroup = selectSystemByIndexArray(positionInTree, allSystems);
    const childSystems = recursiveGetAllChildSystems(systemGroup);
    return childSystems;
}
function compareSystemValue(valueA, valueB, includes) {
    if (includes) {
        let isIncluded = false;
        if (Array.isArray(valueA)) {
            if (Array.isArray(valueB)) {
                isIncluded = valueB.every((val) => valueA.includes(val));
            }
        }
        else {
            isIncluded = valueB.includes(valueA);
        }
        return isIncluded;
    }
    else {
        let isEqual = false;
        if (Array.isArray(valueA)) {
            if (Array.isArray(valueB)) {
                isEqual = arraysHaveSameElements(valueB, valueA);
            }
        }
        else {
            isEqual = valueB === valueA;
        }
        return isEqual;
    }
}
function searchSystemsRecursively(system, key, value, includes) {
    if (system.files) {
        const isEqual = compareSystemValue(value, system[key], includes);
        if (isEqual) {
            return system;
        }
    }
    else if (system.children) {
        let result;
        for (let i = 0; i < system.children.length && result == null; i++) {
            result = searchSystemsRecursively(system.children[i], key, value, includes);
        }
        return result;
    }
}
export function findSystemByKey(systems, key, value, includes) {
    let match;
    systems.forEach((system) => {
        const s = searchSystemsRecursively(system, key, value, includes);
        if (s)
            match = s;
    });
    return match;
}
function recursiveFfindSystemsByKey(system, key, acceptedValues) {
    if (system.children) {
        const childSystems = [];
        system.children.forEach((childSyst) => {
            const thisChildSystems = recursiveFfindSystemsByKey(childSyst, key, acceptedValues);
            childSystems.push(...thisChildSystems);
        });
        return childSystems;
    }
    else {
        const someIsEqual = acceptedValues.some((value) => compareSystemValue(value, system[key]));
        return someIsEqual ? [system] : [];
    }
}
export function findSystemsByKey(systems, key, acceptedValues) {
    let matches = [];
    systems.forEach((system) => {
        const m = recursiveFfindSystemsByKey(system, key, acceptedValues);
        matches = matches.concat(m);
    });
    return matches;
}
export function checkForExtensionsInTree(nodes, extensions) {
    var _a;
    let found = false;
    for (let n = 0; n < nodes.length; n++) {
        const node = nodes[n];
        if (node.children) {
            found = checkForExtensionsInTree(node.children, extensions);
            return found;
        }
        else {
            if (!node.files)
                return false;
            found = (_a = node.files) === null || _a === void 0 ? void 0 : _a.some((f) => {
                const ext = f.split(".").pop();
                return ext && extensions.includes(ext);
            });
            if (found)
                return found;
        }
    }
    return false;
}
