// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { supportedFiles } from "..";
import { createNewSystemFromDC } from "../FileLoaders/renderSystem";
import { dispatchFileSelectionEvent } from "./FileBrowserDialog";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
import { loadTables } from "../Tables/loadTables";
import { ActiveDataset } from "./datacenterBrowser.store";
export function fileHandler(vss, molstar, pyodide, fileEntry, NAPGenericStore, setOpenFileContentPanel, setContentPanelFileId, findFileInParent, dataset) {
    return __awaiter(this, void 0, void 0, function* () {
        const _dataset = dataset ? dataset : ActiveDataset.user;
        const loadingDCFile = NAPGenericStore.getState().loadingDCFile;
        const setLoadingDCFile = NAPGenericStore.getState().setLoadingDCFile;
        if (loadingDCFile) {
            dispatchNotificationEvent({
                type: "warning",
                message: "This file cannot be loaded yet, please wait.",
            });
            return;
        }
        setLoadingDCFile(true);
        /* ================================== */
        const fileExt = fileEntry.name.split(".").pop();
        const supportedTopologies = supportedFiles.topology;
        const supportedTrajectories = supportedFiles.coordinates_trajectory;
        const supportedVolumes = supportedFiles.volume;
        const supportedStaticCoords = supportedFiles.coordinates_static;
        const supportedTables = supportedFiles.tables;
        /* ================================== */
        /* Handle the bad case first so that you do not have to check it
        everywhere below. */
        if (!fileExt)
            throw new Error("No file extension extracted!");
        const getFileType = (ext) => {
            if (supportedTopologies.includes(ext))
                return "topology";
            if (supportedTrajectories.includes(ext))
                return "trajectory";
            if (supportedVolumes.includes(ext))
                return "volume";
            if (supportedStaticCoords.includes(ext))
                return "coordinates";
            if (supportedTables.includes(ext))
                return "table";
        };
        switch (getFileType(fileExt)) {
            case "topology":
                const topData = fileEntry;
                const trajIData = yield dispatchFileSelectionEvent(supportedTrajectories, "Select a coordinate file:", _dataset);
                if (!topData || !trajIData) {
                    break;
                }
                yield createNewSystemFromDC(vss, molstar, pyodide, topData.id, trajIData.id);
                break;
            case "trajectory":
                const trajData = fileEntry;
                let topId;
                const parentId = trajData.parentId;
                if (findFileInParent && parentId) {
                    topId = findFileInParent(parentId);
                }
                if (topId === undefined) {
                    const topIData = yield dispatchFileSelectionEvent([...supportedTopologies, ...supportedFiles.coordinates_static], "Select a topology file:", _dataset);
                    topId = topIData === null || topIData === void 0 ? void 0 : topIData.id;
                }
                if (!topId)
                    break;
                yield createNewSystemFromDC(vss, molstar, pyodide, topId, trajData.id);
                break;
            case "volume":
                yield createNewSystemFromDC(vss, molstar, pyodide, fileEntry.id);
                break;
            case "coordinates":
                yield createNewSystemFromDC(vss, molstar, pyodide, fileEntry.id);
                break;
            case "table":
                const tableData = [{ path: fileEntry.id }];
                loadTables(pyodide, tableData, NAPGenericStore);
                break;
            default:
                setContentPanelFileId(fileEntry.id);
                setOpenFileContentPanel(true);
                break;
        }
        setLoadingDCFile(false);
    });
}
export function getSdfDetails(sdfCompounds) {
    /*
      Positive lookbehind:
      (?<=Y)X, matches X, but only if there’s Y before it. Why?
      Because each sdf entry ends with EXACTLY 4 "$" characters.
      Splitting the file means keeping the delimiter of each entry.
      */
    const splitMolecules = sdfCompounds.split(/(?<=\${4})/);
    const molecularPropertiesArr = [];
    splitMolecules.forEach((molecule) => {
        const moleculeProps = [];
        molecule.split(/> {2}</).forEach((prop) => {
            const keyValPair = prop.split(">");
            moleculeProps.push(keyValPair);
        });
        molecularPropertiesArr.push(moleculeProps);
    });
    molecularPropertiesArr.pop();
    /* last entry in the array is garbage, MUSTFIX */
    return molecularPropertiesArr;
}
/* Each compound in a sdf file can have a list of properties
  in the following format:
>  <(property name)>
(property value)
*/
