// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//

// 1. STATELESS IMPORTS FROM LIBRARIES
import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { shallow } from "zustand/shallow";
import { useCookies } from "react-cookie";
import { Box, Chip, Tooltip, ListItem, ListItemIcon } from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import BarChartIcon from "@mui/icons-material/BarChart";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";

import {
  Sidebar,
  TopSection,
  BottomSection,
  CATHPanel,
  TextInputDialog,
  RepresentationsPanelEntryPoint,
} from "molkitstar-ui";

// 2. MOLKITSTAR-UI IMPORTS
import {
  SaveButton,
  HelpButtons,
  FileUploadButton,
  DocumentationButton,
  CollapsibleDrawerRightSide,
  BugReport,
  useInterval,
  resetAllState,
  useKeyPress,
  fileUploadingHandler,
  NAPMSAStore,
  NAPTableStore,
  NAPPlotStore,
  PMLogo,
  NAPGenericStore,
  Panels,
  NotificationListener,
  ControlledNotificationListener,
  MSAPanel,
  SequencesPanel,
  DocsPanel,
  LandingPagePanel,
  AppsPanel,
  PlotsPanel,
  ViewerPanel,
  ConsolePanel,
  StateTreePanel,
  MolkitLoaderStatus,
  FileDetailsDialog,
  FileContentPanel,
  FileBrowserDialog,
  ConfirmationListener,
  setupPyodideContext,
  FEPGraphStore,
  AuthenticationButtons,
  DCBrowserStore,
  DataPortal,
  DatavizControlOptions,
  UploadLigandDialog,
  userAuthStore,
  CompleteUserInfoDialog,
  executeWhenPyodideReady,
} from "molkitstar-ui";

export default function Main({ vss }) {
  return (
    <>
      <LandingPagePanel />
      <PMV vss={vss} />
    </>
  );
}

function PMV({ vss }) {
  const { tables } = NAPTableStore();
  const { plots } = NAPPlotStore((state) => ({
    plots: state.plots,
  }));
  const { showGraph } = FEPGraphStore();
  // const { msaArr } = NAPMSAStore();
  const [currLoc, setLocation] = useLocation();
  const [cookies] = useCookies(["PM_PROJECT"]);
  const cookiesProject = cookies.PM_PROJECT;
  let project = DCBrowserStore.getState().project; // no need to re-render when project changed
  if (cookiesProject) {
    project = cookiesProject;
    DCBrowserStore.getState().setProject(project);
  }
  const simpleUI = currLoc.startsWith("/data");

  const googleAuthRedirect = currLoc === "/auth/google/callback";

  const [
    activePanels,
    setActivePanels,
    openRightPanel,
    setSideBarWidth,
    removeVisibleSystemsFiles,
  ] = NAPGenericStore(
    (state) => [
      state._activePanels,
      state.setActivePanels,
      state._openRightPanel,
      state.setSideBarWidth,
      state.removeVisibleSystemsFiles,
    ],
    shallow
  );
  const [loaded_structures] = vss(
    (state) => [state.loaded_structures],
    shallow
  );
  useEffect(() => {
    if (simpleUI) setSideBarWidth("0px");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleUI]);

  /* A little wrapper to pass to setupPyodideContext() for ease */
  const closeSystemPanels = () =>
    setActivePanels({
      ...activePanels,
      [Panels.statetree]: false,
      [Panels.sequence]: false,
    });

  const [pyodideReady, setPyodideReady] = useState(false);
  const [pyodideInterval, setPyodideInterval] = useState(1000);

  // check if the user is authenticated
  const [waitUserInfo, addMissingDataAndSetUserInfo, startWorkerCalls, logout] =
    userAuthStore(
      (state) => [
        state.waitUserInfo,
        state.addMissingDataAndSetUserInfo,
        state.startWorkerCalls,
        state.logout,
      ],
      shallow
    );

  if (waitUserInfo && googleAuthRedirect === false) {
    addMissingDataAndSetUserInfo().then((userInfo) => {
      if (userInfo) {
        if (!simpleUI)
          executeWhenPyodideReady(() =>
            startWorkerCalls(userInfo.userId, project)
          );
      } else {
        logout(false);
      }
    });
  }
  useInterval(() => {
    if (window.pyodideWorkerReady) {
      setPyodideInterval(null);

      setupPyodideContext(
        vss,
        window.molstar,
        window.pyodideWorker,
        closeSystemPanels,
        removeVisibleSystemsFiles
      ).then(() => setPyodideReady(true));
    }
  }, pyodideInterval);

  const toggleSystemsTree = () => {
    if (disableSystemsPanel) return;
    setLocation("/");
    setActivePanels({
      ...activePanels,
      [Panels.statetree]: !activePanels[Panels.statetree],
    });
  };
  const toggleSequence = () => {
    if (disableSystemsPanel) return;
    setLocation("/");
    setActivePanels({
      ...activePanels,
      [Panels.sequence]: !activePanels[Panels.sequence],
    });
  };
  const disableDataVizPanel =
    tables.length === 0 && !showGraph && plots.length === 0;

  const disableSystemsPanel = loaded_structures.length === 0;

  const MolkitLoadingStatus = () => <MolkitLoaderStatus open={!pyodideReady} />;

  useKeyPress(["c"], toggleSystemsTree, false, false, true);
  useKeyPress(["s"], toggleSequence, false, false, true);
  const toggleDataVizPanel = () => {
    if (disableDataVizPanel) return;
    setActivePanels({
      ...activePanels,
      [Panels.plots]: !activePanels[Panels.plots],
    });
  };
  useKeyPress(["p"], toggleDataVizPanel, false, false, true);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <RepresentationsPanelEntryPoint
        NAPGenericStore={NAPGenericStore}
        vss={vss}
        molstar={window.molstar}
        pyodideWorker={window.pyodideWorker}
      />

      {/* ============================================================ */}

      {!simpleUI && (
        <>
          <MolkitLoadingStatus />
          <SideBarMenu
            NAPGenericStore={NAPGenericStore}
            vss={vss}
            activePanels={activePanels}
            disableSystemsPanel={disableSystemsPanel}
            toggleSystemsTree={toggleSystemsTree}
            toggleSequence={toggleSequence}
            disableDataVizPanel={disableDataVizPanel}
            setActivePanels={setActivePanels}
            pyodideReady={pyodideReady}
            setLocation={setLocation}
          />
        </>
      )}

      {/* =============== PANEL COMPONENTS GO HERE =============== */}
      {simpleUI ? (
        <DataPortal
          pyodide={window.pyodideWorker}
          pyodideReady={pyodideReady}
          NAPGenericStore={NAPGenericStore}
          vss={vss}
          molstar={window.molstar}
        />
      ) : (
        <>
          <StateTreePanel
            vss={vss}
            molstar={window.molstar}
            pyodide={window.pyodideWorker}
            show={activePanels.statetree}
            NAPGenericStore={NAPGenericStore}
          />
          <DocsPanel pyodide={window.pyodideWorker} />
          <AppsPanel
            vss={vss}
            molstar={window.molstar}
            pyodide={window.pyodideWorker}
            pyodideReady={pyodideReady}
            NAPGenericStore={NAPGenericStore}
          />
          <CATHPanel
            vss={vss}
            molstar={window.molstar}
            pyodide={window.pyodideWorker}
            NAPGenericStore={NAPGenericStore}
            pyodideReady={pyodideReady}
          />
        </>
      )}

      <MSAPanel NAPGenericStore={NAPGenericStore} />
      <SequencesPanel
        NAPGenericStore={NAPGenericStore}
        vss={vss}
        molstar={window.molstar}
      />
      <PlotsPanel
        NAPGenericStore={NAPGenericStore}
        vss={vss}
        molstar={window.molstar}
        pyodide={window.pyodideWorker}
        skipButtons={simpleUI ? [DatavizControlOptions.delete] : undefined}
        fileSelectorAsTabs={simpleUI}
      />
      <ViewerPanel
        NAPGenericStore={NAPGenericStore}
        version={simpleUI ? "full" : undefined}
      />
      {!simpleUI && <ConsolePanel NAPGenericStore={NAPGenericStore} />}
      {/* ======================================================= */}

      <CollapsibleDrawerRightSide
        anchor="right"
        variant="permanent"
        open={openRightPanel}
      ></CollapsibleDrawerRightSide>

      <ConfirmationListener />
      <NotificationListener />
      <ControlledNotificationListener />

      <FileDetailsDialog NAPGenericStore={NAPGenericStore} />
      <FileContentPanel
        NAPGenericStore={NAPGenericStore}
        pyodide={window.pyodideWorker}
      />
      <TextInputDialog />
      <FileBrowserDialog
        vss={vss}
        molstar={window.molstar}
        pyodideReady={pyodideReady}
        NAPGenericStore={NAPGenericStore}
        pyodide={window.pyodideWorker}
        pyodideWorker={window.pyodideWorker}
      />
      <UploadLigandDialog pyodide={window.pyodideWorker} />
      <CompleteUserInfoDialog />
      {process.env.NODE_ENV !== "production" ? (
        <>
          <Chip
            clickable
            label="(DEV) Reset State"
            color="secondary"
            variant="outlined"
            sx={{ position: "fixed", right: 80, top: 10 }}
            onClick={() =>
              resetAllState(window.molstar, vss, NAPTableStore, NAPMSAStore)
            }
          />
        </>
      ) : null}
    </Box>
  );
}

function SideBarMenu({
  NAPGenericStore,
  vss,
  activePanels,
  disableSystemsPanel,
  toggleSystemsTree,
  toggleSequence,
  disableDataVizPanel,
  setActivePanels,
  pyodideReady,
  setLocation,
}) {
  const iconMappingSections = [
    {
      section: Panels.statetree,
      icon: <ImagesearchRollerIcon />,
      tooltipText: "3D Viewer Controls",
      isActive: activePanels.statetree,
      color: activePanels.statetree ? "secondary.main" : "secondary.light",
      disabled: disableSystemsPanel,
      hide: disableSystemsPanel,
      onClick: toggleSystemsTree,
    },
    {
      section: Panels.sequence,
      icon: <SegmentIcon />,
      tooltipText: "Sequence",
      isActive: activePanels.sequence,
      color: activePanels.sequence ? "secondary.main" : "secondary.light",
      disabled: disableSystemsPanel,
      hide: disableSystemsPanel,
      onClick: toggleSequence,
    },
    {
      section: Panels.plots,
      icon: <BarChartIcon />,
      tooltipText: "Data visualization",
      isActive: activePanels.plots,
      color: activePanels.plots ? "secondary.main" : "secondary.light",
      disabled: disableDataVizPanel,
      hide: disableDataVizPanel,
    },
    // {
    //   section: Panels.msa,
    //   icon: <SegmentIcon />,
    //   tooltipText: "Multiple sequence alignment",
    //   isActive: activePanels.msa,
    //   color: activePanels.msa ? "secondary.main" : "secondary.light",
    //   disabled: msaArr.length === 0,
    //   hide: msaArr.length === 0,
    // }
  ];
  function openSystemsPanel(loadInstructions) {
    if (loadInstructions.systems?.length > 0) {
      const activePanels = NAPGenericStore.getState()._activePanels;
      NAPGenericStore.getState().setActivePanels({
        ...activePanels,
        statetree: true,
        apps: false,
      });
      setLocation("/");
    }
  }
  const cb = (e) =>
    fileUploadingHandler(
      e,
      vss,
      window.molstar,
      window.pyodideWorker,
      NAPGenericStore,
      openSystemsPanel
    );

  const FileUpload = () => (
    <FileUploadButton disabled={pyodideReady} callback={cb} />
  );
  const BasicMenuButtons = () => (
    <>
      {iconMappingSections.map((entry /*i, arr*/) => {
        const { section, icon, tooltipText, color, disabled, onClick, hide } =
          entry;
        if (hide) return <div key={`pm-sidebar-section-${section}`}></div>;
        return (
          <Tooltip
            key={`pm-sidebar-section-${section}`}
            enterDelay={200}
            title={tooltipText}
            arrow
            placement="right"
          >
            <ListItem
              button
              disabled={disabled}
              onClick={
                onClick
                  ? onClick
                  : () =>
                      setActivePanels({
                        ...activePanels,
                        [section]: !activePanels[section],
                      })
              }
            >
              <ListItemIcon>
                <Box sx={{ color }}>{icon}</Box>
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        );
      })}
    </>
  );
  return (
    <>
      <Sidebar>
        <TopSection>
          <PMLogo NAPGenericStore={NAPGenericStore} />
          <FileUpload />
          <BasicMenuButtons />
          <SaveButton vss={vss} />
        </TopSection>

        <BottomSection>
          {/* <SlackLogo /> */}
          <AuthenticationButtons vss={vss} molstar={window.molstar} />
          <BugReport />
          <DocumentationButton NAPGenericStore={NAPGenericStore} />
          <HelpButtons />
        </BottomSection>
      </Sidebar>
    </>
  );
}
