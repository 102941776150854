import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Fade, Box } from "@mui/material";
import { MSA } from "../../MSA/MSA";
import { topPanel, leftPanel } from "../panelDimensions";
export const MSAPanel = ({ NAPGenericStore, }) => {
    const [activePanels] = NAPGenericStore((state) => [state._activePanels], shallow);
    const show = activePanels.msa;
    const leftPanelOpen = activePanels.statetree || activePanels.apps;
    const dimensions = Object.assign(Object.assign({}, topPanel), { left: (theme) => leftPanelOpen
            ? `calc(${leftPanel.width} + ${theme.spacing(7)})`
            : `calc(${theme.spacing(7)})`, width: (theme) => leftPanelOpen
            ? `calc(100vw - (${leftPanel.width} + ${theme.spacing(7)}))`
            : `calc(100vw - ${theme.spacing(7)} )` });
    return (_jsx(Fade, Object.assign({ in: show }, { children: _jsx(Box, Object.assign({ id: "msa-entrypoint", sx: dimensions }, { children: _jsx(MSA, { NAPGenericStore: NAPGenericStore }) })) })));
};
