var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Avatar, ListItem, ListItemIcon, Tooltip, ListItemText, MenuItem, IconButton, } from "@mui/material";
import { dispatchConfirmationDialogEvent } from "../..";
import { useState } from "react";
import { SidebarPopupMenu } from "../../Sidebar/SidebarPopupMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UserSettingsDialog } from "./UserSettingsDialog";
function extractInitial(name) {
    const firstLetterMatch = name.match(/\p{L}/u);
    // If a letter is found, return it in uppercase. If not, find the first non-whitespace character.
    if (firstLetterMatch) {
        return firstLetterMatch[0].toUpperCase();
    }
    else {
        const firstNonWhitespace = name.match(/\S/);
        return firstNonWhitespace ? firstNonWhitespace[0] : "";
    }
}
function UserOptionsButton({ handleClick, userName, }) {
    const initials = userName ? extractInitial(userName) : "";
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Account", arrow: true, placement: "bottom" }, { children: _jsx(IconButton, Object.assign({ onClick: handleClick }, { children: _jsx(Avatar, Object.assign({ sx: {
                    bgcolor: "secondary.main",
                    width: "30px",
                    height: "30px",
                    fontSize: "18px",
                } }, { children: initials })) })) })));
}
function UserOptionsListItem({ handleClick, userName, }) {
    const initials = userName ? extractInitial(userName) : "";
    return (_jsx(_Fragment, { children: _jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Account", arrow: true, placement: "right" }, { children: _jsx(ListItem, Object.assign({ onClick: handleClick, sx: { cursor: "pointer" } }, { children: _jsx(ListItemIcon, { children: _jsx(Avatar, Object.assign({ sx: {
                            bgcolor: "secondary.main",
                            width: "1.3em",
                            height: "1.3em",
                            fontSize: "18px",
                        } }, { children: initials })) }) })) })) }));
}
function UserOptionsMenu({ handleClose, anchorEl, menuIsOpen, handleLogoutClick, handleOpenSettings, vertical, }) {
    const confirmLogOut = () => __awaiter(this, void 0, void 0, function* () {
        const ok = yield dispatchConfirmationDialogEvent({
            message: "Are you sure you want to log out?",
        });
        if (ok)
            handleLogoutClick();
    });
    return (_jsx(SidebarPopupMenu, Object.assign({ handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen, vertical: vertical }, { children: _jsxs("div", { children: [_jsxs(MenuItem, Object.assign({ onClick: confirmLogOut, dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx(LogoutIcon, {}) }), _jsx(ListItemText, { children: "Log out" })] }), "account-menu-logout"), _jsxs(MenuItem, Object.assign({ onClick: handleOpenSettings, dense: true }, { children: [_jsx(ListItemIcon, { children: _jsx(AccountCircleIcon, {}) }), _jsx(ListItemText, { children: "Account Settings" })] }), "account-menu-settings")] }) })));
}
export function UserOptions({ userInfo, handleLogoutClick, landingPage, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuIsOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [settingsIsOpen, setSettingsIsOpen] = useState(false);
    const handleOpenSettings = () => {
        setSettingsIsOpen(true);
    };
    const handleCloseSettings = () => {
        setSettingsIsOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UserOptionsMenu, { handleClose: handleClose, anchorEl: anchorEl, menuIsOpen: menuIsOpen, handleLogoutClick: handleLogoutClick, handleOpenSettings: handleOpenSettings, vertical: landingPage }), landingPage ? (_jsx(UserOptionsButton, { handleClick: handleClick, userName: userInfo.username })) : (_jsx(UserOptionsListItem, { handleClick: handleClick, userName: userInfo.username })), _jsx(UserSettingsDialog, { open: settingsIsOpen, handleClose: handleCloseSettings, userInfo: userInfo })] }));
}
