// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { CookiesProvider } from "react-cookie";
import { ViewerStateStore } from "molkitstar-ui";
import { priorityWorkerHandler } from "./highPriorityWorker.handler";

import "./index.css";
import NAP from "./NAP.jsx";

import p from "../package.json";
window.NAP_version = p.version;

window.highPriorityWorker = new Worker(
  new URL("./highPriorityWorker.ts", import.meta.url),
  { type: "module" }
);

window.highPriorityMsg = (x) => {
  window.highPriorityWorker.postMessage(x);
};
/* 
  The event listener is added regardless of login status and will only
  fire on messages from the worker. So, no harm (I think).
*/
window.highPriorityWorker.addEventListener("message", (msg) => {
  priorityWorkerHandler(msg);
});

export const theme = createTheme({
  /*components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#e6e6e6",
        },
      },
    },
  }, JUST IN CASE IT IS NEEDED */
  palette: {
    primary: {
      main: "#0b5394",
      light: "#a0cff8",
    },
    secondary: {
      main: "#ffab40",
      light: "#e6e6e6",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

function ThemedApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookiesProvider>
        <NAP vss={ViewerStateStore} />
      </CookiesProvider>
    </ThemeProvider>
  );
}

root.render(<ThemedApp />);
