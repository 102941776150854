var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { dispatchUploadLigandEvent } from "./UploadLigandDialog";
export function LigandSelector({ pyodide, hookFormRegisterFunc, setValue, formKey, label, error, disabled = false, }) {
    const formValue = useWatch({
        name: formKey,
        defaultValue: "",
    });
    // const [fileLabel, setFileLabel] = useState(formValue);
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        const ligand = yield dispatchUploadLigandEvent();
        if (!ligand)
            return;
        setValue(formKey, ligand);
    });
    const clearSelection = () => {
        setValue(formKey, "");
    };
    const _a = hookFormRegisterFunc(formKey, {
        disabled: disabled,
    }), { ref } = _a, inputProps = __rest(_a, ["ref"]);
    const hasSelection = !!formValue;
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column" }, { children: [_jsxs(Grid, Object.assign({ item: true, container: true, direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 1, xs: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { display: "none" } }, { children: _jsx(TextField, Object.assign({ inputRef: ref }, inputProps, { disabled: disabled, label: label, error: !!error, helperText: error, inputProps: { readOnly: true }, size: "small", sx: { width: "100%" } })) })), _jsx(Grid, Object.assign({ item: true, zeroMinWidth: true }, { children: _jsx(FormLabel, { children: label }) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: hasSelection ? (_jsx(Button, Object.assign({ onClick: clearSelection, variant: "outlined", color: "error", sx: { width: "120px" }, endIcon: _jsx(ClearIcon, {}) }, { children: "Clear" }))) : (_jsx(Button, Object.assign({ onClick: handleClick, variant: "outlined", sx: { width: "180px", p: "5px" }, endIcon: _jsx(AddCircleIcon, {}) }, { children: "Add molecule(s)" }))) }))] })), error ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: { color: "#d32f2f" } }, { children: error })) }))) : null] })));
}
