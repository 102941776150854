var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { create } from "zustand";
import { produce } from "immer";
const _defaults = {
    systParamsAfterUpdate: {},
    loaded_structures: [],
    activeTrajectory: undefined,
};
export function selectSystemByIndexArray(loaded_structures, indexArray) {
    const parentSystem = loaded_structures[indexArray[0]];
    const restIndexArray = indexArray.slice(1);
    const currSys = restIndexArray.reduce((prev, curr) => {
        if (prev && prev.children)
            return prev.children[curr];
        else
            throw new Error("Incorrect index array.");
    }, parentSystem);
    // const systemClone = Object.assign({}, currSys);
    // return systemClone;
    return currSys;
}
const _remove_system_by_pos = (loaded_structures, positionInTree) => {
    if (positionInTree.length === 1) {
        loaded_structures.splice(positionInTree[0], 1);
    }
    else {
        const parentPositionInTree = positionInTree.slice(0, -1);
        const parentSyst = selectSystemByIndexArray(loaded_structures, parentPositionInTree);
        if (parentSyst.children) {
            if (parentSyst.children.length > 1) {
                const systIdx = positionInTree.slice(-1)[0];
                parentSyst.children.splice(systIdx, 1);
            }
            else {
                _remove_system_by_pos(loaded_structures, parentPositionInTree);
            }
        }
    }
};
const _remove_system_by_cellref = (systems, cellRef) => {
    var _a;
    let indexes = [];
    // Find all systems with the given cellRef
    for (let i = 0; i < systems.length; i++) {
        if ((_a = systems[i].cellRef) === null || _a === void 0 ? void 0 : _a.includes(cellRef)) {
            indexes.push(i);
        }
    }
    // Remove them from the list
    for (var i = indexes.length - 1; i >= 0; i--) {
        systems.splice(indexes[i], 1);
    }
    // Now iterate over the children of each remaining system
    for (const system of systems) {
        if (system.children) {
            system.children = _remove_system_by_cellref(system.children, cellRef);
        }
    }
    return systems;
};
// Cleans up the representations reference list
const _remove_rep_refs = (repr, cellRef, repRef) => {
    if (repr.refs === undefined)
        return true;
    if (cellRef) {
        repr.refs = repr.refs.filter((r) => !cellRef.includes(r.cellRef));
    }
    else if (repRef) {
        repr.refs = repr.refs.filter((r) => r.repRef !== repRef);
    }
    return repr.refs.length > 0;
};
// Removes all the representations in the loaded_structures which belong to the cellRef
const _remove_all_representations = (loaded_structures, cellRef) => {
    for (const system of loaded_structures) {
        if (system.representations === undefined)
            continue;
        system.representations = system.representations.filter((rep) => {
            return _remove_rep_refs(rep, cellRef, undefined); // removes and returns true if there are refs left, otherwise delete it
        });
        if (system.children) {
            _remove_all_representations(system.children, cellRef);
        }
    }
};
const _remove_representation = (loaded_structures, repIdx, positionInTree) => {
    if (!positionInTree)
        return;
    const system = selectSystemByIndexArray(loaded_structures, positionInTree);
    if (system.representations === undefined)
        return;
    system.representations.splice(repIdx, 1);
};
// export function deleteStateTreeRepresentation(
//   vss: VSS,
//   positionInTree: number[],
//   selectedRepID: number
// ) {
//   const s = vss.getState().loaded_structures;
//   const newSysts = produce(s, (draft) => {
//     const system = selectSystemByIndexArray(positionInTree, draft);
//     if (!system.representations) return;
//     const systemReps = system.representations as RepresentationParams[];
//     if (selectedRepID >= systemReps.length) return;
//     systemReps.splice(selectedRepID, 1);
//   });
//   vss.getState().set_loaded_structures(newSysts);
// }
const _replace_representation = (loaded_structures, cellRef, oldRepRef, newRep) => {
    const system = get_system_by_cellref(loaded_structures, cellRef);
    if (!system || !system.representations)
        return;
    const repIdx = system.representations.findIndex((r) => { var _a; return (_a = r.refs) === null || _a === void 0 ? void 0 : _a.some((x) => x.repRef === oldRepRef); } // if the repRef appears in any of the refs of the representation
    );
    system.representations.splice(repIdx, 1, newRep);
};
export const get_system_by_cellref = (loaded_structures, cellRef) => {
    var _a;
    for (const system of loaded_structures) {
        if ((_a = system.cellRef) === null || _a === void 0 ? void 0 : _a.includes(cellRef)) {
            return system;
        }
        if (system.children) {
            const child = get_system_by_cellref(system.children, cellRef);
            if (child)
                return child;
        }
    }
};
export const get_representation_ref_by_name = (system, name) => {
    if (system.representations === undefined)
        return;
    for (const rep of system.representations) {
        if (rep.name === name) {
            const repRef = rep.refs && rep.refs.length > 0 ? rep.refs[0].repRef : undefined;
            return repRef;
        }
    }
};
const _add_representation = (loaded_structures, positionInTree, rep, forceRepPosition) => {
    const system = selectSystemByIndexArray(loaded_structures, positionInTree);
    if (!system.representations) {
        system["representations"] = [];
    }
    const representations = system.representations;
    if (forceRepPosition) {
        /*
        1. this one used to delete one rep and put another
        2. now it should swap them but inside an immer-produce function and set the rep object
        */
        representations[forceRepPosition] = rep;
    }
    else {
        representations.push(rep);
    }
};
const _updateSystemParameters = (loaded_structures, positionInTree, newSystParams) => {
    const system = selectSystemByIndexArray(loaded_structures, positionInTree);
    for (const param in newSystParams) {
        system[param] = newSystParams[param];
    }
};
const _add_loaded_structure = (loaded_structures, system) => {
    loaded_structures.push(system);
};
export const ViewerStateStore = create()((set, get) => (Object.assign(Object.assign({}, _defaults), { reset: () => set(_defaults), setActiveTrajectory: (x) => set({ activeTrajectory: x }), set_loaded_structures: (x) => set({ loaded_structures: x }), add_loaded_structure: (system) => {
        set(produce((state) => {
            _add_loaded_structure(state.loaded_structures, system);
        }));
    }, remove_system_by_pos: (positionInTree) => {
        set(produce((state) => {
            _remove_system_by_pos(state.loaded_structures, positionInTree);
        }));
    }, remove_system_by_cellref: (cellRef) => {
        set(produce((state) => {
            _remove_system_by_cellref(state.loaded_structures, cellRef);
        }));
    }, remove_representation: (positionInTree, repIdx) => {
        set(produce((state) => {
            _remove_representation(state.loaded_structures, repIdx, positionInTree);
        }));
    }, remove_all_representations: (cellRef) => set(produce((state) => {
        _remove_all_representations(state.loaded_structures, cellRef);
    })), replace_representation: (cellRef, oldRepRef, newRep) => {
        set(produce((state) => {
            _replace_representation(state.loaded_structures, cellRef, oldRepRef, newRep);
        }));
    }, add_representation: (positionInTree, rep, forceRepPosition) => {
        set(produce((state) => {
            _add_representation(state.loaded_structures, positionInTree, rep, forceRepPosition);
        }));
    }, addSystemParamsAfterUpdate: (moleculeId, values) => set({
        systParamsAfterUpdate: Object.assign(Object.assign({}, get().systParamsAfterUpdate), { [moleculeId]: values }),
    }), removeSystemParamsAfterUpdate: (moleculeId) => {
        const _a = get().systParamsAfterUpdate, _b = moleculeId, _ = _a[_b], newDict = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        set({ systParamsAfterUpdate: newDict });
    }, updateSystemParameters: (positionInTree, newSystParams) => {
        set(produce((state) => {
            _updateSystemParameters(state.loaded_structures, positionInTree, newSystParams);
        }));
    } })));
