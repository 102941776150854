import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Grid, Tooltip, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { executeWithTokenRefresh } from "../../Authentication";
export function CancelJob({ jobID }) {
    const cancelJob = () => {
        const headers = {
            "Content-type": "application/json",
            Accept: "text/plain",
        };
        executeWithTokenRefresh(fetch(`${window.location.origin}/v2/jobs/${jobID}`, {
            method: "DELETE",
            headers: headers,
        }));
    };
    return (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", title: "Cancel job", sx: {} }, { children: _jsx(IconButton, Object.assign({ onClick: cancelJob }, { children: _jsx(HighlightOffIcon, { fontSize: "small", color: "action" }) })) })) })));
}
