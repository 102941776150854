import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { BugReportPanel } from "./BugReportPanel";
import { BugReportButton } from "./BugReportButton";
export function BugReport() {
    const [bugPanelIsOpen, setBugPanelIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(BugReportButton, { open: bugPanelIsOpen, setBugPanelIsOpen: setBugPanelIsOpen }), _jsx(BugReportPanel, { open: bugPanelIsOpen, setOpen: setBugPanelIsOpen })] }));
}
