import { OrderedSet } from "molstar/lib/mol-data/int";
import { StructureElement } from "molstar/lib/mol-model/structure";
import { lociFromSelection } from "./utils";
function createLociRange(startLoci, endLoci) {
    if (endLoci && !StructureElement.Loci.areEqual(startLoci, endLoci)) {
        const ref = startLoci.elements[0];
        const ext = endLoci.elements[0];
        const min = Math.min(OrderedSet.min(ref.indices), OrderedSet.min(ext.indices));
        const max = Math.max(OrderedSet.max(ref.indices), OrderedSet.max(ext.indices));
        const range = StructureElement.Loci(endLoci.structure, [
            {
                unit: ref.unit,
                indices: OrderedSet.ofRange(min, max),
            },
        ]);
        return range;
    }
}
function selectRangeWithinChain(startResId, endResId, chain, structureData, skipFirstPos) {
    let loci;
    if (startResId === endResId && !skipFirstPos) {
        loci = lociFromSelection(structureData, startResId, chain);
    }
    else {
        const startLoci = lociFromSelection(structureData, startResId, chain);
        const endLoci = lociFromSelection(structureData, endResId, chain);
        const lociRange = createLociRange(startLoci, endLoci);
        if (lociRange) {
            loci = skipFirstPos
                ? StructureElement.Loci.subtract(lociRange, startLoci)
                : lociRange;
        }
    }
    return loci;
}
export function selectResidueRange(seqData, startResId, //mouseDownPosition.resId,
startChain, //mouseDownPosition.chain
endResId, endChain, structureData) {
    if (startChain === endChain) {
        const loci = selectRangeWithinChain(startResId, endResId, startChain, structureData, true);
        return loci;
    }
    // return;
    const startIndex = seqData.findIndex((p) => p.chain === startChain);
    const endIndex = seqData.findIndex((p) => p.chain === endChain);
    if (startIndex < endIndex) {
        // From the initial seqId to the end of the initial chain
        const initialChainSeqIds = seqData[startIndex].seqIds;
        let lociAll;
        if (initialChainSeqIds.length > 0) {
            const loci = selectRangeWithinChain(startResId, initialChainSeqIds[initialChainSeqIds.length - 1], startChain, structureData, true);
            if (loci)
                lociAll = loci;
        }
        // All seqIds from chains between initial and final
        for (let i = startIndex + 1; i < endIndex; i++) {
            const loci = lociFromSelection(structureData, undefined, seqData[i].chain);
            if (loci) {
                lociAll = lociAll ? StructureElement.Loci.union(lociAll, loci) : loci;
            }
        }
        // From the initial seqId to the end of the initial chain
        const finalChainSeqIds = seqData[endIndex].seqIds;
        if (finalChainSeqIds.length > 0) {
            const loci = selectRangeWithinChain(finalChainSeqIds[0], endResId, endChain, structureData, false);
            if (loci) {
                lociAll = lociAll ? StructureElement.Loci.union(lociAll, loci) : loci;
            }
        }
        return lociAll;
    }
    else {
        // Same in reverse order
        let lociAll;
        const finalChainSeqIds = seqData[endIndex].seqIds;
        if (finalChainSeqIds.length > 0) {
            const loci = selectRangeWithinChain(endResId, finalChainSeqIds[finalChainSeqIds.length - 1], endChain, structureData);
            if (loci)
                lociAll = loci;
        }
        for (let i = startIndex - 1; i > endIndex; i--) {
            const loci = lociFromSelection(structureData, undefined, seqData[i].chain);
            if (loci) {
                lociAll = lociAll ? StructureElement.Loci.union(lociAll, loci) : loci;
            }
        }
        // From the initial seqId to the end of the initial chain
        const initialChainSeqIds = seqData[startIndex].seqIds;
        if (initialChainSeqIds.length > 0) {
            const loci = selectRangeWithinChain(initialChainSeqIds[0], startResId, startChain, structureData, initialChainSeqIds[0] === startResId);
            if (loci) {
                lociAll = lociAll ? StructureElement.Loci.union(lociAll, loci) : loci;
            }
        }
        return lociAll;
    }
}
