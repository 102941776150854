import { selectSystemByIndexArray } from "../../stateTree";
import { setVisibility } from "../../VisibilityToggle";
import produce from "immer";
export function toggleMultipleSystemsVisibility(vss, molstar, checkedSystemsNum) {
    const tree = vss.getState().loaded_structures;
    const sortedPositions = checkedSystemsNum.sort((a, b) => a - b);
    const newtree = produce(tree, (draft) => {
        var _a;
        for (const positionInTree of sortedPositions) {
            const syst = selectSystemByIndexArray(positionInTree, draft);
            let cellRefs = syst.cellRef;
            const currentVis = syst.visibility !== undefined ? syst.visibility : true;
            if (cellRefs)
                setVisibility(molstar, cellRefs, !currentVis);
            syst.visibility = !currentVis;
            if (!currentVis)
                (_a = syst.representations) === null || _a === void 0 ? void 0 : _a.forEach((rep) => (rep.visibility = !currentVis));
        }
    });
    vss.getState().set_loaded_structures(newtree);
}
