import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItemIcon, Box, Tooltip, ListItemButton } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
export const FileUploadButton = ({ disabled, callback, }) => {
    return (_jsx(Tooltip, Object.assign({ enterDelay: 200, title: "Load files", arrow: true, placement: "right" }, { children: _jsxs(ListItemButton, Object.assign({ disabled: !disabled, component: "label" }, { children: [_jsx("input", { style: { display: "none" }, multiple: true, type: "file", id: "multi-file-upload", onChange: (e) => callback(e) }), _jsx(ListItemIcon, { children: _jsx(Box, Object.assign({ sx: { color: "secondary.light" } }, { children: _jsx(DriveFolderUploadIcon, {}) })) })] })) })));
};
