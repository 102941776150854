var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { dispatchControlledNotification, dispatchDeleteControlledNotification, } from "../NotificationSystem/utils";
// This is pretty much copies from ele electron cli_file_load. We should unify
function getSystemFiles(system) {
    if (!system) {
        return []; // Return an empty array for undefined/null system
    }
    if (system.children) {
        const childFiles = system.children.flatMap(getSystemFiles);
        return childFiles; // Recurse into children for systems with children
    }
    else if (system.files) {
        return system.files; // Return the files array for systems with files
    }
    return []; // Return an empty array for systems without files or children
}
function findFilesInConfig(configFile) {
    const filePaths = [];
    for (let elementType in configFile) {
        const elData = configFile[elementType];
        if (Array.isArray(elData)) {
            elData.forEach((systOrOther) => {
                if (systOrOther.path) {
                    // it's a table or plot
                    filePaths.push(systOrOther.path);
                }
                else {
                    // it's a system
                    const systFilePaths = getSystemFiles(systOrOther);
                    filePaths.push(...systFilePaths);
                }
            });
        }
        else {
            if (elData.files) {
                const elFiles = elData.files;
                filePaths.push(...elFiles);
            }
        }
    }
    return filePaths;
}
export function readLocalTableInChunks(filePath) {
    return __awaiter(this, void 0, void 0, function* () {
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        wIPCR.send("read-local-table", filePath);
        let tableArray = [];
        return new Promise((resolve, reject) => {
            wIPCR.on("read-local-table-chunk", (event, chunk) => {
                if (chunk) {
                    tableArray.push(...chunk);
                }
            });
            wIPCR.on("read-local-table-done", (event, success, lastChunk) => {
                if (success) {
                    tableArray.push(...lastChunk);
                    resolve(tableArray);
                }
                else {
                    console.error(`Error readinf the table ${filePath}`);
                    resolve(undefined);
                }
            });
        });
    });
}
export function loadLocalFiles(configFile, acceptedFilesObject) {
    var _a, e_1, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const filePaths = findFilesInConfig(configFile);
        dispatchControlledNotification({
            idx: "config_file",
            type: "info",
            message: `Reading file(s)...`,
        });
        //@ts-ignore
        const wIPCR = window.ipcr;
        if (!wIPCR)
            return;
        try {
            for (var _d = true, filePaths_1 = __asyncValues(filePaths), filePaths_1_1; filePaths_1_1 = yield filePaths_1.next(), _a = filePaths_1_1.done, !_a;) {
                _c = filePaths_1_1.value;
                _d = false;
                try {
                    const filePath = _c;
                    let customFile;
                    customFile = yield wIPCR.invoke("read-local-file", filePath);
                    if (customFile) {
                        acceptedFilesObject[customFile.name] = customFile;
                    }
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = filePaths_1.return)) yield _b.call(filePaths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatchDeleteControlledNotification({ idx: "config_file" });
    });
}
