import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Fade, Box, Typography } from "@mui/material";
import { FileType } from "../..";
import { topPanel, leftPanel, rightPanel } from "../panelDimensions";
import { useLocation } from "wouter";
import { findSystemsByKey } from "../../3dViewer/stateTree";
import { useEffect, useMemo, useRef } from "react";
import { Structure, StructureProperties, } from "molstar/lib/mol-model/structure";
import { MeasurementType, SelectionStore, } from "../../StateStores/selection.store";
import { diff } from "deep-object-diff";
import { getStructureObjects } from "../../3dViewer/Sequence/utils";
import SeqVirtualized from "./SequenceVirtualized";
import { measureAngle, measureDihedral, measureDistance, } from "../../3dViewer/Selection/Measurements/addMeasurements";
export const SequencesPanel = ({ NAPGenericStore, vss, molstar, }) => {
    const ref = useRef(null);
    const [activePanels, setTopPanelHeight] = NAPGenericStore((state) => [state._activePanels, state.setTopPanelHeight], shallow);
    const [currLoc] = useLocation();
    const show = activePanels.sequence;
    const appsPanelOpen = activePanels.apps || currLoc.startsWith("/tools");
    const dataVizOpen = activePanels.plots;
    const dimensions = Object.assign(Object.assign({}, topPanel), { left: (theme) => `calc(${appsPanelOpen ? leftPanel.width + " + " : ""}${theme.spacing(7)})`, width: (theme) => `calc(100vw - (${appsPanelOpen ? leftPanel.width + " + " : ""}${theme.spacing(7)}${dataVizOpen ? " + " + rightPanel.width : ""}))` });
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    const protFileTypes = Object.values(FileType).filter((type) => type != "volume" && type != "sdf");
    const protSystems = useMemo(() => findSystemsByKey(loaded_structures, "type", protFileTypes).filter((system) => system.type != "volume" && system.type != "sdf"), [loaded_structures]);
    const protSystemObjs = useMemo(() => {
        const _protSystemObjs = [];
        protSystems.forEach((system) => {
            const cellRef = system.cellRef ? system.cellRef[0] : undefined;
            const { structureData, seqData } = getStructureObjects(cellRef, molstar);
            if (cellRef && system.visibility !== false) {
                _protSystemObjs.push({ structureData, seqData, system });
            }
        });
        return _protSystemObjs;
    }, [protSystems]);
    const handleResize = (show) => {
        if (ref.current) {
            const newSize = !!show ? ref.current.offsetHeight : 0;
            setTopPanelHeight(newSize);
        }
    };
    useEffect(() => {
        handleResize(show);
    }, [show, protSystems]);
    const handleResizeCompOnWindowResize = () => {
        handleResize(show);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResizeCompOnWindowResize);
        return () => window.removeEventListener("resize", handleResizeCompOnWindowResize);
    }, [show]);
    const [setSelectedResidues, clickedSequence, setClickedSequence] = SelectionStore((state) => [
        state.setSelectedResidues,
        state.clickedSequence,
        state.setClickedSequence,
    ], shallow);
    const updateSelectedResidues = () => {
        const newSelectedResidues = {};
        for (const [cellRef, { structure }] of molstar.managers.structure.selection
            .entries) {
            if (!structure)
                continue;
            if (!(cellRef in newSelectedResidues))
                newSelectedResidues[cellRef] = {};
            Structure.eachAtomicHierarchyElement(structure, {
                residue: (loc) => {
                    const position = StructureProperties.residue.label_seq_id(loc);
                    const chain = StructureProperties.chain.auth_asym_id(loc);
                    if (!(chain in newSelectedResidues[cellRef]))
                        newSelectedResidues[cellRef][chain] = [];
                    newSelectedResidues[cellRef][chain].push(position);
                },
            });
        }
        const selectionDiff = diff(SelectionStore.getState().selectedResidues, newSelectedResidues);
        if (Object.keys(selectionDiff).length > 0) {
            setSelectedResidues(newSelectedResidues);
        }
    };
    useEffect(() => {
        if (!molstar)
            return;
        molstar.behaviors.interaction.click.subscribe((event) => {
            const activeMeasurement = SelectionStore.getState().activeMeasurement;
            if (!activeMeasurement) {
                // get molstar selections
                updateSelectedResidues();
            }
            else if (activeMeasurement === MeasurementType.distance) {
                // create distances based on selection
                measureDistance(molstar);
            }
            else if (activeMeasurement === MeasurementType.angle) {
                // create distances based on selection
                measureAngle(molstar);
            }
            else if (activeMeasurement === MeasurementType.dihedral) {
                // create distances based on selection
                measureDihedral(molstar);
            }
        });
        // molstar.behaviors.interaction.hover.subscribe(
        //   (event: InteractivityManager.HoverEvent) => {
        //     const selectedResidues = {};
        //     if (event.current?.loci?.kind === "element-loci") {
        //       const l = event.current?.loci;
        //       console.log(event.current);
        //       const { unit, indices } = l.elements[0];
        //       const location = StructureElement.Location.create(
        //         l.structure,
        //         unit,
        //         unit.elements[OrderedSet.start(indices)]
        //       );
        //       if (Unit.isAtomic(location.unit)) {
        //         const chainId = StructureProperties.chain.auth_asym_id(location);
        //         console.log(chainId);
        //         const resId = StructureProperties.residue.label_seq_id(location);
        //         console.log(resId);
        //       }
        //     }
        //   }
        // );
        return () => molstar.behaviors.interaction.click.unsubscribe();
    }, [molstar]);
    const clickedSeqRef = clickedSequence === null || clickedSequence === void 0 ? void 0 : clickedSequence.HTMLElement;
    useEffect(() => {
        const handleOutsiseMouseUp = (event) => {
            if (clickedSeqRef &&
                !clickedSeqRef.contains(event.target)) {
                // on mouseUp outide of the component, stop selection
                setClickedSequence(undefined);
            }
        };
        document.addEventListener("mouseup", handleOutsiseMouseUp);
        return () => {
            document.removeEventListener("mouseup", handleOutsiseMouseUp);
        };
    }, [clickedSeqRef]);
    const parentRef = useRef(null);
    return (_jsx(Fade, Object.assign({ in: show, style: { zIndex: 1 }, ref: ref, unmountOnExit: true, timeout: 0 }, { children: _jsxs(Box, Object.assign({ id: "sequence-entrypoint", sx: Object.assign(Object.assign({}, dimensions), { overflowY: "scroll", 
                // height: `200px`,
                // width: `400px`,
                // overflow: "auto",
                backgroundColor: "white" }), ref: parentRef }, { children: [" ", protSystemObjs.length === 0 ? (_jsx(Typography, Object.assign({ gutterBottom: true, variant: "body2", color: "text.secondary", sx: { px: 1 } }, { children: "No proteins displayed." }))) : (_jsx(SeqVirtualized, { parentRef: parentRef, protSystemObjs: protSystemObjs, molstar: molstar }))] })) })));
};
