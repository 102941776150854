var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { FileType, IsLoadingGroup } from "..";
import { renderSystem } from "../FileLoaders/main";
import { selectSystemByIndexArray } from "../3dViewer/stateTree";
function traverseSystemTreeDataPortal(vss, molstar, pyodide, acceptedFiles, allSystems, currentSystem, quiet, archivedOnLoad, skipNotVisible, category) {
    var _a, e_1, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        if (category)
            currentSystem.category = category;
        let donotLoad = undefined;
        if (skipNotVisible && currentSystem.visibility === false) {
            donotLoad = IsLoadingGroup.all;
        }
        const alreadyLoaded = currentSystem.cellRef && currentSystem.cellRef.length > 0;
        if (alreadyLoaded) {
            donotLoad = undefined;
        }
        if (archivedOnLoad) {
            currentSystem.visibility = false;
            currentSystem.archived = true;
        }
        if (currentSystem.files && !donotLoad && !alreadyLoaded) {
            yield renderSystem(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet);
        }
        if (currentSystem.children && currentSystem.type === FileType.group) {
            let someNotLoad = false;
            let allNotLoad = true;
            try {
                for (var _d = true, _e = __asyncValues(currentSystem.children), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                    _c = _f.value;
                    _d = false;
                    try {
                        let s = _c;
                        const child = yield traverseSystemTreeDataPortal(vss, molstar, pyodide, acceptedFiles, allSystems, s, quiet, archivedOnLoad, skipNotVisible, category);
                        if (!!(child === null || child === void 0 ? void 0 : child.donotLoad))
                            someNotLoad = true;
                        if ((child === null || child === void 0 ? void 0 : child.donotLoad) !== IsLoadingGroup.all)
                            allNotLoad = false;
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            donotLoad = allNotLoad
                ? IsLoadingGroup.all
                : someNotLoad
                    ? IsLoadingGroup.some
                    : undefined;
        }
        currentSystem.isLoading = donotLoad;
        return { donotLoad: donotLoad };
    });
}
export function loadSystemsDataPortal(acceptedFilesObject, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad, skipNotVisible, category) {
    var _a, e_2, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const { systems } = loadingInstructions;
        if (systems) {
            try {
                for (var _d = true, systems_1 = __asyncValues(systems), systems_1_1; systems_1_1 = yield systems_1.next(), _a = systems_1_1.done, !_a;) {
                    _c = systems_1_1.value;
                    _d = false;
                    try {
                        let system = _c;
                        yield traverseSystemTreeDataPortal(vss, molstar, pyodide, acceptedFilesObject, systems, system, quiet, archivedOnLoad, skipNotVisible, category);
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = systems_1.return)) yield _b.call(systems_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            const prevSystems = vss.getState().loaded_structures;
            const newSystems = produce(prevSystems, (draft) => {
                // Remove systems of this session already saved
                const indicesToDelete = [];
                draft.forEach((s, i) => {
                    if (s.category == category)
                        indicesToDelete.push(i);
                });
                indicesToDelete.sort((a, b) => b - a);
                indicesToDelete.forEach((index) => {
                    if (index >= 0 && index < draft.length) {
                        draft.splice(index, 1);
                    }
                });
                draft.push(...systems);
            });
            vss.getState().set_loaded_structures(newSystems);
            return newSystems;
        }
    });
}
export function loadMissingSystem(systemPositionInTree, acceptedFiles, 
// loadingInstructions: LoadingInstructions,
vss, molstar, pyodide
// quiet?: boolean,
// archivedOnLoad?: boolean,
// skipNotVisible?: boolean,
// category?: string
) {
    return __awaiter(this, void 0, void 0, function* () {
        const systems = vss.getState().loaded_structures;
        let cellRefs;
        const newSystems = yield produce(systems, (draftSystems) => __awaiter(this, void 0, void 0, function* () {
            const system = selectSystemByIndexArray(systemPositionInTree, draftSystems);
            const alreadyLoaded = system.cellRef && system.cellRef.length > 0;
            if (system.files && !alreadyLoaded) {
                yield renderSystem(vss, molstar, pyodide, acceptedFiles, system);
                cellRefs = system.cellRef;
            }
            system.isLoading = undefined;
        }));
        vss.getState().set_loaded_structures(newSystems);
        return cellRefs;
    });
}
