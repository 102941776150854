var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { executeWithTokenRefresh } from "../../Authentication/";
export function checkRepeatedJobName(group, jobName) {
    return __awaiter(this, void 0, void 0, function* () {
        // repeated from fetchPlaymoleculeJobs - unify
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/jobs`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                group: group,
                children: false,
                count: false,
                limit: -1,
                newer_than: -1,
                older_than: -1,
            }),
        }));
        if (!response.success) {
            throw Error(response.error
                ? response.error
                : "Could not check if the job name is repeated.");
        }
        const jobs = yield response.result.json();
        const isRepeated = jobs.some((job) => job.name === jobName);
        return isRepeated;
    });
}
export function getExecId(group, appid, jobName) {
    return __awaiter(this, void 0, void 0, function* () {
        const postData = JSON.stringify({
            description: "",
            group: group,
            name: jobName,
            specs: "{}",
        });
        const headers = {
            "Content-type": "application/json",
            Accept: "text/plain",
        };
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/jobs/${appid}/create`, {
            method: "POST",
            headers: headers,
            body: postData,
        }));
        if (!response.success) {
            throw Error(response.error ? response.error : "Could not generate a job id.");
        }
        const jobObj = yield response.result.json();
        return jobObj["id"].toString();
    });
}
export function sendStartMessage(execid) {
    return __awaiter(this, void 0, void 0, function* () {
        const headers = {
            "Content-type": "application/json",
            Accept: "text/plain",
        };
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/jobs/${execid}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ periodicity: 0 }),
        }));
        if (!response.success) {
            throw Error(response.error ? response.error : "Could not send job start message.");
        }
    });
}
function getJobParams(appManifestParams, formData, inputpath, pyodide) {
    var _a, appManifestParams_1, appManifestParams_1_1;
    var _b, e_1, _c, _d, _e, e_2, _f, _g;
    return __awaiter(this, void 0, void 0, function* () {
        const submitParams = [];
        try {
            for (_a = true, appManifestParams_1 = __asyncValues(appManifestParams); appManifestParams_1_1 = yield appManifestParams_1.next(), _b = appManifestParams_1_1.done, !_b;) {
                _d = appManifestParams_1_1.value;
                _a = false;
                try {
                    const formField = _d;
                    const attr_name = formField.name;
                    const formVal = formData[attr_name];
                    const datatype = formField.type;
                    const valArray = Array.isArray(formVal) ? formVal : [formVal];
                    const newvals = [];
                    try {
                        for (var _h = true, valArray_1 = (e_2 = void 0, __asyncValues(valArray)), valArray_1_1; valArray_1_1 = yield valArray_1.next(), _e = valArray_1_1.done, !_e;) {
                            _g = valArray_1_1.value;
                            _h = false;
                            try {
                                let val = _g;
                                const valIsEmpty = val === undefined || val === null || val === "";
                                if (valIsEmpty) {
                                    if (formField.mandatory &&
                                        !["outdir", "scratchdir"].includes(attr_name)) {
                                        throw Error(`Mandatory field '${attr_name}' is empty.`);
                                    }
                                    else {
                                        val = undefined;
                                    }
                                }
                                const isFile = ["Path", "file", "fileORstring"].includes(datatype);
                                if (isFile &&
                                    !valIsEmpty &&
                                    !(typeof val === "string" && val.startsWith("dc://"))) {
                                    // check if isfile or isdir
                                    const fileExists = yield pyodide.RunPythonAsync({
                                        context: { val },
                                        script: `
          from js import val
          os.path.isfile(val) or os.path.isdir(val)
          `,
                                    });
                                    if (!fileExists) {
                                        if (["Path", "file"].includes(datatype) || val.includes(".")) {
                                            throw Error(`File or folder does not exist: ${val}`);
                                        }
                                    }
                                    else {
                                        try {
                                            yield pyodide.RunPythonAsync({
                                                context: { val, inputpath },
                                                script: `
              from js import val, inputpath
              filename = os.path.basename(os.path.normpath(val))
              dest = os.path.join(inputpath, filename)
              copy_file_or_dir(val, dest, True)
              `,
                                            });
                                        }
                                        catch (e) {
                                            throw Error(`${e}`);
                                        }
                                    }
                                }
                                else {
                                    if (typeof val === "boolean" && typeof formField.value === "string") {
                                        //TODO: Deprecate this once all apps have generated new style manifests
                                        val = val.toString();
                                    }
                                }
                                newvals.push(val);
                            }
                            finally {
                                _h = true;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (!_h && !_e && (_f = valArray_1.return)) yield _f.call(valArray_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    submitParams.push({
                        name: attr_name,
                        value: newvals.length > 1 ? newvals : newvals[0],
                        type: formField.type,
                        tag: formField.tag,
                    });
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = appManifestParams_1.return)) yield _c.call(appManifestParams_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return submitParams;
    });
}
export function uploadJobParams(appManifestParams, formData, execid, group, appName, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const tmpdir = "/tmp";
        const inputpath = `${tmpdir}/${execid}`;
        yield pyodide.RunPythonAsync({
            context: { inputpath },
            script: `
    from js import inputpath
    os.makedirs(inputpath, exist_ok=True)
    `,
        });
        let submitParams;
        let error;
        try {
            submitParams = yield getJobParams(appManifestParams, formData, inputpath, pyodide);
            yield uploadFile(tmpdir, inputpath, execid, group, appName, "input", pyodide);
        }
        catch (e) {
            error = e;
        }
        // delete input files dir
        yield pyodide.RunPythonAsync({
            context: { inputpath },
            script: `
          from js import inputpath
          shutil.rmtree(inputpath)
          `,
        });
        if (error)
            throw Error(error);
        return submitParams;
    });
}
function uploadFile(basePath, inputFile, execid, group, appName, name, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        const comments = group;
        const remotepath = `${execid}/${name}`;
        const ispublic = false;
        const overwrite = true;
        let attempts = 5;
        const _logger = true;
        const tags = [`app:${appName}:${name}`, `type:${name}`];
        let fileToPost;
        try {
            fileToPost = yield pyodide.RunPythonAsync({
                context: { basePath, inputFile },
                script: `
      from js import basePath, inputFile
      create_tar_file(inputFile, basePath)
      `,
            });
        }
        catch (e) {
            throw Error(`${e}`);
        }
        const fileContent = yield pyodide.FS.readFile(fileToPost, "binary");
        const blob = new Blob([fileContent], {
            type: "application/x-gzip", //"text/plain"
        });
        var postData = new FormData();
        const fileName = fileToPost.split("/").pop();
        postData.append("uploadfile", blob, fileName);
        const data = {
            remotepath: remotepath,
            comments: comments,
            public: ispublic,
            execid: execid,
            overwrite: overwrite,
            tags: tags.join(","),
        };
        Object.entries(data).forEach(([key, val]) => {
            postData.append(key, val.toString());
        });
        let uploaded = false;
        let response;
        while (!uploaded && attempts > 0) {
            try {
                response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/datacenter`, {
                    method: "POST",
                    body: postData,
                }));
                uploaded = true;
            }
            catch (error) {
                attempts -= 1;
                console.error(`Error uploading dataset ${inputFile} with error ${error}. Retrying in 5s. Attempts remaining ${attempts}.`, _logger);
                yield new Promise((resolve) => setTimeout(resolve, 5000)); // Sleep for 5 seconds
            }
        }
        try {
            yield pyodide.RunPythonAsync({
                context: { fileToPost },
                script: `
      from js import fileToPost
      os.remove(fileToPost)
      `,
            });
        }
        catch (e) {
            console.error(`could not remove file ${fileToPost}`);
        }
        if (!uploaded) {
            throw Error(`Error uploading dataset ${inputFile}`);
        }
        if (!(response === null || response === void 0 ? void 0 : response.success)) {
            const respError = response === null || response === void 0 ? void 0 : response.error;
            throw Error(respError ? respError : "Error uploading the dataset");
        }
    });
}
export function uploadJobFiles(execid, group, appName, out_config, pyodide) {
    return __awaiter(this, void 0, void 0, function* () {
        let configFile;
        let tmpDirPath;
        try {
            ({ filePath: configFile, tmpDirPath } = yield pyodide.RunPythonAsync({
                context: { out_config, execid },
                script: `
      from js import out_config, execid
      create_tmp_config_file(out_config, execid)
      `,
            }));
        }
        catch (e) {
            throw Error(`${e}`);
        }
        yield uploadFile(tmpDirPath, configFile, execid, group, appName, "config", pyodide);
        try {
            yield pyodide.RunPythonAsync({
                context: { tmpDirPath },
                script: `
          from js import tmpDirPath
          shutil.rmtree(tmpDirPath)
          `,
            });
        }
        catch (e) {
            console.error(e);
        }
    });
}
