import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Paper } from "@mui/material";
import { NAPTableStore } from "../../StateStores/tables.store";
import { useKeyPress } from "./../../utils/useKeyPress";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { PreviousNextButtons } from "../../3dViewer/Controls/PreviousNextButtons";
import { NAPGenericStore } from "../../GenericViewerState";
export function TableNavigation({ handleClickTableNavigation, numRows, }) {
    const [isWaiting] = NAPGenericStore((state) => [state.loadingDCFile]);
    const [rowIdx, setRowIdx, addStepToNavigationIdx] = NAPTableStore((state) => [
        state.navigationIdx,
        state.setNavigationIdx,
        state.addStepToNavigationIdx,
    ], shallow);
    useEffect(() => {
        handleClickTableNavigation(rowIdx);
    }, [rowIdx]);
    const handleClickStep = (step) => {
        if (isWaiting) {
            return;
        }
        addStepToNavigationIdx(step);
    };
    const handleClickSet = (newIdx) => {
        if (isWaiting) {
            return;
        }
        setRowIdx(newIdx);
    };
    useKeyPress(["ArrowRight"], () => {
        handleClickStep(1);
    }, false, false, false);
    useKeyPress(["ArrowLeft"], () => {
        handleClickStep(-1);
    }, false, false, false);
    // Listent to events to navigate table
    useEffect(() => {
        const _cb = (e) => {
            const { step } = e.detail;
            handleClickStep(step);
        };
        window.addEventListener("PM-TableNavigationStep", _cb);
        return () => {
            window.removeEventListener("PM-TableNavigationStep", _cb);
        };
    }, []);
    useEffect(() => {
        const _cb = (e) => {
            const { value } = e.detail;
            handleClickSet(value);
        };
        window.addEventListener("PM-TableNavigationSet", _cb);
        return () => {
            window.removeEventListener("PM-TableNavigationSet", _cb);
        };
    }, []);
    return (_jsx(Paper, Object.assign({ sx: { order: 6, width: "100%", mt: 1 } }, { children: _jsx(PreviousNextButtons, { handleClickFirst: () => handleClickSet(0), handleClickPrevious: () => handleClickStep(-1), handleClickNext: () => handleClickStep(1), handleClickLast: () => handleClickSet(numRows - 1), firstIsDisabled: isWaiting || rowIdx === 0, previousIsDisabled: isWaiting || rowIdx === 0, nextIsDisabled: isWaiting || rowIdx === numRows - 1, lastIsDisabled: isWaiting || rowIdx === numRows - 1, size: "large" }) })));
}
export function dispatchTableNavigationStepEvent({ step }) {
    window.dispatchEvent(new CustomEvent("PM-TableNavigationStep", {
        detail: { step },
    }));
}
export function dispatchTableNavigationSetEvent({ value }) {
    window.dispatchEvent(new CustomEvent("PM-TableNavigationSet", {
        detail: { value },
    }));
}
