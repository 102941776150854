var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Grid, Button, Tooltip, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { dispatchFileSelectionEvent } from "../../../DatacenterBrowser/FileBrowserDialog";
import { writeFileInVFS } from "../../../apps/utils";
import { SplitButton } from "../../SplitButton";
export function FileInput({ file, pyodide, setFile, label, disabled = false, error, }) {
    const hasSelection = !!file;
    const [isLoading, setIsLoading] = useState(false);
    const [fileLabel, setFileLabel] = useState(file);
    useEffect(() => {
        if (!file) {
            setFileLabel("");
            return;
        }
        let label = file;
        if (file.startsWith("dc://")) {
            const dcFileName = file.split("=").pop();
            if (dcFileName)
                label = dcFileName;
        }
        else {
            const fileName = file.split("/").pop();
            if (fileName)
                label = fileName;
        }
        setFileLabel(label);
    }, [file]);
    const handleClick = (option, data) => __awaiter(this, void 0, void 0, function* () {
        switch (option) {
            case "datacenter":
                dispatchFileSelectionEvent([], "Select a file:").then((file) => {
                    if (!file)
                        return;
                    let fileID = file.id.startsWith("dc://")
                        ? file.id
                        : `${file.parentId}?files=${file.name}`;
                    if (file.isDir && fileID.indexOf("?files=") > -1)
                        fileID = fileID + "/";
                    setFile(fileID);
                });
                break;
            case "local":
                setIsLoading(true);
                const filePath = yield writeFileInVFS(pyodide, data);
                setIsLoading(false);
                setFile(filePath);
                break;
            default:
                throw Error("unknown option!");
        }
    });
    const clearSelection = () => {
        setFile("");
    };
    const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);
    const handleOptionIdxChange = (newIdx) => {
        setSelectedOptionIdx(newIdx);
    };
    return (
    // <Box sx={{ maxWidth: "23em" }}>
    _jsxs(Grid, Object.assign({ container: true, direction: "column" }, { children: [_jsxs(Grid, Object.assign({ item: true, container: true, direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 1, xs: true }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: "auto" }, { children: [" ", hasSelection ? (_jsx(Button, Object.assign({ onClick: clearSelection, variant: "outlined", color: "error", sx: { width: "120px" }, endIcon: _jsx(ClearIcon, {}) }, { children: "Clear" }))) : (_jsx(SplitButton, { onClick: handleClick, options: options, isLoading: isLoading, selectedOptionIdx: selectedOptionIdx, handleOptionIdxChange: handleOptionIdxChange, disabled: disabled, variant: "contained", poperPlacement: "right-end" }))] })), _jsx(Grid, Object.assign({ item: true, xs: true, zeroMinWidth: true }, { children: _jsx(Tooltip, Object.assign({ title: fileLabel, arrow: true, placement: "top-start" }, { children: _jsx(Typography, Object.assign({ style: { textOverflow: "ellipsis", overflow: "hidden" } }, { children: fileLabel })) })) }))] })), error ? (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(Typography, Object.assign({ variant: "caption", sx: { color: "#d32f2f" } }, { children: error })) }))) : null] }))
    // </Box>
    );
}
const options = [
    {
        label: "From local file system",
        key: "local",
        isInput: true,
        icon: _jsx(DriveFolderUploadIcon, {}),
    },
    {
        label: "From datacenter",
        key: "datacenter",
        isInput: false,
        icon: _jsx(CloudDownloadIcon, {}),
    },
];
