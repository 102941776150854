import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Collapse, Paper, Grid, IconButton, Typography, Box, Tooltip, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useMemo } from "react";
import { selectSystemByIndexArray } from "../../stateTree";
export function CloneRepresentationsMenu({ numSelectedSystems, isActive, onAccept, onCancel, disabledCheckboxSystems, loaded_structures, backgroundColor, }) {
    const fromSystPosStr = disabledCheckboxSystems.length > 0 ? disabledCheckboxSystems[0] : undefined;
    const fromSystName = useMemo(() => {
        if (!fromSystPosStr)
            return;
        const fromSystPos = fromSystPosStr
            .split("-")
            .map((sysIdEl) => Number(sysIdEl));
        if (!fromSystPos)
            return;
        const system = selectSystemByIndexArray(fromSystPos, loaded_structures);
        return system.name;
    }, [fromSystPosStr, loaded_structures.length]);
    return (_jsx(Collapse, Object.assign({ in: isActive, unmountOnExit: true }, { children: _jsx(Paper, Object.assign({ elevation: 0, sx: {
                backgroundColor: backgroundColor ? backgroundColor : undefined,
            } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "flex-end", sx: { mt: 1, py: 1, px: 2 }, spacing: 1 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: true }, { children: [_jsx(Typography, Object.assign({ sx: { pb: 1 } }, { children: "Clone Representations" })), _jsx(CloneFromDetails, { name: fromSystName ? fromSystName : "" }), _jsxs(Typography, Object.assign({ sx: { fontSize: "0.875rem" } }, { children: ["to: (", numSelectedSystems, " selected)"] }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ onClick: onCancel, color: "primary", size: "small" }, { children: _jsx(CloseIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(IconButton, Object.assign({ size: "small", color: "primary", onClick: onAccept, disabled: numSelectedSystems === 0 }, { children: _jsx(CheckIcon, {}) })) }))] })) })) })));
}
function CloneFromDetails({ name }) {
    return (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "flex-start", alignItems: "center" }, { children: [_jsxs(Typography, Object.assign({ display: "inline", sx: { fontSize: "0.875rem", mr: "5px" }, noWrap: true }, { children: ["from:", " "] })), _jsx(SystemName, { name: name })] })));
}
function SystemName({ name }) {
    return (_jsx(Box, Object.assign({ sx: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "9.5em",
            direction: "rtl",
            textAlign: "left",
        } }, { children: _jsx(Tooltip, Object.assign({ arrow: true, enterDelay: 1000, title: name, placement: "right" }, { children: _jsx(Typography, Object.assign({ display: "inline", noWrap: true, sx: {
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    fontSize: "0.875rem",
                } }, { children: name })) })) })));
}
