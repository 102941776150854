export function defineColorParams(color, color_value, carbon_color_value) {
    const uniformColorAvailable = color === "uniform" && color_value ? color_value : undefined;
    const carbonColorAvailable = color === "element-symbol" && carbon_color_value
        ? carbon_color_value
        : undefined;
    const colorParams = uniformColorAvailable
        ? {
            value: uniformColorAvailable,
            carbonColor: undefined,
        }
        : carbonColorAvailable
            ? {
                carbonColor: {
                    name: "uniform",
                    params: { value: carbonColorAvailable },
                },
            }
            : undefined;
    return colorParams;
}
