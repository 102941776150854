// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { sleep } from "../utils/sleep";
import { NAPTableStore } from "../StateStores/tables.store";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import { formatTable, parseCSVString, parseCSVFile } from "./prepareTable";
import uuidv4 from "../utils/UUID";
import { readLocalTableInChunks } from "../FileLoaders/loadLocalFiles";
import { getRawFileContent } from "../apps/results/fetchDcData";
/* Just reads the file content. */
export function handleCSVLoad(tableFile) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(`File reading was aborted for ${tableFile.name}`);
        reader.onerror = () => {
            reject(`File reading has failed for ${tableFile.name}`);
        };
        reader.onload = () => {
            // Do whatever you want with the file contents
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
        reader.readAsText(tableFile);
    });
}
function loadTable(pyodide, tableData, acceptedFilesObject, localBasePath, quiet, archivedOnLoad) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const tableFilePath = tableData.path;
        let tableFileName;
        let tableDCPath;
        let tableArr;
        let error;
        const isRemote = tableData.path.startsWith("dc://");
        if (isRemote) {
            if (!pyodide) {
                console.error("pyodide instance not found!");
                return;
            }
            // load from datacenter
            tableFileName = tableData.path.slice(tableData.path.lastIndexOf("=") + 1);
            if (!quiet)
                dispatchControlledNotification({
                    idx: tableFileName,
                    type: "info",
                    message: `Loading...`,
                    fileName: tableFileName,
                });
            const tableDataPath = tableData.path;
            const csvString = yield getRawFileContent(tableDataPath, pyodide);
            yield sleep(2000);
            try {
                tableArr = yield parseCSVString(csvString.replace(/\n+$/, ""));
            }
            catch (e) {
                error = e;
            }
            tableDCPath = tableData.path.slice(0, tableData.path.indexOf("?"));
        }
        else {
            // the table should be among the loaded local files
            if (acceptedFilesObject && tableFilePath in acceptedFilesObject) {
                const tableFile = acceptedFilesObject[tableFilePath];
                tableFileName = tableFile.name;
                if (!quiet)
                    dispatchControlledNotification({
                        idx: tableFileName,
                        type: "info",
                        message: `Loading`,
                        fileName: tableFileName,
                    });
                if (tableFile["isInLFS"]) {
                    tableArr = yield readLocalTableInChunks(tableFile["name"]);
                }
                else if (tableFile["isLoaded"]) {
                    try {
                        const _tableFile = tableFile;
                        tableArr = yield parseCSVString(_tableFile.data);
                    }
                    catch (e) {
                        error = e;
                    }
                }
                else {
                    try {
                        tableArr = yield parseCSVFile(tableFile);
                    }
                    catch (e) {
                        error = e;
                    }
                }
            }
        }
        let tableRes = undefined;
        if (tableArr && tableArr.length > 0) {
            const loadedTable = yield formatTable(tableArr, (_a = tableData.options) === null || _a === void 0 ? void 0 : _a.rowButton, localBasePath, (_b = tableData.options) === null || _b === void 0 ? void 0 : _b.onRowClick, (_c = tableData.options) === null || _c === void 0 ? void 0 : _c.SMILESColumns);
            if (loadedTable) {
                tableRes = Object.assign(Object.assign({}, loadedTable), { tableID: uuidv4(), name: tableData.name ? tableData.name : tableFileName, path: tableFileName, localBasePath: localBasePath, archived: archivedOnLoad });
                if (isRemote) {
                    tableRes["DCpath"] = tableDCPath;
                }
                if (tableData.options)
                    tableRes["options"] = tableData.options;
            }
        }
        if (!tableRes) {
            error = "Error loading the table.";
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: tableFileName });
        if (error) {
            dispatchNotificationEvent({
                type: "error",
                message: error,
            });
        }
        return tableRes;
    });
}
export function loadTables(pyodide, tables, NAPGenericStore, acceptedFilesObject, localBasePath, quiet, archivedOnLoad) {
    var _a, tables_1, tables_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        let loadedTables = [];
        try {
            for (_a = true, tables_1 = __asyncValues(tables); tables_1_1 = yield tables_1.next(), _b = tables_1_1.done, !_b;) {
                _d = tables_1_1.value;
                _a = false;
                try {
                    let tableData = _d;
                    const tableConfig = yield loadTable(pyodide, tableData, acceptedFilesObject, localBasePath, quiet, archivedOnLoad);
                    if (tableConfig) {
                        loadedTables.push(tableConfig);
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = tables_1.return)) yield _c.call(tables_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (loadedTables.length > 0) {
            NAPTableStore.getState().add_tables(loadedTables);
            if (!archivedOnLoad) {
                const activePanels = NAPGenericStore.getState()._activePanels;
                NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
            }
        }
    });
}
