// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var PlotClickAction;
(function (PlotClickAction) {
    PlotClickAction["displaySystem"] = "displaySystem";
    PlotClickAction["displayFrames"] = "displayFrames";
    PlotClickAction["toggleVisibility"] = "toggleVisibility";
    PlotClickAction["selectEdgeEndpoints"] = "selectEdgeEndpoints";
})(PlotClickAction || (PlotClickAction = {}));
export var PlotHoverAction;
(function (PlotHoverAction) {
    PlotHoverAction["highlightSystem"] = "highlightSystem";
})(PlotHoverAction || (PlotHoverAction = {}));
