var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { FileType, validateNAPfile, generateFileConfiguration, supportedFiles, TrajectoryModeEnum, } from "..";
import { loadTables } from "../Tables/loadTables";
import { syncTableToSystems } from "../Tables/utils";
import { loadPlots } from "./plots";
import { uuidv4 } from "../utils/_exports";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { createMolstarModels } from "../3dViewer/createMolstarModels";
import { addRepresentation } from "../3dViewer/showFile";
import { renderDataCenterSystem } from "./renderSystem";
import { loadInMoleculeKit } from "./loadInMoleculeKit";
import { findSystemByKey } from "../3dViewer/stateTree";
import { renderVolume } from "../3dViewer/renderVolume";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { deleteMolkitMolecules } from "../3dViewer/Controls/utils";
import { getFilesFullPath } from "./utils";
import { loadLocalFiles } from "./loadLocalFiles";
import { loadTrajShowingMultipleFrames } from "./loadTrajShowingMultipleFrames";
// import { loadMSAs } from "../MSA/loadMSA";
/**
 * Entrypoint of the file loading pipeline.
 * Detects if configuration file(s) are paseed and triggers appropriate actions.
 */
export const fileUploadingHandler = (e, vss, molstar, pyodide, NAPGenericStore, callback) => __awaiter(void 0, void 0, void 0, function* () {
    const target = e.target;
    const acceptedFiles = target.files;
    const configFilesFound = findConfigFile(acceptedFiles);
    if (configFilesFound.length > 1) {
        dispatchNotificationEvent({
            type: "error",
            message: "More than one configuration files found, exactly one required!",
        });
        return;
    }
    const acceptedFilesObject = Object.assign({}, ...Array.from(acceptedFiles).map((x) => ({
        [x["path"] ? x["path"] : x.name]: x,
    })));
    let instructions;
    let localBasePath;
    switch (configFilesFound.length) {
        /* NO NAP FILE, GENERATE IT ON THE FLY */
        case 0:
            const config = generateFileConfiguration(acceptedFiles);
            if (config.unsupportedFilesDetected.length > 0)
                displayNotSupportedError(config.unsupportedFilesDetected);
            if (config.unmatchedFiles.length > 0) {
                displayUnmatchedError(config.unmatchedFiles);
            }
            instructions = config.loadingInstructions;
            break;
        /* NAP FILE PROVIDED, USE IT AS LOADING INSTRUCTIONS */
        case 1:
            const [configFile] = configFilesFound;
            const napFilePath = configFile["path"];
            localBasePath = napFilePath
                ? napFilePath.substring(0, napFilePath.lastIndexOf("/"))
                : undefined;
            instructions = yield generateLoadingInstructions(configFile, acceptedFilesObject, localBasePath);
            break;
        default:
            break;
    }
    if (instructions) {
        yield loadAllFileTypes(acceptedFilesObject, instructions, vss, molstar, pyodide, NAPGenericStore, localBasePath);
        callback(instructions);
    }
});
/**
 * Loads all file types specified in the given loading instructions and adds
 * successfully rendered systems to the VSS state.
 */
export function loadAllFileTypes(acceptedFiles, loadingInstructions, vss, molstar, pyodide, NAPGenericStore, localBasePath, quiet, archivedOnLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        const s = yield loadSystems(acceptedFiles, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad);
        if (loadingInstructions.tables)
            yield loadTables(pyodide, loadingInstructions.tables, NAPGenericStore, acceptedFiles, localBasePath, quiet, archivedOnLoad);
        if (s)
            syncTableToSystems(s);
        // if (loadingInstructions.MSAs?.files)
        //   await loadMSAs(
        //     acceptedFiles,
        //     loadingInstructions.MSAs?.files,
        //     NAPGenericStore
        //   );
        if (loadingInstructions.plots)
            yield loadPlots(pyodide, acceptedFiles, loadingInstructions.plots, NAPGenericStore, localBasePath, quiet, archivedOnLoad);
    });
}
export function getFileType(mol, singleResidue, predefinedType) {
    if (mol && mol.numFrames > 1) {
        return predefinedType ? predefinedType : FileType.trajectory;
    }
    return singleResidue ? FileType.sdf : FileType.coordinates_single;
}
export function generateModelAndReps(vss, molstar, pyodide, system, topFileContent, trajFileContent, topFileName, trajFileName, 
/*
  If the ID of an existing system is passed, use it to replace its models
  and representations with the new ones generated here. Otherwise generate a
  new uuid and build a new system from scratch.
*/
systemID = uuidv4(), systemLabel, quiet, isInVFS) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        if (topFileContent === null)
            console.error("Topology file content is null!");
        // --------------------- REPLACE EXISTING SYSTEM -------------------------//
        const { loaded_structures } = vss.getState();
        const existingSystem = findSystemByKey(loaded_structures, "moleculeID", systemID);
        if (existingSystem) {
            // Updating an existing system (through ipython)
            deleteMolkitMolecules(existingSystem, pyodide);
            // Delete all the structures of this molecule from molstar
            if (existingSystem === null || existingSystem === void 0 ? void 0 : existingSystem.cellRef) {
                existingSystem.cellRef.forEach((ref) => __awaiter(this, void 0, void 0, function* () {
                    yield PluginCommands.State.RemoveObject(molstar, {
                        state: molstar.state.data,
                        ref: ref,
                    });
                }));
            }
            system = existingSystem;
        }
        // -----------------------------------------------------------------------//
        if (system.type === FileType.volume) {
            let _topFileContent;
            if (isInVFS) {
                _topFileContent = yield pyodide.FS.readFile(topFileContent, "utf8");
            }
            else {
                _topFileContent = topFileContent;
            }
            const volData = yield renderVolume(_topFileContent /* cubefile content is always string */, molstar, system.volumeRepresentation);
            const [volParams, volState] = volData;
            const prevVolumeParams = system.volumeRepresentation
                ? system.volumeRepresentation
                : {};
            const volumeParams = {
                cellRef: [volState.ref],
                volumeRepresentation: Object.assign(Object.assign({}, prevVolumeParams), { minIsoValue: volParams.minIsoVal, maxIsoValue: volParams.maxIsoVal }),
            };
            system.moleculeID = systemID;
            system.cellRef = volumeParams.cellRef;
            system.volumeRepresentation = volumeParams.volumeRepresentation;
            return;
        }
        const file_extension = topFileName.split(".").pop();
        const isSDF = file_extension === "sdf";
        try {
            const mols = yield loadInMoleculeKit(pyodide, systemID, topFileContent, topFileName, isSDF, trajFileContent, trajFileName, isInVFS);
            let singleResidue = new Set(mols[0].resname).size === 1;
            let ftype = getFileType(mols[0], singleResidue, system.type);
            system.cellRef = [];
            system.type = ftype;
            for (const mol of mols) {
                const cellRef = yield createMolstarModels(molstar, mol, system.name, ftype);
                if (isSDF) {
                    system.cellRef = system.cellRef.concat(cellRef);
                }
                else {
                    system.cellRef = cellRef;
                }
            }
            if (system.type === FileType.trajectory &&
                ((_b = (_a = system.trajectoryParameters) === null || _a === void 0 ? void 0 : _a.mode) === null || _b === void 0 ? void 0 : _b.name) ===
                    TrajectoryModeEnum.multipleFrames) {
                const ensembleCellRefs = yield loadTrajShowingMultipleFrames(system.cellRef, molstar, system.trajectoryParameters.mode.options);
                if (ensembleCellRefs) {
                    system.cellRef = ensembleCellRefs;
                }
            }
            system.moleculeID = systemID;
            if (systemLabel)
                system.name = systemLabel;
            const filesStr = system.files &&
                system.files.length > 1 &&
                system.files.join(", ") !== system.name
                ? `(${(_c = system.files) === null || _c === void 0 ? void 0 : _c.join(", ")})`
                : "";
            if (!quiet)
                dispatchControlledNotification({
                    idx: system.name,
                    type: "info",
                    message: `Creating representations...`,
                    fileName: `${system.name} ${filesStr}`,
                });
            system.aromaticBonds = mols[0].bondtype.includes("ar");
            const addedReps = yield addRepresentation(molstar, pyodide, singleResidue, systemID, isSDF, isSDF ? undefined : mols[0], system.cellRef, system.visibility, system.representations, system.aromaticBonds);
            if (!quiet)
                dispatchDeleteControlledNotification({ idx: system.name });
            if (addedReps) {
                system.representations = addedReps;
            }
            const currentSysTree = vss.getState().loaded_structures;
            if (existingSystem) {
                // TODO: swap the new system with its match in the state tree
                const newSysts = produce(currentSysTree, (draft) => {
                    let existingSystem = findSystemByKey(draft, "moleculeID", systemID);
                    existingSystem = system;
                });
                return newSysts;
            }
            return [...currentSysTree, system];
        }
        catch (error) {
            console.error(`loadInMoleculeKit failed for ${system.name}.`);
            dispatchNotificationEvent({ message: error, type: "error" });
        }
    });
}
/**
 * Renders systems recursively based on the provided loading instructions
 * and adds successfully rendered systems to viewer state.
 */
export function loadSystems(acceptedFilesObject, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad) {
    var _a, e_1, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const { systems } = loadingInstructions;
        if (systems) {
            try {
                for (var _d = true, systems_1 = __asyncValues(systems), systems_1_1; systems_1_1 = yield systems_1.next(), _a = systems_1_1.done, !_a;) {
                    _c = systems_1_1.value;
                    _d = false;
                    try {
                        let system = _c;
                        yield traverseSystemTree(vss, molstar, pyodide, acceptedFilesObject, systems, system, quiet, archivedOnLoad);
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = systems_1.return)) yield _b.call(systems_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const prevSystems = vss.getState().loaded_structures;
            const newSystems = produce(prevSystems, (draft) => {
                // Remove all systems without cellRef (it means they failed)
                const systemsWithoutFailedEntries = systems.filter((s) => s.cellRef || s.type === FileType.group);
                draft.push(...systemsWithoutFailedEntries);
            });
            vss.getState().set_loaded_structures(newSystems);
            return newSystems;
        }
    });
}
/**
 * Recursively renders the files of each MolSystem in the passed system tree.
 */
function traverseSystemTree(vss, molstar, pyodide, acceptedFiles, allSystems, currentSystem, quiet, archivedOnLoad) {
    var _a, e_2, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        if (archivedOnLoad) {
            currentSystem.visibility = false;
            currentSystem.archived = true;
        }
        if (currentSystem.files) {
            yield renderSystem(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet);
        }
        if (currentSystem.children && currentSystem.type === FileType.group) {
            try {
                for (var _d = true, _e = __asyncValues(currentSystem.children), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                    _c = _f.value;
                    _d = false;
                    try {
                        let s = _c;
                        yield traverseSystemTree(vss, molstar, pyodide, acceptedFiles, allSystems, s, quiet, archivedOnLoad);
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    });
}
export function renderSystem(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const systemFileNames = currentSystem.files ? currentSystem.files : [];
        const filesStr = systemFileNames.length > 1 &&
            systemFileNames.join(", ") !== currentSystem.name
            ? `(${systemFileNames === null || systemFileNames === void 0 ? void 0 : systemFileNames.join(", ")})`
            : "";
        if (!quiet)
            dispatchControlledNotification({
                idx: currentSystem.name,
                type: "info",
                message: `Loading...`,
                fileName: `${currentSystem.name} ${filesStr}`,
            });
        const isRemote = (_a = currentSystem.files) === null || _a === void 0 ? void 0 : _a.every((f) => f.startsWith("dc://"));
        if (isRemote) {
            yield renderFromDatacenter(vss, molstar, pyodide, currentSystem, quiet);
        }
        else {
            yield renderFromFile(vss, molstar, pyodide, acceptedFiles, currentSystem, quiet);
        }
        if (!quiet)
            dispatchDeleteControlledNotification({ idx: currentSystem.name });
        if (currentSystem.visibility === false && currentSystem.cellRef) {
            setVisibility(molstar, currentSystem.cellRef, false);
        }
    });
}
function renderFromDatacenter(
/*
  Warning!
  This function gets the system from a nap file.
  It is NOT a filebrowser callback!
*/
vss, molstar, pyodide, molSystem, quiet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!molSystem.files)
            throw Error(`System ${molSystem.name} has no files!`);
        /* By topology file we mean most things here, like pdb's or sdf's etc. */
        let topoFileName;
        let trajFileName;
        if (molSystem.type === FileType.trajectory ||
            molSystem.type === FileType.ensemble ||
            molSystem.type === FileType.coordinates_group) {
            trajFileName = molSystem.files.find((f) => {
                const ext = f.split(".").pop();
                return ext && supportedFiles.coordinates_trajectory.includes(ext);
            });
            topoFileName = molSystem.files.find((f) => {
                const ext = f.split(".").pop();
                return (ext &&
                    (supportedFiles.topology.includes(ext) ||
                        supportedFiles.coordinates_static.includes(ext)));
            });
        }
        else {
            const files_coords = molSystem.files.filter((f) => {
                const ext = f.split(".").pop();
                return (ext &&
                    (supportedFiles.coordinates_static.includes(ext) ||
                        supportedFiles.volume.includes(ext)));
            });
            if (files_coords.length > 0) {
                [topoFileName] = files_coords;
            }
        }
        if (!topoFileName)
            throw Error("Topology file is mandatory!");
        yield renderDataCenterSystem(vss, molstar, pyodide, topoFileName, trajFileName, molSystem, undefined, quiet);
    });
}
function renderFromFile(vss, molstar, pyodide, acceptedFiles, system, quiet) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { topFileContent, trajFileContent, topFileName, trajFileName, isInVFS, } = yield getFileContentForSystem(acceptedFiles, system);
            yield generateModelAndReps(vss, molstar, pyodide, system, 
            /* force type because undefined is caught beforehand */
            topFileContent, trajFileContent, topFileName, trajFileName, undefined, undefined, quiet, isInVFS);
        }
        catch (e) {
            dispatchNotificationEvent({
                type: "error",
                message: `${e}`,
            });
        }
    });
}
function generateLoadingInstructions(confFile, acceptedFilesObject, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        let loadingInstructions;
        try {
            loadingInstructions = yield readConfigFile(confFile);
        }
        catch (error) {
            dispatchNotificationEvent({ message: error, type: "error" });
            return;
        }
        if (localBasePath) {
            getFilesFullPath(loadingInstructions, localBasePath);
            yield loadLocalFiles(loadingInstructions, acceptedFilesObject);
        }
        const missingFiles = checkForMissingFiles(loadingInstructions, acceptedFilesObject);
        if (missingFiles.length > 0) {
            dispatchNotificationEvent({
                message: `Please upload the session file together with the files referenced there. The following files are missing: ${missingFiles.join(", ")}`,
                type: "error",
            });
            return;
        }
        return loadingInstructions;
    });
}
/**
 * Retrieves the content of files associated with the system passed.
 * If the system is a trajectory, it returns both the content of the
 * trajectory and topology files.
 *
 * For all other system types, only the topology file content is returned.
 *
 * An error is thrown if the system has no associated files
 * or if the topology file content could not be read.
 */
function getFileContentForSystem(acceptedFiles, system) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (system.type === FileType.trajectory ||
                system.type === FileType.ensemble ||
                system.type === FileType.coordinates_group) {
                const { topFileContent, trajFileContent, topFileName, trajFileName, isInVFS, } = yield loadTrajectoryFilePair(acceptedFiles, system);
                return {
                    topFileContent: topFileContent,
                    trajFileContent: trajFileContent,
                    topFileName: topFileName,
                    trajFileName: trajFileName,
                    isInVFS: isInVFS,
                };
            }
        }
        catch (e) {
            console.error(e);
        }
        if (!system.files)
            throw Error(`system ${system.name} has no associated files!`);
        const [topFileName] = system.files; // non-traj --> only 1 file
        const fileRef = acceptedFiles[topFileName]; // pickup from filename:file list
        let topFileContent;
        let isInVFS = false;
        if (fileRef["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            topFileContent = fileRef["data"];
        }
        else if (fileRef["isInVFS"]) {
            topFileContent = fileRef["data"];
            isInVFS = true;
        }
        else {
            topFileContent = yield loadFileAsText(fileRef, system.name);
        }
        if (topFileContent === undefined)
            throw Error(`Topo file ${topFileName} could not be read.`);
        return {
            topFileName: topFileName,
            topFileContent: topFileContent,
            trajFileName: "",
            trajFileContent: "",
            isInVFS: isInVFS,
        };
    });
}
function loadTrajectoryFilePair(acceptedFiles, system) {
    return __awaiter(this, void 0, void 0, function* () {
        const trajFiles = system.files;
        /*================  ERROR HANDLING PART ========================*/
        const errorMsg = `${system.name}: Trajectories need two file types - trajectory (${supportedFiles.coordinates_trajectory.join(", ")}) and model (${[
            ...supportedFiles.topology,
            ...supportedFiles.coordinates_static,
        ].join(", ")}).`;
        if (!trajFiles || (trajFiles === null || trajFiles === void 0 ? void 0 : trajFiles.length) > 2)
            throw new Error(errorMsg);
        const trajFileName = trajFiles.find((f) => {
            const ext = f.split(".").pop();
            return ext && supportedFiles.coordinates_trajectory.includes(ext);
        });
        const topFileName = trajFiles.find((f) => {
            const ext = f.split(".").pop();
            return (ext &&
                (supportedFiles.topology.includes(ext) ||
                    supportedFiles.coordinates_static.includes(ext)));
        });
        if (!trajFileName || !topFileName)
            throw new Error(errorMsg);
        /*==============================================================*/
        const topFile = acceptedFiles[topFileName];
        const trajFile = acceptedFiles[trajFileName];
        let topFileContent;
        let isInVFS = false;
        if (topFile["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            topFileContent = topFile["data"];
        }
        else if (topFile["isInVFS"]) {
            topFileContent = topFile["data"];
            isInVFS = true;
        }
        else {
            topFileContent = yield loadFileAsText(topFile, system.name);
        }
        let trajFileContent;
        if (trajFile["isLoaded"]) {
            // to do - improve in the monorepo. These are files loaded through electron CLI. We already have the file content, no need to read it again. We need to extend the args interface to consider that these keys are present
            trajFileContent = trajFile["data"];
        }
        else if (trajFile["isInVFS"]) {
            trajFileContent = trajFile["data"];
            isInVFS = true;
        }
        else {
            trajFileContent = yield loadFileAsArrayBuffer(trajFile, system.name);
        }
        return {
            topFileContent: topFileContent,
            trajFileContent: trajFileContent,
            topFileName: topFileName,
            trajFileName: trajFileName,
            isInVFS: isInVFS,
        };
    });
}
// ----------------- UTILS FOR HANDLING FILE LIST --------------------//
function findConfigFile(acceptedFiles) {
    return Array.from(acceptedFiles).filter((myFile) => {
        const ext = myFile.name.split(".").pop();
        return ext && supportedFiles.instructions.includes(ext);
    });
}
function checkForMissingFiles(loadingInstructions, acceptedFilesObject) {
    let missingFiles = [];
    const { systems } = loadingInstructions;
    if (!systems)
        return missingFiles;
    systems.forEach((system) => {
        const missingFilesSystem = getMissingFilesRecursive(system, acceptedFilesObject);
        missingFiles = missingFiles.concat(missingFilesSystem);
    });
    return missingFiles;
}
function getMissingFilesRecursive(system, acceptedFilesObject) {
    var _a;
    let missingFiles = [];
    if (system.children) {
        system.children.forEach((s) => {
            const mf = getMissingFilesRecursive(s, acceptedFilesObject);
            missingFiles = missingFiles.concat(mf);
        });
    }
    else {
        const isRemote = (_a = system.files) === null || _a === void 0 ? void 0 : _a.every((f) => f.startsWith("dc://"));
        if (system.files && !isRemote) {
            system.files.forEach((f) => {
                if (!(f in acceptedFilesObject))
                    missingFiles.push(f);
            });
        }
    }
    return missingFiles;
}
// ---------------------- FILE READER WRAPPERS -----------------------//
/*
  TODO: loadFileAsText() and loadFileAsArrayBuffer() could be merged
  into a single function with a switch arg for text/buffer mode.
*/
function readConfigFile(fileContent) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(["File reading was aborted"]);
        reader.onerror = () => reject(["File reading has failed"]);
        reader.onload = () => {
            const stringContent = reader.result;
            try {
                const fileConfData = JSON.parse(stringContent);
                const validationResult = validateNAPfile(fileConfData);
                if (validationResult.isOk) {
                    resolve(fileConfData);
                    return;
                }
                if (validationResult.errors) {
                    reject(formatErrors(validationResult.errors));
                    return;
                }
            }
            catch (_a) {
                reject(["Wrong format."]);
            }
        };
        reader.readAsText(fileContent);
    });
}
export function loadFileAsText(fileContent, systemName) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(`File reading was aborted for ${systemName}`);
        reader.onerror = () => reject(`File reading has failed for ${systemName}`);
        reader.onload = () => {
            // Do whatever you want with the file contents
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
        reader.readAsText(fileContent);
    });
}
export function loadFileAsArrayBuffer(fileContent, systemName) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(`File reading was aborted for ${systemName}`);
        reader.onerror = () => reject(`File reading has failed for ${systemName}`);
        reader.onload = () => {
            // Do whatever you want with the file contents
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
        reader.readAsArrayBuffer(fileContent);
    });
}
// -------------------- ERROR MESSAGE FORMATTING ---------------------//
function formatErrors(validationErrors) {
    const errorList = [];
    validationErrors.forEach((err) => {
        const valid_types = Array.isArray(err.schema)
            ? `(${err.schema.join(", ")})`
            : "";
        const error_string = `'${err.currentValue}' in ${err.path} ${err.errMessage} ${valid_types}`;
        errorList.push(error_string);
    });
    return errorList;
}
export function displayNotSupportedError(unsupportedFiles) {
    const extUnique = [];
    unsupportedFiles.forEach((fname) => {
        const ext = fname.split(".").pop();
        if (ext && !extUnique.includes(ext)) {
            extUnique.push(ext);
        }
    });
    const errorMsg = `The extension${unsupportedFiles.length > 1 ? "s" : ""} ${extUnique.join(", ")} ${unsupportedFiles.length > 1 ? "are" : "is"} not supported. The following files were skipped: ${unsupportedFiles.join(", ")}`;
    dispatchNotificationEvent({ message: errorMsg, type: "error" });
}
export function displayUnmatchedError(unmatchedFiles) {
    const topFiles = unmatchedFiles.filter((e) => {
        const ext = e.split(".").pop();
        return ext && supportedFiles.topology.includes(ext);
    });
    const trajFiles = unmatchedFiles.filter((e) => {
        const ext = e.split(".").pop();
        return ext && supportedFiles.coordinates_trajectory.includes(ext);
    });
    if (topFiles.length > 0 && trajFiles.length > 0)
        dispatchNotificationEvent({
            message: `The following trajectory and topology files could not be matched, please load only one file of each type: ${unmatchedFiles.join(", ")}`,
            type: "error",
        });
    else if (topFiles.length > 0)
        dispatchNotificationEvent({
            message: `Topology file${topFiles.length > 1 ? "s" : ""} loaded without corresponding trajectory file: ${topFiles.join(", ")}. Please load a topology and trajectory file pair.`,
            type: "error",
        });
    else
        dispatchNotificationEvent({
            message: `Trajectory file${trajFiles.length > 1 ? "s" : ""} loaded without corresponding topology file: ${trajFiles.join(", ")}. Please load a topology and trajectory file pair.`,
            type: "error",
        });
}
