var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Task } from "molstar/lib/mol-task";
import { PluginStateObject, PluginStateTransform, } from "molstar/lib/mol-plugin-state/objects";
import { Structure, Unit, } from "molstar/lib/mol-model/structure";
import { ParamDefinition as PD } from "molstar/lib/mol-util/param-definition";
import { FileType, TrajectoryModeEnum, } from "../../utils";
import { addRepresentation } from "../showFile";
import produce from "immer";
import { findSystemByKey, updateStateTreeTrajectory } from "../stateTree";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import { removeRepresentations } from "../RepresentationControls/removeRepresentations";
const StructureFromTrajectorySubset = PluginStateTransform.BuiltIn({
    name: "structure-from-trajectory-subset",
    display: {
        name: "Structure from Trajectory Subset",
        description: "Create a molecular structure from a trajectory, including only a subset of frames.",
    },
    from: PluginStateObject.Molecule.Trajectory,
    to: PluginStateObject.Molecule.Structure,
    params: {
        from: PD.Numeric(0),
        to: PD.Numeric(0),
        step: PD.Numeric(0),
    },
})({
    /* apply() is called when you call data.commit() */
    apply({ a /* this one gets the type in the "from" field above */, params }) {
        return Task.create("Build Structure", (ctx) => __awaiter(this, void 0, void 0, function* () {
            const s = yield ofTrajectorySelection(a.data, ctx, params);
            const props = {
                label: "Ensemble",
                description: Structure.elementDescription(s),
            };
            return new PluginStateObject.Molecule.Structure(s, props);
        }));
    },
});
function ofTrajectorySelection(trajectory, ctx, params) {
    return __awaiter(this, void 0, void 0, function* () {
        if (trajectory.frameCount === 0)
            return Structure.Empty;
        if (params.from < 0 ||
            params.to >= trajectory.frameCount ||
            params.from > params.to) {
            return Structure.Empty;
        }
        const units = [];
        let first = void 0;
        let count = 0;
        for (let i = params.from, il = params.to; i <= il; i += params.step) {
            const frame = yield Task.resolveInContext(trajectory.getFrameAtIndex(i), ctx);
            if (!first)
                first = frame;
            const structure = Structure.ofModel(frame);
            for (let j = 0, jl = structure.units.length; j < jl; ++j) {
                const u = structure.units[j];
                const invariantId = u.invariantId + count;
                const chainGroupId = u.chainGroupId + count;
                const newUnit = Unit.create(units.length, invariantId, chainGroupId, u.traits, u.kind, u.model, u.conformation.operator, u.elements);
                units.push(newUnit);
            }
            count = units.length;
        }
        return Structure.create(units, {
            representativeModel: first,
            label: first.label,
        });
    });
}
export function showTrajectoryAsFrameEnsemble(system, trajCellRef, from, to, step, molstar, pyodide, vss) {
    var _a, e_1, _b, _c;
    var _d;
    return __awaiter(this, void 0, void 0, function* () {
        const ensembleCellRefs = yield displayMultipleFrames(trajCellRef, from, to, step, molstar);
        // Remove previous ensemble if present
        const currentCellRef = system.cellRef;
        if (currentCellRef && currentCellRef.length > 0) {
            const model = molstar.state.data.selectQ((q) => q.byRef(currentCellRef[0]));
            if (model) {
                try {
                    for (var _e = true, model_1 = __asyncValues(model), model_1_1; model_1_1 = yield model_1.next(), _a = model_1_1.done, !_a;) {
                        _c = model_1_1.value;
                        _e = false;
                        try {
                            const cell = _c;
                            if (((_d = cell.obj) === null || _d === void 0 ? void 0 : _d.label) !== "Ensemble")
                                continue;
                            yield PluginCommands.State.RemoveObject(molstar, {
                                state: molstar.state.data,
                                ref: cell.transform.ref,
                            });
                        }
                        finally {
                            _e = true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (!_e && !_a && (_b = model_1.return)) yield _b.call(model_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        let addedReps = undefined;
        if (system.representations) {
            // remove old representations
            // create new representations
            system.cellRef = ensembleCellRefs;
            addedReps = yield addRepresentation(molstar, pyodide, 
            // system,
            system.type == FileType.sdf, system.moleculeID, system.type == FileType.sdf, undefined, system.cellRef, system.visibility, system.representations, system.aromaticBonds);
            yield removeRepresentations(system.representations, molstar);
        }
        // update tree
        const loaded_structures = vss.getState().loaded_structures;
        const updatedStructureTree = yield produce(loaded_structures, (draft) => {
            const _system = findSystemByKey(draft, "moleculeID", system.moleculeID);
            if (_system) {
                _system.cellRef = ensembleCellRefs;
                _system.representations = addedReps;
                _system.trajectoryParameters = Object.assign(Object.assign({}, _system.trajectoryParameters), { mode: {
                        name: TrajectoryModeEnum.multipleFrames,
                        options: {
                            from,
                            to,
                            step,
                        },
                    } });
            }
        });
        vss.getState().set_loaded_structures(updatedStructureTree);
    });
}
export function displayMultipleFrames(trajCellRef, from, to, step, molstar) {
    return __awaiter(this, void 0, void 0, function* () {
        const ensemble = yield molstar
            .build()
            .to(trajCellRef)
            .apply(StructureFromTrajectorySubset, {
            from: from - 1,
            to: to - 1,
            step: step,
        })
            .commit();
        const ensembleCellRefs = Array.isArray(ensemble)
            ? ensemble.map((s) => s.ref)
            : [ensemble.ref];
        return ensembleCellRefs;
    });
}
const showTrajectoryAsPlay = (
// system: System,
trajCellRef, system, 
// from: number,
molstar, pyodide, vss) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, e_2, _b, _c;
    // remove embed
    const ensembleCellRef = system.cellRef;
    if (ensembleCellRef) {
        try {
            for (var _d = true, ensembleCellRef_1 = __asyncValues(ensembleCellRef), ensembleCellRef_1_1; ensembleCellRef_1_1 = yield ensembleCellRef_1.next(), _a = ensembleCellRef_1_1.done, !_a;) {
                _c = ensembleCellRef_1_1.value;
                _d = false;
                try {
                    const ref = _c;
                    yield PluginCommands.State.RemoveObject(molstar, {
                        state: molstar.state.data,
                        ref: ref,
                    });
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = ensembleCellRef_1.return)) yield _b.call(ensembleCellRef_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    //recover trajectory cell
    const cellArr = molstar.state.data.selectQ((q) => q
        .byRef(trajCellRef)
        .subtree()
        .ofType(PluginStateObject.Molecule.Structure)
        .first());
    if (cellArr.length === 0) {
        console.error("Could not recover trajectory.");
        return;
    }
    const cellRef = cellArr[0].transform.ref;
    // Add represetations
    let addedReps = undefined;
    if (system.representations) {
        // create new representations
        system.cellRef = [cellRef];
        addedReps = yield addRepresentation(molstar, pyodide, 
        // system,
        system.type == FileType.sdf, system.moleculeID, system.type == FileType.sdf, undefined, system.cellRef, system.visibility, system.representations, system.aromaticBonds);
    }
    // update tree
    const loaded_structures = vss.getState().loaded_structures;
    const updatedStructureTree = yield produce(loaded_structures, (draft) => {
        const _system = findSystemByKey(draft, "moleculeID", system.moleculeID);
        if (_system) {
            _system.cellRef = [cellRef];
            _system.representations = addedReps;
            _system.trajectoryParameters = Object.assign(Object.assign({}, _system.trajectoryParameters), { mode: {
                    name: TrajectoryModeEnum.currentFrame,
                } });
        }
    });
    vss.getState().set_loaded_structures(updatedStructureTree);
});
export const handleSelectFrameMode = (mode, positionInTree, vss, trajCellRef, system, molstar, pyodide, currFrame) => {
    const newMode = { name: mode };
    if (mode === TrajectoryModeEnum.multipleFrames) {
        // no need to update the molstar objects here, it's done authomatically
        newMode["options"] = {
            from: currFrame ? currFrame : 1,
            to: currFrame ? currFrame : 1,
            step: 1,
        };
        updateStateTreeTrajectory(vss, positionInTree, "mode", newMode);
    }
    else {
        showTrajectoryAsPlay(trajCellRef, system, molstar, pyodide, vss);
    }
};
