import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button } from "@mui/material/";
export function TestButton({ content }) {
    return (_jsx(Button, Object.assign({ onClick: () => console.log("!!"), variant: "text" }, { children: content })));
}
export default TestButton;
