import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Plots } from "../Plots/DataVizPlots/Plots";
import { CSVtables } from "../Tables/CSVtables";
import { NAPPlotStore } from "../StateStores/plots.store";
import { NAPTableStore } from "../StateStores/tables.store";
import { DatavizControlOptions, DatavizElements, } from "./interfaces";
import { DataVizTabs } from "./DataVizTabs";
import { shallow } from "zustand/shallow";
import { deleteAllDatavizElementIntances } from "./utils";
function updateTabs(changedTabName, numElementsInTab, availableTabs, setActiveTab, setAvailableTabs) {
    const tableTab = availableTabs[changedTabName];
    if (numElementsInTab > tableTab.num) {
        setActiveTab(changedTabName);
    }
    else if (numElementsInTab === 0) {
        let newActiveTab = undefined;
        for (let tabName in availableTabs) {
            if (tabName === changedTabName)
                continue;
            if (availableTabs[tabName].num > 0) {
                newActiveTab = tabName;
                break;
            }
        }
        setActiveTab(newActiveTab);
    }
    const newAvailableTabs = produce(availableTabs, (draft) => {
        draft[changedTabName].num = numElementsInTab;
    });
    setAvailableTabs(newAvailableTabs);
    return newAvailableTabs;
}
export function TablePlotsTabs({ vss, molstar, pyodide, NAPGenericStore, skipButtons, fileSelectorAsTabs, }) {
    const { tables } = NAPTableStore((state) => ({
        tables: state.tables,
    }), shallow);
    const { plots } = NAPPlotStore((state) => ({
        plots: state.plots,
    }), shallow);
    const dataVizTabs = {
        Tables: {
            num: 0,
        },
        Plots: {
            num: 0,
        },
    };
    const [activeTab, setActiveTab] = useState(undefined);
    const [availableTabs, setAvailableTabs] = useState(dataVizTabs);
    const numTables = tables.filter((t) => !t.archived).length;
    const numPlots = plots.filter((p) => !p.archived).length;
    useEffect(() => {
        const _newAvailableTabs = updateTabs(DatavizElements.Tables, numTables, availableTabs, setActiveTab, setAvailableTabs);
        updateTabs(DatavizElements.Plots, numPlots, _newAvailableTabs, setActiveTab, setAvailableTabs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numTables, numPlots]);
    let activeComp;
    switch (activeTab) {
        case DatavizElements.Tables:
            const skipButtonsTable = numPlots > 0 && numTables === 1
                ? skipButtons
                    ? [...skipButtons, DatavizControlOptions.delete]
                    : [DatavizControlOptions.delete]
                : skipButtons;
            activeComp = (_jsx(CSVtables, { NAPGenericStore: NAPGenericStore, vss: vss, molstar: molstar, pyodide: pyodide, skipButtons: skipButtonsTable, fileSelectorAsTabs: fileSelectorAsTabs }));
            break;
        case DatavizElements.Plots:
            const skipButtonsPlot = numPlots === 1 && numTables > 0
                ? skipButtons
                    ? [...skipButtons, DatavizControlOptions.delete]
                    : [DatavizControlOptions.delete]
                : skipButtons;
            activeComp = (_jsx(Plots, { NAPGenericStore: NAPGenericStore, vss: vss, molstar: molstar, pyodide: pyodide, skipButtons: skipButtonsPlot }));
            break;
        default:
            activeComp = _jsx(_Fragment, {});
            break;
    }
    const tabDetails = Object.entries(availableTabs)
        .filter(([tabName, tabData]) => tabData.num > 0)
        .map(([tabName, tabData]) => {
        return {
            name: tabName,
            onClick: () => setActiveTab(tabName),
            onDeleteAll: () => {
                deleteAllDatavizElementIntances(tabName, numTables, numPlots);
            },
        };
    })
        .sort((a, b) => a.name.localeCompare(b.name));
    const _setActiveTab = (x) => {
        setActiveTab(x);
    };
    return (_jsxs(_Fragment, { children: [activeTab && numTables > 0 && numPlots > 0 ? (_jsx(Grid, Object.assign({ item: true, xs: 12, sx: { borderBottom: 1, borderColor: "divider", mb: 1 } }, { children: _jsx(DataVizTabs, { tabs: tabDetails, activeTab: activeTab, callback: _setActiveTab }) }))) : null, activeComp] }));
}
