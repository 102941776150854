import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckMark from "@mui/icons-material/Check";
export function ConfirmationListener() {
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const [res, setRes] = useState("");
    const [noCancelButton, setNoCancelButton] = useState(false);
    useEffect(() => {
        const _cb = (e) => {
            const { message, resolve, noCancelButton } = e.detail;
            setMsg(message);
            setOpen(true);
            setRes(() => resolve);
            setNoCancelButton(!!noCancelButton);
        };
        window.addEventListener("PM-Confirmation-Dialog", _cb);
        return () => {
            window.removeEventListener("PM-Confirmation-Dialog", _cb);
        };
    }, []);
    const handleClose = () => {
        setOpen(false);
        setNoCancelButton(false);
        if (res)
            res(false);
    };
    const handleAccept = () => {
        setOpen(false);
        setNoCancelButton(false);
        if (res)
            res(true);
    };
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, sx: { minWidth: 800 } }, { children: [_jsx(DialogContent, { children: msg }), _jsxs(DialogActions, { children: [!noCancelButton && (_jsx(IconButton, Object.assign({ color: "primary", onClick: handleClose }, { children: _jsx(CloseIcon, {}) }))), _jsx(IconButton, Object.assign({ color: "primary", onClick: handleAccept, autoFocus: true, onKeyDown: (e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAccept();
                            }
                        } }, { children: _jsx(CheckMark, {}) }))] })] })));
}
