import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Collapse, Fade, IconButton, Paper, Tooltip } from "@mui/material";
import { rightPanel } from "../../UIPanels/panelDimensions";
import { shallow } from "zustand/shallow";
import { NAPTableStore } from "../../StateStores";
import { showInExponentialNotation, unArchiveTableSystems } from "../utils";
import { PreviousNextButtons } from "../../3dViewer/Controls/PreviousNextButtons";
import { dispatchTableNavigationSetEvent, dispatchTableNavigationStepEvent, } from "./TableNavigation";
import CloseIcon from "@mui/icons-material/Close";
export function NavigationTableData({ NAPGenericStore, vss, }) {
    var _a, _b;
    const [isFullscreen, activePanels, topPanelHeight, loadingDCFile] = NAPGenericStore((state) => [
        state.isFullscreen,
        state._activePanels,
        state.topPanelHeight,
        state.loadingDCFile,
    ], shallow);
    const [tables, activeTableIdx, navigationIdx, navigationMode, setNavigationMode,] = NAPTableStore((state) => [
        state.tables,
        state.activeTableIdx,
        state.navigationIdx,
        state.navigationMode,
        state.setNavigationMode,
    ]);
    const activeTable = tables[activeTableIdx];
    const numRows = activeTable ? activeTable.rows.length : 0;
    const activeRow = navigationMode && activeTable ? activeTable.rows[navigationIdx] : undefined;
    const highlightColumns = ((_a = activeTable === null || activeTable === void 0 ? void 0 : activeTable.options) === null || _a === void 0 ? void 0 : _a.highlightColumns)
        ? (_b = activeTable === null || activeTable === void 0 ? void 0 : activeTable.options) === null || _b === void 0 ? void 0 : _b.highlightColumns
        : [];
    let highlightData = [];
    if (activeRow) {
        highlightColumns.forEach((colName) => {
            const cellData = activeRow[colName];
            if (cellData)
                highlightData[colName] = cellData;
        });
    }
    const tableIsOpen = activePanels.plots;
    const rightPos = `calc(${tableIsOpen ? rightPanel.width : "0vw"} + 3.45rem)`;
    const hidden = (tableIsOpen && isFullscreen) || Object.keys(highlightData).length === 0;
    const handleDisableNavigationMode = () => {
        setNavigationMode(false);
        unArchiveTableSystems(vss);
    };
    return (_jsx(Fade, Object.assign({ in: !hidden }, { children: _jsxs(Paper, Object.assign({ variant: "outlined", sx: {
                position: "fixed",
                right: rightPos,
                top: `${topPanelHeight + 10}px`,
                // backgroundColor: "white",
                zIndex: 1,
                p: 1,
                backgroundColor: "rgb(255, 255, 255, 0.8)",
            } }, { children: [_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Box, { children: Object.entries(highlightData).map(([key, val]) => (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1 }, { children: [_jsxs(Box, { children: [key, ":"] }), _jsx(Box, Object.assign({ sx: { fontWeight: "bold" } }, { children: showInExponentialNotation(val) }))] }), `navigate-table-data-${key}`))) }), _jsx(ExitNavigationButton, { isOpen: !tableIsOpen, handleClick: handleDisableNavigationMode })] })), _jsx(NavigationButtons, { isWaiting: loadingDCFile, isOpen: !tableIsOpen, numRows: numRows, navigationIdx: navigationIdx })] })) })));
}
function ExitNavigationButton({ isOpen, handleClick, }) {
    return (_jsx(Collapse, Object.assign({ orientation: "horizontal", in: isOpen }, { children: _jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(Tooltip, Object.assign({ title: "Exit navigation mode" }, { children: _jsx(IconButton, Object.assign({ sx: { color: "rgba(0, 0, 0, 0.54)", mb: "5px" }, size: "small", color: "primary", onClick: handleClick }, { children: _jsx(CloseIcon, { fontSize: "small" }) })) })) })) })));
}
function NavigationButtons({ isOpen, numRows, navigationIdx, isWaiting, }) {
    return (_jsx(Collapse, Object.assign({ orientation: "vertical", in: isOpen }, { children: _jsx(PreviousNextButtons, { handleClickFirst: () => dispatchTableNavigationSetEvent({ value: 0 }), handleClickPrevious: () => dispatchTableNavigationStepEvent({ step: -1 }), handleClickNext: () => dispatchTableNavigationStepEvent({ step: 1 }), handleClickLast: () => {
                dispatchTableNavigationSetEvent({ value: numRows - 1 });
            }, firstIsDisabled: isWaiting || navigationIdx === 0, previousIsDisabled: isWaiting || navigationIdx === 0, nextIsDisabled: isWaiting || navigationIdx === numRows - 1, lastIsDisabled: isWaiting || navigationIdx === numRows - 1, size: "small", value: navigationIdx }) })));
}
