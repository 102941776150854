var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid } from "@mui/material";
import { StateObjectRef } from "molstar/lib/mol-state";
import { useState } from "react";
import { defaultColor, defaultType, getRepresentationTypeOptions, } from "../RepresentationControls/representationParamOptions";
import { SelectionField } from "../RepresentationControls/SelectRepresentationParam";
import { RepresentationColorSelector } from "../RepresentationControls/RepresentationColorSelector";
import { OpacitySlider } from "../RepresentationControls/OpacitySlider";
import { StructureComponentManager } from "molstar/lib/mol-plugin-state/manager/structure/component";
import { ParamDefinition } from "molstar/lib/mol-util/param-definition";
import { findSystemByKey } from "../stateTree";
import { FileType, } from "../../utils";
import { generateRepName } from "../RepresentationControls/AtomSelectionUtils";
import { selectionRefToIndexes } from "./utils";
import { removeRepresentations } from "../RepresentationControls/removeRepresentations";
import { addRepresentation } from "../showFile";
import { defineColorParams } from "../RepresentationControls/utils";
import { RepresentationOptionLabel } from "../RepresentationControls/RepresentationOptionLabel";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
function addSelectionRepresentationToStructure(type, alpha, colorScheme, colorValue, carbonColorValue, selection, structRef, loaded_systems, molstar, vss, pyodide) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const cameraSnapshot = (_a = molstar.canvas3d) === null || _a === void 0 ? void 0 : _a.camera.getSnapshot();
        const structCellRef = structRef.cell.transform.ref;
        const currSys = findSystemByKey(loaded_systems, "cellRef", structCellRef, true);
        // Create new representation
        const aromaticBonds = currSys ? !!currSys.aromaticBonds : false;
        const structureCell = StateObjectRef.resolveAndCheck(molstar.state.data, structCellRef);
        if (!structureCell)
            return;
        const repName = generateRepName(molstar, structCellRef);
        const component = yield molstar.builders.structure.tryCreateComponentFromSelection(structureCell, selection, repName);
        if (!component)
            return;
        const representation = yield molstar.builders.structure.representation.addRepresentation(component, {
            type: type,
            typeParams: {
                alpha: alpha,
                aromaticBonds: aromaticBonds,
                multipleBonds: "symmetric",
            },
            color: colorScheme,
            colorParams: defineColorParams(colorScheme, colorValue, carbonColorValue),
        });
        const selIdxs = selectionRefToIndexes(structRef, molstar);
        const selStr = selIdxs && selIdxs.length > 0 ? `index ${selIdxs}` : "";
        const stateTreePos = currSys === null || currSys === void 0 ? void 0 : currSys.stateTreePosition;
        const systCellRef = (currSys === null || currSys === void 0 ? void 0 : currSys.cellRef) ? currSys === null || currSys === void 0 ? void 0 : currSys.cellRef : [structCellRef];
        const refs = systCellRef.map((cellRef) => {
            return {
                cellRef: cellRef,
                repRef: cellRef === structCellRef ? representation.ref : undefined,
            };
        });
        const repParamsDat = {
            selection: selStr,
            alpha: alpha,
            color: colorScheme,
            color_value: colorValue,
            carbon_color_value: carbonColorValue,
            type: type,
            visibility: true,
            aromaticBonds: aromaticBonds,
            refs: refs,
        };
        // Remove previous representations and add them again after the new one
        let updatedPrevReps = [];
        if (currSys && currSys.representations) {
            const previousReps = currSys.representations;
            if (previousReps.length > 0) {
                yield removeRepresentations(previousReps, molstar);
                updatedPrevReps = yield addRepresentation(molstar, pyodide, currSys.type == FileType.sdf, currSys.moleculeID, currSys.type == FileType.sdf, undefined, currSys.cellRef, currSys.visibility, previousReps, currSys.aromaticBonds);
            }
            if (cameraSnapshot)
                (_b = molstar.canvas3d) === null || _b === void 0 ? void 0 : _b.camera.setState(cameraSnapshot);
        }
        const allRepParams = [repParamsDat, ...updatedPrevReps];
        if (stateTreePos) {
            vss
                .getState()
                .updateSystemParameters(stateTreePos, { representations: allRepParams });
            //add_representation(stateTreePos, repParamsDat);
        }
        return representation.ref;
    });
}
export function CreateRepresentationFromSelection({ closeMenu, molstar, vss, pyodide, }) {
    const [type, setType] = useState(defaultType);
    const [colorScheme, setColorScheme] = useState(defaultColor);
    const [colorValue, setColorValue] = useState(13421772);
    const [carbonColorValue, setCarbonColorValue] = useState(undefined);
    const [alpha, setAlpha] = useState(1);
    const [errorQuery, setErrorQuery] = useState("");
    const createRepr = () => __awaiter(this, void 0, void 0, function* () {
        var _a, e_1, _b, _c;
        const loaded_systems = vss.getState().loaded_structures;
        const params = StructureComponentManager.getAddParams(molstar);
        const values = ParamDefinition.getDefaultValues(params);
        const currentStructures = molstar.managers.structure.hierarchy.current.structures;
        let refs = [];
        try {
            try {
                for (var _d = true, currentStructures_1 = __asyncValues(currentStructures), currentStructures_1_1; currentStructures_1_1 = yield currentStructures_1.next(), _a = currentStructures_1_1.done, !_a;) {
                    _c = currentStructures_1_1.value;
                    _d = false;
                    try {
                        const struct = _c;
                        const structCellRef = struct.cell.transform.ref;
                        const repRef = yield addSelectionRepresentationToStructure(type, alpha, colorScheme, colorValue, carbonColorValue, values.selection, struct, loaded_systems, molstar, vss, pyodide);
                        if (repRef)
                            refs.push({ cellRef: structCellRef, repRef: repRef });
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = currentStructures_1.return)) yield _b.call(currentStructures_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            // setVisibilityForSDF(molstar, activeSystem?.visibleElementIDs, refs);
        }
        catch (e) {
            console.error(e);
        }
        closeMenu();
    });
    const onColorPickerUpdate = (newColor, colorParamKey) => {
        // setColorValue(e)
        if (colorParamKey === "carbon_color_value")
            setCarbonColorValue(newColor);
        else {
            setColorValue(newColor ? newColor : 13421772);
        }
    };
    return (_jsx(Box, Object.assign({ sx: { padding: "11px", pt: 0 } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", alignItems: "flex-start", justifyContent: "center", spacing: 0 }, { children: [_jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(SelectionField, { currValue: type, menuLabel: _jsx(RepresentationOptionLabel, { isLabel: true, icon: _jsx(AutoAwesomeIcon, {}), text: "Representation Type" }), options: getRepresentationTypeOptions(false), callback: (e) => {
                            setType(e.target.value);
                        } }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(RepresentationColorSelector, { onSchemeSelection: (e) => {
                            setColorScheme(e);
                        }, onColorPickerUpdate: onColorPickerUpdate, currColorScheme: colorScheme, currColorVal: colorValue, currCarbonColorVal: carbonColorValue }) })), _jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(OpacitySlider, { currVal: alpha, onUpdate: (e) => {
                            setAlpha(e);
                        } }) })), _jsxs(Box, Object.assign({ display: "flex", sx: { paddingInline: 1, width: "100%" }, justifyContent: "flex-end" }, { children: [_jsx(Button, Object.assign({ sx: { marginRight: 2 }, variant: "outlined", color: "secondary", onClick: () => {
                                closeMenu();
                            }, size: "small" }, { children: "cancel" })), _jsx(Button, Object.assign({ variant: "contained", onClick: createRepr, size: "small" }, { children: "ok" }))] }))] })) })));
}
