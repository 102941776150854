import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Fade, Radio } from "@mui/material";
import { selectSystemByIndexArray } from "./stateTree";
export function SystemRadioButton({ vss, pyodide, NAPGenericStore, boxKey, name, }) {
    const [activeSystemRadio, setActiveSystemRadio, filterRadio, systemRadioSelector,] = NAPGenericStore((state) => [
        state._activeSystemRadio,
        state.setActiveSystemRadio,
        state._filterRadio,
        state._systemRadioSelector,
    ], shallow);
    const handleChange = (event) => {
        const treePos = event.target.value.split("-").map((e) => Number(e));
        const s = vss.getState().loaded_structures;
        const system = selectSystemByIndexArray(treePos, s);
        const molID = system.moleculeID;
        pyodide
            .RunPythonAsync({
            context: { molID },
            script: `
      from js import molID
      getMoleculeAttr(molID, "topoloc")
      `,
        })
            .then((molFile) => {
            systemRadioSelector({ name: name, file: molFile });
            setActiveSystemRadio("");
        });
    };
    const isDisabled = filterRadio ? !filterRadio(name) : false;
    return (_jsx(Fade, Object.assign({ in: activeSystemRadio ? true : false, unmountOnExit: true }, { children: _jsx(Radio, { checked: activeSystemRadio === boxKey, onChange: handleChange, value: boxKey, name: "systems-radio-buttons", size: "small", disabled: isDisabled }) })));
}
