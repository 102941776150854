// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { displayMultipleFrames } from "../3dViewer/TrajectoryControls/displayMultipleFrames";
import { getTrajectoryRef } from "../3dViewer/TrajectoryControls/utils";
export function loadTrajShowingMultipleFrames(cellRef, molstar, framesSettings) {
    return __awaiter(this, void 0, void 0, function* () {
        const trajectoryRef = getTrajectoryRef(molstar, cellRef);
        if (!trajectoryRef)
            return;
        const ensembleCellRefs = yield displayMultipleFrames(trajectoryRef, (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.from) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.from : 1, (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.to) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.to : 1, (framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.step) ? framesSettings === null || framesSettings === void 0 ? void 0 : framesSettings.step : 1, molstar);
        return ensembleCellRefs;
    });
}
