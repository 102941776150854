import { StructureSelection } from "molstar/lib/mol-model/structure";
import { Script } from "molstar/lib/mol-script/script";
import { StateObjectRef } from "molstar/lib/mol-state";
export const lociFromSelection = (structureData, resId, chain) => {
    const sel = Script.getStructureSelection((Q) => {
        const query = {
            "entity-test": Q.core.rel.eq([Q.ammp("entityType"), "polymer"]),
        };
        if (resId !== undefined) {
            if (typeof resId === "number")
                query["residue-test"] = Q.core.rel.eq([
                    Q.struct.atomProperty.macromolecular.label_seq_id(),
                    resId,
                ]);
            else {
                query["residue-test"] = Q.core.set.has([
                    Q.set(...resId),
                    Q.ammp("auth_seq_id"),
                ]);
                query["group-by"] = Q.struct.atomProperty.macromolecular.residueKey();
            }
        }
        if (chain !== undefined) {
            query["chain-test"] = Q.core.rel.eq([
                Q.struct.atomProperty.macromolecular.auth_asym_id(),
                chain,
            ]);
        }
        return Q.struct.generator.atomGroups(query);
    }, structureData);
    const loci = StructureSelection.toLociWithSourceUnits(sel);
    return loci;
};
export function getStructureObjects(cellRef, molstar) {
    var _a, _b, _c;
    const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
    // I need to know the chain of each sequence segment. This is over-complicated, I didn't find a better way to do it yet. Try to improve when possible.
    const structureData = (_a = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.obj) === null || _a === void 0 ? void 0 : _a.data;
    const structureModels = (_b = structureData === null || structureData === void 0 ? void 0 : structureData.state) === null || _b === void 0 ? void 0 : _b.models;
    const structureModel = structureModels && structureModels.length > 0
        ? structureModels[0]
        : undefined;
    const structureChainData = (_c = structureModel === null || structureModel === void 0 ? void 0 : structureModel.properties) === null || _c === void 0 ? void 0 : _c.structAsymMap;
    const strucChains = structureChainData === undefined
        ? []
        : [
            ...new Set(Array.from(structureChainData.values()).map((entry) => entry["auth_id"])),
        ];
    const entryToChain = Object.fromEntries(strucChains.map((chain, i) => [i, chain]));
    const structSeq = structureModel === null || structureModel === void 0 ? void 0 : structureModel.sequence.sequences;
    const seqData = structSeq === undefined
        ? []
        : structSeq.map((seq, i) => {
            return {
                // entityId: seq.entityId,
                chain: entryToChain[i],
                kind: seq.sequence.kind,
                seqIds: seq.sequence.seqId.__array,
                labels: seq.sequence.label.__array,
            };
        });
    return { structureData, seqData };
}
export function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}
export const posIsHighlighted = (mouseDownPosId, lastMouseOverPosId, positionId) => {
    if (mouseDownPosId === undefined || lastMouseOverPosId === undefined)
        return false;
    if (mouseDownPosId < lastMouseOverPosId &&
        mouseDownPosId <= positionId &&
        positionId <= lastMouseOverPosId)
        return true;
    if (mouseDownPosId > lastMouseOverPosId &&
        mouseDownPosId >= positionId &&
        positionId >= lastMouseOverPosId)
        return true;
    return false;
};
