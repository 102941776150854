import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Button, ButtonGroup, Grow, Paper, Popper, ClickAwayListener, MenuList, MenuItem, ListItemIcon, ListItemText, } from "@mui/material";
import { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircularProgress from "@mui/material/CircularProgress";
function MenuInput({ children, handleChange, }) {
    return (_jsxs("div", { children: [_jsx("input", { type: "file", hidden: true, onChange: handleChange, id: "raised-button-file" }), _jsx("label", Object.assign({ htmlFor: "raised-button-file" }, { children: children }))] }));
}
function PopperMenuItem({ option, index, selectedOptionIdx, handleClick, }) {
    return (_jsxs(MenuItem, Object.assign({ selected: index === selectedOptionIdx, onClick: handleClick }, { children: [option.icon ? _jsx(ListItemIcon, { children: option.icon }) : null, _jsx(ListItemText, { children: option.label })] }), option.key));
}
export function SplitButton({ onClick, options, isLoading, selectedOptionIdx, handleOptionIdxChange, disabled, variant, poperPlacement, }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const selectedIsInput = options[selectedOptionIdx].isInput;
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleButtonClick = () => {
        if (!selectedIsInput) {
            onClick(options[selectedOptionIdx]["key"]);
        }
    };
    const handleMenuItemClick = (index) => {
        handleOptionIdxChange(index);
        setOpen(false);
    };
    const inputHandler = (e, optionKey) => {
        const target = e.target;
        const loadedFiles = target.files;
        const loadedFile = loadedFiles[0];
        // onClick(options[selectedOptionIdx]["key"], loadedFile);
        onClick(optionKey, loadedFile);
    };
    const buttonStyle = selectedIsInput
        ? {
            borderRadius: "4px 0 0 4px",
            borderRight: 0,
            "&:hover": { borderRight: 0 },
            px: 1.2,
        }
        : { px: 1.2 };
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, Object.assign({ variant: variant, ref: anchorRef, "aria-label": "split button", sx: { "& .MuiButtonGroup-grouped": { minWidth: "0" } }, disabled: disabled || isLoading }, { children: [_jsxs(Button, Object.assign({ onClick: handleButtonClick, component: selectedIsInput ? "label" : "button", sx: buttonStyle, endIcon: isLoading ? (_jsx(CircularProgress, { size: 20 })) : options[selectedOptionIdx].icon ? (options[selectedOptionIdx].icon) : undefined }, { children: ["Select", selectedIsInput ? (_jsx("input", { type: "file", hidden: true, onChange: (e) => inputHandler(e, options[selectedOptionIdx]["key"]) })) : null] })), _jsx(Button, Object.assign({ size: "small", "aria-controls": open ? "split-button-menu" : undefined, "aria-expanded": open ? "true" : undefined, "aria-haspopup": "menu", onClick: handleToggle, sx: {
                            width: "1em",
                            borderRadius: " 0 4px 4px 0",
                        } }, { children: _jsx(ArrowDropDownIcon, {}) }))] })), _jsx(Popper, Object.assign({ sx: {
                    zIndex: 2,
                }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, placement: poperPlacement }, { children: ({ TransitionProps, placement }) => (_jsx(Grow, Object.assign({}, TransitionProps, { style: {
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    } }, { children: _jsx(Paper, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: handleClose }, { children: _jsx(MenuList, Object.assign({ id: "split-button-menu", autoFocusItem: true }, { children: options.map((option, index) => option.isInput ? (_jsx(MenuInput, Object.assign({ handleChange: (e) => {
                                        handleMenuItemClick(index);
                                        inputHandler(e, option.key);
                                    } }, { children: _jsx(PopperMenuItem, { option: option, index: index, selectedOptionIdx: selectedOptionIdx, handleClick: () => { } }) }), `split-btn-${index}`)) : (_jsx(PopperMenuItem, { option: option, index: index, selectedOptionIdx: selectedOptionIdx, handleClick: () => {
                                        handleMenuItemClick(index);
                                        onClick(option.key);
                                    } }, `split-btn-${index}`))) })) })) }) }))) }))] }));
}
