var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Button, CircularProgress, DialogActions, DialogContentText, Fade, FormHelperText, Grid, } from "@mui/material";
import { dispatchConfirmationDialogEvent } from "../../utils";
import { MailCodeVerificationForm } from "../MailCodeVerification/MailCodeVerificationForm";
import { executeWithTokenRefresh } from "../executeWithTokenRefresh";
import { FormProvider, useForm } from "react-hook-form";
import { DeleteAccountSchema, defaultValues, } from "./DeleteAccountSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldPM } from "../../SharedFormParts/TextField";
export function DeleteAccount({ onSuccess, userEmail, }) {
    const [showingVerificationCodeForm, setShowingVerificationCodeForm] = useState(true);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [verificationCode, setVerificationCode] = useState(undefined);
    const handleVerificationCodeSuccess = (verificationCode) => {
        setVerificationCode(verificationCode);
        setTimeout(() => setShowingVerificationCodeForm(false), 500);
        setTimeout(() => setShowDeleteConfirm(true), 700);
    };
    const handlDeleteAccountSuccess = () => __awaiter(this, void 0, void 0, function* () {
        //logout
        setShowDeleteConfirm(false);
        yield dispatchConfirmationDialogEvent({
            message: "Your account was deleted!",
            noCancelButton: true,
        });
        onSuccess();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Fade, Object.assign({ in: showingVerificationCodeForm, unmountOnExit: true }, { children: _jsx("div", { children: _jsx(MailCodeVerificationForm, { onSuccess: handleVerificationCodeSuccess, email: userEmail }) }) })), _jsx(Fade, Object.assign({ in: showDeleteConfirm, unmountOnExit: true }, { children: _jsx("div", { children: _jsx(DeleteAccountConfirmation, { onSuccess: handlDeleteAccountSuccess, email: userEmail, code: verificationCode }) }) }))] }));
}
function DeleteAccountConfirmation({ onSuccess, email, code, }) {
    var _a;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formMethods = useForm({
        resolver: yupResolver(DeleteAccountSchema),
        defaultValues: defaultValues,
    });
    const { handleSubmit, formState: { errors }, } = formMethods;
    const onSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        setIsSubmitting(true);
        setErrorMessage("");
        const response = yield executeWithTokenRefresh(fetch(`${window.location.origin}/v2/auth/delete-account`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mail: email,
                password: data.password,
                password_re: data.password,
                code: code,
            }),
        }), true);
        if (response.success) {
            setTimeout(() => setIsSubmitting(false), 200);
            onSuccess();
        }
        else {
            setIsSubmitting(false);
            const errorMsg = response.error
                ? response.error
                : "Something went wrong. Please try again or contact support if the issue continues.";
            setErrorMessage(errorMsg);
        }
    });
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(FormProvider, Object.assign({}, formMethods, { children: [_jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ textAlign: "left" }, { children: "Enter your password to delete your account." })) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TextFieldPM, { type: "password", formKey: "password", label: "Password", error: (_a = errors["password"]) === null || _a === void 0 ? void 0 : _a.message }) })), !!errorMessage && (_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogContentText, Object.assign({ component: FormHelperText, textAlign: "center", error: true }, { children: errorMessage })) }))), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(DialogActions, Object.assign({ sx: { justifyContent: "center" } }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", disabled: isSubmitting, endIcon: isSubmitting ? _jsx(CircularProgress, { size: 20 }) : undefined }, { children: "Delete" })) })) }))] })), " "] })) })));
}
