import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import produce from "immer";
import { useState, useEffect } from "react";
import { useCustomEvent } from "..";
import { Alert, Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
function ControlledNotificationAlert({ idx, message, type, fileName, onClose, }) {
    if (!onClose)
        throw Error("onClose callback is undefined");
    return (_jsxs(Alert, Object.assign({ onClose: () => onClose(idx), severity: type, sx: { maxWidth: "75%" } }, { children: [fileName ? (_jsxs(_Fragment, { children: [_jsx("b", { children: fileName }), " - "] })) : (""), message] })));
}
export function ControlledNotificationListener() {
    const [notifications, setNotifications] = useState({});
    const removeNotification = (key) => {
        const updNotifications = produce(notifications, (draft) => {
            delete draft[key];
        });
        setNotifications(updNotifications);
    };
    const _setNotifications = ({ message, type, idx, fileName, }) => {
        setNotifications((prev) => {
            return Object.assign(Object.assign({}, prev), { [idx]: { message: message, type: type, fileName } });
        });
    };
    useCustomEvent("PM-ControlledNotification", _setNotifications);
    useEffect(() => {
        const _cb = (e) => {
            const { idx } = e.detail;
            removeNotification(idx);
        };
        window.addEventListener("PM-DeleteControlledNotification", _cb);
        return () => {
            window.removeEventListener("PM-DeleteControlledNotification", _cb);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Stack, Object.assign({ sx: {
            position: "absolute",
            top: 24,
            left: 0,
            right: 0,
            alignItems: "center",
            zIndex: 20,
        }, spacing: 2 }, { children: Object.entries(notifications).map(([idx, notif], i) => {
            return (_jsx(ControlledNotificationAlert, { idx: idx, fileName: notif.fileName, message: notif.message, type: notif.type, onClose: removeNotification }, `controlled-notif-${i}`));
        }) })));
}
export function NotificationListener() {
    const [open, setOpen] = useState(false);
    const [snackPack, setSnackPack] = useState([]);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const _cb = ({ message, type }) => {
        setSnackPack((prev) => [
            ...prev,
            {
                key: new Date().getTime(),
                message: `${message}`,
                type: type,
            },
        ]);
    };
    useCustomEvent("PM-Notification", _cb);
    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo(Object.assign({}, snackPack[0]));
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        }
        else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }
    }, [snackPack, messageInfo, open]);
    const closeSnack = (event, reason) => {
        if (reason === "clickaway")
            return;
        setOpen(false);
    };
    const handleExited = () => setMessageInfo(undefined);
    return (_jsx(Snackbar, Object.assign({ anchorOrigin: { horizontal: "center", vertical: "top" }, open: open, onClose: closeSnack, autoHideDuration: 8000, TransitionProps: { onExited: handleExited }, action: _jsx(IconButton, Object.assign({ sx: { p: 0.5 }, onClick: closeSnack }, { children: _jsx(CloseIcon, {}) })) }, { children: _jsx(Alert, Object.assign({ onClose: closeSnack, severity: messageInfo === null || messageInfo === void 0 ? void 0 : messageInfo.type, sx: { width: "100%" } }, { children: messageInfo ? messageInfo.message : undefined })) }), messageInfo ? messageInfo.key : undefined));
}
