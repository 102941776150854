var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { shallow } from "zustand/shallow";
import { Box, Fade, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { createGroup } from "../../3dViewer/Controls/Groups/createGroup";
import { MolSystemsPanel } from "../../3dViewer/MolSystemsPanel";
import { ToggleAllGroups } from "../../3dViewer/Controls/Groups/CreateGroupCheckbox";
import { Panels, } from "../../GenericViewerState/SharedState";
import { SystemActionsMenu } from "../../3dViewer/Controls/Menu/SystemActionsMenu";
import { deleteSelectedSystems } from "../../3dViewer/Controls/DeleteMultipleSystems/deleteSelectedSystems";
import { toggleMultipleSystemsVisibility } from "../../3dViewer/Controls/ToggleVisibilityMultipleSystems/toggleVisibilityMultipleSystems";
import { menuButtons, selectedNodesOnClick, unselectedNodesOnUncheck, getAllNodesPosition, selectAllNodeCheckboxes, unslectAllNodeCheckboxes, } from "../../3dViewer/Controls/utils";
import { bottomPanel, systemTree } from "../panelDimensions";
import { SelectionOptions } from "../../3dViewer/Selection/SelectionOptions";
import { cloneRepresentationsToSystems } from "../../3dViewer/Controls/CloneRepresentations/cloneRepresentations";
export const StateTreePanel = ({ show, vss, molstar, pyodide, NAPGenericStore, }) => {
    const [isToggleAllChecked, setIsToggleAllChecked] = useState(false);
    const [activeButton, setActiveButton] = useState(undefined);
    const [checkedSystems, setCheckedSystems] = useState([]);
    const [disabledCheckboxSystems, setDisabledCheckboxSystems] = useState([]);
    const [groupName, setGroupName] = useState("Group");
    const [activeSystemRadio, setActiveSystemRadio, activePanels, setActivePanels, removeVisibleSystemsFiles, topPanelHeight, closeSystemRepresentations,] = NAPGenericStore((state) => [
        state._activeSystemRadio,
        state.setActiveSystemRadio,
        state._activePanels,
        state.setActivePanels,
        state.removeVisibleSystemsFiles,
        state.topPanelHeight,
        state.closeSystemRepresentations,
    ], shallow);
    const leftPanelDimensions = Object.assign(Object.assign({}, systemTree), { 
        //@ts-ignore
        left: (theme) => `calc(${theme.spacing(7)})`, top: `${topPanelHeight}px`, maxHeight: `calc(100vh  - ${activePanels.console ? bottomPanel.height : "1.75em"} - ${topPanelHeight}px)`, overflowY: "hidden", p: (theme) => `calc(${theme.spacing(1)})`, pl: 0, display: "flex", flexDirection: "column" });
    // Click on a menu button
    const onCreateGroupClick = () => {
        unslectAllNodeCheckboxes(setIsToggleAllChecked, setCheckedSystems);
        setActiveButton((prev) => prev === undefined ? menuButtons.createGroup : undefined);
    };
    const onDeleteButtonClick = () => {
        selectAllNodeCheckboxes(vss, setIsToggleAllChecked, setCheckedSystems);
        setActiveButton((prev) => prev === undefined ? menuButtons.deleteSystems : undefined);
    };
    const onToggleVizButtonClick = () => {
        selectAllNodeCheckboxes(vss, setIsToggleAllChecked, setCheckedSystems);
        setActiveButton((prev) => prev === undefined ? menuButtons.toggleVisibility : undefined);
    };
    const onCloneRepresentationClick = (posInTree) => {
        setDisabledCheckboxSystems([posInTree.join("-")]);
        selectAllNodeCheckboxes(vss, setIsToggleAllChecked, setCheckedSystems);
        setActiveButton(menuButtons.cloneRepresentation);
    };
    // Accept selection on a menu button
    const onAcceptGroupClick = () => {
        const checkedSystemstoGroupNum = checkedSystems.map((sysId) => sysId.split("-").map((sysIdEl) => Number(sysIdEl)));
        createGroup(vss, checkedSystemstoGroupNum, groupName);
        setActiveButton(undefined);
    };
    const onAcceptCloneRepresentationsClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const checkedSystemstoGroupNum = checkedSystems.map((sysId) => sysId.split("-").map((sysIdEl) => Number(sysIdEl)));
        const fromPosStr = disabledCheckboxSystems[0];
        if (!fromPosStr)
            return;
        const fromPos = fromPosStr.split("-").map((sysIdEl) => Number(sysIdEl));
        yield cloneRepresentationsToSystems(fromPos, checkedSystemstoGroupNum, vss, molstar, pyodide);
        setActiveButton(undefined);
        setDisabledCheckboxSystems([]);
    });
    const onAcceptDeleteMultipleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const checkedSystemsNum = checkedSystems.map((sysId) => sysId.split("-").map((sysIdEl) => Number(sysIdEl)));
        closeSystemRepresentations();
        const onDeleteCallback = (updatedStructs, deletedFiles) => {
            if (updatedStructs.length === 0)
                setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.statetree]: false, [Panels.sequence]: false }));
            if (deletedFiles)
                removeVisibleSystemsFiles(deletedFiles);
        };
        deleteSelectedSystems(molstar, pyodide, vss, checkedSystemsNum, onDeleteCallback);
        setActiveButton(undefined);
    });
    const onAcceptToggleVizClick = () => {
        const checkedSystemsNum = checkedSystems.map((sysId) => sysId.split("-").map((sysIdEl) => Number(sysIdEl)));
        toggleMultipleSystemsVisibility(vss, molstar, checkedSystemsNum);
        setActiveButton(undefined);
    };
    // Cancel selection
    const onCancel = () => {
        setActiveButton(undefined);
    };
    // Control checkboxes
    const onCheckSystem = (isChecked, key) => {
        const posInTree = key.split("-").map((sysIdEl) => Number(sysIdEl));
        setCheckedSystems((prev) => {
            if (isChecked) {
                const selectedTreePos = selectedNodesOnClick(vss, posInTree, prev);
                const selectedIDs = selectedTreePos.map((e) => e.join("-"));
                return [...new Set([...prev, ...selectedIDs])];
            }
            else {
                const treePosstoRemove = unselectedNodesOnUncheck(vss, posInTree);
                const IDstoRemove = treePosstoRemove.map((e) => e.join("-"));
                return prev.filter((e) => !IDstoRemove.includes(e));
            }
        });
        setIsToggleAllChecked(false);
    };
    const onCheckToggleAll = (isChecked) => {
        setIsToggleAllChecked(isChecked);
        if (isChecked) {
            const allSystemIDs = getAllNodesPosition(vss).map((e) => e.join("-"));
            setCheckedSystems(allSystemIDs);
        }
        else {
            setCheckedSystems([]);
        }
    };
    //Others
    const onGroupNameChange = (newName) => {
        setGroupName(newName);
    };
    // Checkbox and radio buttons
    const areSystemsCheckboxActive = activeButton !== undefined;
    useEffect(() => {
        if (areSystemsCheckboxActive)
            setActiveSystemRadio("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areSystemsCheckboxActive]);
    useEffect(() => {
        if (activeSystemRadio)
            setActiveButton(undefined);
    }, [activeSystemRadio]);
    const [loaded_structures] = vss((state) => [state.loaded_structures], shallow);
    useEffect(() => {
        setActiveButton(undefined);
        setDisabledCheckboxSystems([]);
    }, [loaded_structures]);
    return (_jsx(Fade, Object.assign({ in: show }, { children: _jsxs(Box, Object.assign({ id: "left-panel", sx: leftPanelDimensions }, { children: [_jsxs(Box, { children: [_jsx(SystemActionsMenu, { molstar: molstar, vss: vss, numSelectedSystems: checkedSystems.length, activeButton: activeButton, onCreateGroupClick: onCreateGroupClick, onCancelCreateGroupClick: onCancel, onAcceptCloneRepresentationsClick: onAcceptCloneRepresentationsClick, onCancelCloneRepresentationsClick: onCancel, onAcceptGroupClick: onAcceptGroupClick, onGroupNameChange: onGroupNameChange, groupName: groupName, onDeleteButtonClick: onDeleteButtonClick, onCancelDeleteMultipleClick: onCancel, onAcceptDeleteMultipleClick: onAcceptDeleteMultipleClick, onToggleVizButtonClick: onToggleVizButtonClick, onCancelToggleVizClick: onCancel, onAcceptToggleVizClick: onAcceptToggleVizClick, disabledCheckboxSystems: disabledCheckboxSystems }), _jsx(ToggleAllGroups, { areSystemsCheckboxActive: areSystemsCheckboxActive, onCheckToggleAll: onCheckToggleAll, isToggleAllChecked: isToggleAllChecked })] }), _jsx(Box, Object.assign({ sx: {
                        overflowY: "auto",
                        flexGrow: 1,
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 1 }, { children: [_jsx(SelectionOptions, { molstar: molstar, vss: vss, pyodide: pyodide }), _jsx(MolSystemsPanel, { vss: vss, molstar: molstar, pyodide: pyodide, positionInTree: [], onCheckSystem: onCheckSystem, checkedSystems: checkedSystems, disabledCheckboxSystems: disabledCheckboxSystems, areSystemsCheckboxActive: areSystemsCheckboxActive, NAPGenericStore: NAPGenericStore, onCloneRepresentationClick: onCloneRepresentationClick })] })) }))] })) })));
};
