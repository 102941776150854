import urlJoin from "url-join";
function joinIfNotAbsolute(basePath, filePath) {
    if (filePath.charAt(0) === "/")
        return filePath;
    return urlJoin(basePath, filePath);
}
function updateDCPathInSystTree(system, basePath) {
    var _a;
    if (system.children) {
        system.children.forEach((child) => {
            updateDCPathInSystTree(child, basePath);
        });
    }
    else {
        system.files = (_a = system.files) === null || _a === void 0 ? void 0 : _a.map((f) => joinIfNotAbsolute(basePath, f));
    }
}
export function getFilesFullPath(configFile, basePath) {
    // Modify nap file so that it contains full paths
    for (let elementType in configFile) {
        const elData = configFile[elementType];
        if (Array.isArray(elData)) {
            elData.forEach((systOrOther) => {
                if (systOrOther.path) {
                    // it's a table or plot
                    systOrOther.path = joinIfNotAbsolute(basePath, systOrOther.path);
                }
                else {
                    // it's a system
                    updateDCPathInSystTree(systOrOther, basePath);
                }
            });
        }
        else {
            if (elData.files) {
                elData.files = elData.files.map((fileName) => joinIfNotAbsolute(basePath, fileName));
            }
        }
    }
}
